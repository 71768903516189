import { Card, Form } from "react-bootstrap";

const FastCheck = (props) => {


    function renderCheck() {
        return (<>
            <Form.Check className="RF-3"
                checked={props.checked} disabled={props.disabled}
                onChange={v => props.onChange(v.target.checked)} />
            <span className="RFT-2 ms-2">
                {props.label}
            </span>
        </>);
    }


    return (<>
        {props.frame &&
            <Card className={props.className}>
                <Card.Body className="d-flex align-items-center px-2 py-0">
                    {renderCheck()}
                    {props.children}
                </Card.Body>
            </Card>
        }
        {!props.frame &&
            <div className={"d-flex align-items-center px-2 py-0 " + props.className}>
                {renderCheck()}
                {props.children}
            </div>
        }
    </>);
}

export default FastCheck;