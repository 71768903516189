import api from "Defines/Api";
import useApi from "Hooks/useApi";
import { useState, useEffect } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";

const SelectGiftVariantsModal = (props) => {

    const value = props.value;

    const getApi = useApi(api.giftVariants.index, null, true);

    const onCancel = () => {
        props.onCancel();
    }
    const onSave = () => {
        props.onSubmit();
    }

    const onChange = (e, name) => {
        let val = (value) ? value : [];

        const index = val.indexOf(name);

        if (e.target.checked && index === -1) {
            val.push(name);
        } else {
            val.splice(index, 1);
        }

        props.onChange(val);
    }

    const isChecked = (name) => {
        if (value) {
            return value.includes(name);
        }
        return false;
    }

    return (<>
        <Modal show={props.show} onHide={onCancel} centered={props.centered}>

            <Modal.Header closeButton>
                <Modal.Title className="RFT-2">Warianty prezentu</Modal.Title>
            </Modal.Header>

            <Modal.Body >

                {getApi.data && <>
                    {getApi.data.map((dat) => (
                        <div key={dat}>
                            <Row className="d-flex justify-content-center">
                                <Col xs={2} className="d-flex align-items-center RF-1">
                                    <Form.Check checked={isChecked(dat)}
                                        onChange={e => { onChange(e, dat) }} />
                                </Col>
                                <Col xs={8} className="text-center">
                                    <Image fluid src={api.giftVariants.showUrl(dat)} />
                                </Col>
                            </Row>
                            <hr className="my-0" />
                        </div>
                    ))}

                </>}

            </Modal.Body>

            <Modal.Footer>
                
                <Button variant="success" className="RFT-2" onClick={onSave}>OK</Button>
            </Modal.Footer>

        </Modal>
    </>)
}

//<Button variant="danger" className="RFT-2" onClick={onCancel}>Anuluj</Button>

export default SelectGiftVariantsModal;