import routes from "./Routes";


const rodo = <article>
    <h1 style={{ textAlign: "center" }}>Obowiązek informacyjny RODO</h1>
    <br />
    <p>
        Poniższa informacja stanowi zwięzły, zrozumiały i przejrzysty skrót
        informacji zamieszczonych w{" "}
        <a href={routes.global.rules}>Polityce Prywatności</a> odnośnie
        Administratora danych, celu i sposobu przetwarzania danych osobowych oraz
        Twoich praw w związku z tym przetwarzaniem, w formie wymaganej do spełnienia
        obowiązku informacyjnego RODO. Szczegóły dotyczące sposobu przetwarzania i
        podmiotów uczestniczących w tym procesie dostępne są we wskazanej polityce.
    </p>
    <h2>Kto jest administratorem danych?</h2>{" "}
    <p>
        Administratorem Danych Osobowych (dalej Administrator) jest osoba fizyczna
        "Sebastian Koczur" zamieszkała w Andrychowie, świadcząca usługi drogą
        elektroniczną za pośrednictwem Serwisu
    </p>
    <h2>Jak można skontaktować się z administratorem danych?</h2>
    <p>Z Administratorem można skontaktować się w jeden z poniższych sposobów:</p>
    <ul>
        <li>
            <p>
                <strong>Adres poczty elektronicznej</strong> - sebastian@skoczur.pl
            </p>
        </li>
    </ul>
    <h2>Czy Administrator powołał Inspektora Danych Osobowych?</h2>
    <p>
        Na podstawie Art. 37 RODO, Administrator nie powołał Inspektora Ochrony
        Danych.
    </p>
    <p>
        W sprawach dotyczących przetwarzania danych, w tym danych osobowych, należy
        kontaktować się bezpośrednio z Administratorem.
    </p>
    <h2>Skąd pozyskujemy dane osobowe i jakie są ich źródła?</h2>
    <p>Dane pozyskiwane są z następujących źródeł:</p>
    <ul>
        <li>od osób, których dane dotyczą</li>
        <li>
            w przypadku rejestracji przy użyciu portali społecznościowych, za wyrażoną
            świadomą zgodą tych osób, z tych portali społecznościowych
        </li>
        <li>od użytkowników wprowadzających gości</li>
    </ul>
    <h2>Jaki jest zakres przetwarzanych przez nas danych osobowych?</h2>
    <p>
        W serwisie przetwarzane są <strong>dane osobowe zwykłe</strong>, podane
        dobrowolnie przez osoby, których dotyczą
        <br />
        <small>
            (Np. imię i nazwisko, login, adres e-mail, telefon, adres IP, itp.)
        </small>
    </p>
    <p>
        Szczegółowy zakres przetwarzanych danych dostępny jest w{" "}
        <a href={routes.global.rules}>Polityce Prywatności</a>.
    </p>
    <h2>Jakie są cele przetwarzania przez nas danych?</h2>
    <p>
        Dane osobowe dobrowolnie podane przez Użytkowników są przetwarzane w jednym
        z następujących celów:
    </p>
    <ul>
        <li>
            Realizacji usług elektronicznych
        </li>
        <li>
            Komunikacji Administratora z Użytkownikami w sprawach związanych z
            Serwisem oraz ochrony danych
        </li>
        <li>Zapewnienia prawnie uzasadnionego interesu Administratora</li>
    </ul>
    <h2>Jakie są podstawy prawne przetwarzania danych?</h2>
    <p>Serwis gromadzi i przetwarza dane Użytkowników na podstawie:</p>
    <ul>
        <li>
            Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
            kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
            przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
            danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
            danych)
            <ul>
                <li>
                    art. 6 ust. 1 lit. a<br />
                    <small>
                        osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich
                        danych osobowych w jednym lub większej liczbie określonych celów
                    </small>
                </li>
                <li>
                    art. 6 ust. 1 lit. b<br />
                    <small>
                        przetwarzanie jest niezbędne do wykonania umowy, której stroną jest
                        osoba, której dane dotyczą, lub do podjęcia działań na żądanie
                        osoby, której dane dotyczą, przed zawarciem umowy
                    </small>
                </li>
                <li>
                    art. 6 ust. 1 lit. f<br />
                    <small>
                        przetwarzanie jest niezbędne do celów wynikających z prawnie
                        uzasadnionych interesów realizowanych przez administratora lub przez
                        stronę trzecią
                    </small>
                </li>
            </ul>
        </li>
        <li>
            Ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz.U. 2018 poz.
            1000)
        </li>
        <li>
            Ustawa z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. 2004 nr 171
            poz. 1800)
        </li>
        <li>
            Ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych
            (Dz. U. 1994 Nr 24 poz. 83)
        </li>
    </ul>
    <h2>
        Jaki jest prawnie uzasadniony interes realizowany przez Administratora?
    </h2>
    <ul>
        <li>
            W celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami –
            podstawą prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1
            lit. f) RODO) polegający na ochronie naszych praw, w tym między innymi;
        </li>
        <li>W celu oceny ryzyka potencjalnych klientów</li>
        <li>W celu oceny planowanych kampanii marketingowych</li>
        <li>W celu realizacji marketingu bezpośredniego</li>
    </ul>
    <h2>Przez jaki okres przetwarzamy dane osobowe?</h2>
    <p>
        Co do zasady, wskazane dane osobowe są przechowywane wyłącznie przez okres
        świadczenia usługi w ramach prowadzonego serwisu przez Administratora. Są
        one usuwane lub anonimizowane{" "}
        <strong>natychmiastowo w chwili zakończenia świadczenia usług</strong> (np.
        usunięcie zarejestrowanego konta użytkownika, wypisanie z listy Newsletter,
        itp.)
    </p>
    <h2>Kto jest odbiorcą danych w tym danych osobowych?</h2>
    <p>Co do zasady jedynym odbiorcą danych jest Administrator.</p>
    <p>
        Przetwarzanie danych może jednak być powierzone innym podmiotom,
        realizującym usługi na rzecz Administratora w celu utrzymania działalności
        Serwisu.
    </p>
    Do podmiotów takich można zaliczyć między innymi:
    <ul>
        <li>
            Firmy hostingowe, świadczące usługi hostingu lub usług pokrewnych dla
            Administratora
        </li>
        <li>
            Firmy serwisowe i wsparcia IT dokonujące konserwacji lub odpowiedzialne za
            utrzymanie infrastruktury IT
        </li>
    </ul>
    <h2>Czy Państwa dane osobowe będą przekazywane poza Unię Europejską?</h2>
    <p>
        Dane osobowe <strong>nie będą przekazywane poza Unię Europejską</strong>,
        chyba że zostały opublikowane na skutek indywidualnego działania Użytkownika
        (np. wprowadzenie komentarza lub wpisu), co sprawi, że dane będą dostępne
        dla każdej osoby odwiedzającej serwis.
    </p>
    <h2>
        Czy dane osobowe będą podstawą zautomatyzowanego podejmowania decyzji?
    </h2>
    <p>
        Dane osobowe <strong>nie będą wykorzystywane</strong> do zautomatyzowanego
        podejmowania decyzji (profilowania).
    </p>
    <h2>Jakie mają Państwo prawa związane z przetwarzaniem danych osobowych?</h2>
    <ul>
        <li>
            <p>
                <strong>Prawo dostępu do danych osobowych</strong>
                <br />
                Użytkownikom przysługuje prawo uzyskania dostępu do swoich danych
                osobowych, realizowane na żądanie złożone do Administratora
            </p>
        </li>
        <li>
            <p>
                <strong>Prawo do sprostowania danych osobowych</strong>
                <br />
                Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego
                sprostowania danych osobowych, które są nieprawidłowe lub / oraz
                uzupełnienia niekompletnych danych osobowych, realizowane na żądanie
                złożone do Administratora
            </p>
        </li>
        <li>
            <p>
                <strong>Prawo do usunięcia danych osobowych</strong>
                <br />
                Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego
                usunięcia danych osobowych, realizowane na żądanie złożone do
                Administratora. <br />
                <br />W przypadku kont użytkowników, usunięcie danych polega na
                anonimizacji danych umożliwiających identyfikację Użytkownika.
                <br />
                <br />W przypadku usługi Newsletter, Użytkownik ma możliwość
                samodzielnego usunięcia swoich danych osobowych korzystając z odnośnika
                umieszczonego w każdej przesyłanej wiadomości e-mail.
            </p>
        </li>
        <li>
            <p>
                <strong>Prawo do ograniczenia przetwarzania danych osobowych</strong>
                <br />
                Użytkownikom przysługuje prawo ograniczenia przetwarzania danych
                osobowych w przypadkach wskazanych w art. 18 RODO, m.in. kwestionowania
                prawidłowość danych osobowych, realizowane na żądanie złożone do
                Administratora
            </p>
        </li>
        <li>
            <p>
                <strong>Prawo do przenoszenia danych osobowych</strong>
                <br />
                Użytkownikom przysługuje prawo uzyskania od Administratora, danych
                osobowych dotyczących Użytkownika w ustrukturyzowanym, powszechnie
                używanym formacie nadającym się do odczytu maszynowego, realizowane na
                żądanie złożone do Administratora
            </p>
        </li>
        <li>
            <p>
                <strong>
                    Prawo wniesienia sprzeciwu wobec przetwarzania danych osobowych
                </strong>
                <br />
                Użytkownikom przysługuje prawo wniesienia sprzeciwu wobec przetwarzania
                jego danych osobowych w przypadkach określonych w art. 21 RODO,
                realizowane na żądanie złożone do Administratora
            </p>
        </li>
        <li>
            <p>
                <strong>Prawo wniesienia skargi</strong>
                <br />
                Użytkownikom przysługuje prawo wniesienia skargi do organu nadzorczego
                zajmującego się ochroną danych osobowych.
            </p>
        </li>
    </ul>
</article>;


const regulations = <article>
    <h1 className="page-title text-center">Regulamin serwisu</h1>
    <br />
    <h2>1. Pojęcia ogólne</h2>
    <ul>
        <li>
            <strong>Regulamin</strong> – niniejszy regulamin
        </li>
        <li>
            <strong>Serwis</strong> – serwis internetowych "QRInvite", działających
            pod adresem https://qri.skoczur.pl
        </li>
        <li>
            <strong>Usługodawca</strong> – właściciel serwisu będący osobą fizyczną -
            Sebastian Koczur
        </li>
        <li>
            <strong>Usługobiorca</strong> – każda osoba fizyczna, uzyskująca dostęp do
            Serwisu i korzystająca z usług świadczonych za pośrednictwem Serwisu przez
            Usługodawcę.
        </li>
        <li>
            <strong>Komunikacja Drogą Elektroniczną</strong> – Komunikacja pomiędzy
            stronami za pośrednictwem poczty elektronicznej (e-mail).
        </li>
    </ul>
    <h2>2. Postanowienia ogólne</h2>
    <ul>
        <li>
            Regulamin, określa zasady funkcjonowania i użytkowania Serwisu oraz
            określa zakres praw i obowiązków Usługobiorców i Usługodawcy związanych z
            użytkowaniem Serwisu.
        </li>
        <li>
            Przedmiotem usług Usługodawcy jest udostępnienie nieodpłatnych narzędzi w
            postaci Serwisu, umożliwiających Usługobiorcom dostęp do treści w postaci
            wirtualnych zaproszeń weselnych
        </li>
        <li>
            Wszelkie ewentualne treści, artykuły i informacje zawierające cechy
            wskazówek lub porad publikowane na łamach Serwisu są jedynie ogólnym
            zbiorem informacji i nie są kierowane do poszczególnych Usługobiorców.
            Usługodawca nie ponosi odpowiedzialności za wykorzystanie ich przez
            Usługobiorców.
        </li>
        <li>
            Usługobiorca bierze na siebie pełną odpowiedzialno za sposób wykorzystania
            materiałów udostępnianych w ramach Serwisu w tym za wykorzystanie ich
            zgodnie z obowiązującymi przepisami prawa.
        </li>
        <li>
            Usługodawca nie udziela żadnej gwarancji co do przydatności materiałów
            umieszczonych w Serwisie.
        </li>
        <li>
            Usługodawca nie ponosi odpowiedzialności z tytułu ewentualnych szkód
            poniesionych przez Usługobiorców Serwisu lub osoby trzecie w związku z
            korzystaniem z Serwisu. Wszelkie ryzyko związane z korzystaniem z Serwisu,
            a w szczególności z używaniem i wykorzystywaniem informacji umieszczonych
            w Serwisie, ponosi Usługobiorca korzystający z usług Serwisu.
        </li>
    </ul>
    <h2>3. Warunki używania Serwisu</h2>
    <ul>
        <li>
            Używanie Serwisu przez każdego z Usługobiorców jest nieodpłatne i
            dobrowolne.
        </li>
        <li>
            Usługobiorcy mają obowiązek zapoznania się z Regulaminem oraz pozostałymi
            dokumentami stanowiącymi jego integralną część i muszą zaakceptować w
            całości jego postanowienia w celu dalszego korzystania z Serwisu.
        </li>
        <li>
            Usługobiorcy nie mogą wykorzystywać żadnych pozyskanych w Serwisie danych
            osobowych do celów marketingowych.
        </li>{" "}
        <li>
            Wymagania techniczne korzystania z Serwisu:
            <ul>
                <li>
                    urządzenie z wyświetlaczem umożliwiające wyświetlanie stron
                    internetowych,
                </li>
                <li>połączenie z internetem,</li>
                <li>
                    dowolna przeglądarka internetowa, która wyświetla strony internetowe
                    zgodnie ze standardami i postanowieniami Konsorcjum W3C i obsługuje
                    strony www udostępniane w języku HTML5,
                </li>
                <li>włączoną obsługę skryptów JavaScript,</li>
                <li>włączoną obsługę plików Cookie</li>
            </ul>
        </li>
        <li>
            W celu zapewnienia bezpieczeństwa Usługodawcy, Usługobiorcy oraz innych
            Usługobiorców korzystających z Serwisu, wszyscy Usługobiorcy korzystający
            z Serwisu powinni stosować się do ogólnie przyjętych{" "}
            <a href="https://nety.pl/cyberbezpieczenstwo/zasady-ogolne-korzystania-z-sieci-internet/">
                zasad bezpieczeństwa w sieci
            </a>
            ,
        </li>{" "}
        <li>
            Zabrania się działań wykonywanych osobiście przez Usługobiorców lub przy
            użyciu oprorgamowania:
            <ul>
                <li>bez zgody pisemnej, dekompilacji i analizy kodu źródłowego,</li>
                <li>
                    bez zgody pisemnej, powodujących nadmierne obciążenie serwera Serwisu,
                </li>
                <li>
                    bez zgody pisemnej, prób wykrycia luk w zabezpieczeniach Serwisu i
                    konfiguracji serwera,
                </li>
                <li>
                    podejmowania prób wgrywania lub wszczykiwania na serwer i do bazy
                    danych kodu, skryptów i oprogramowania mogących wyrządzić szkodę
                    oprogramowaniu Serwisu, innym Usługobiorcom lub Usługodawcy,
                </li>
                <li>
                    podejmowania prób wgrywania lub wszczykiwania na serwer i do bazy
                    danych kodu, skryptów i oprogramowania mogących śledzić lub wykradać
                    dane Usługobiorców lub Usługodawcy,
                </li>
                <li>
                    podejmowania jakichkolwiek działań mających na celu uszkodzenie,
                    zablokowanie działania Serwisu lub uniemożliwienie realizacji celu w
                    jakim działa Serwis.
                </li>
            </ul>
        </li>
        <li>
            W przypadku wykrycia zaistnienia lub potencjalnej możliwości zaistnienia
            incydentu Cyberbezpieczeństwa lub naruszenia RODO, Usługobiorcy w
            pierwszej kolejności powinni zgłosić ten fakt Usługodawcy w celu szybkiego
            usunięcia problemu / zagrożenia i zabezpieczenia interesów wszystkich
            Usługobiorców Serwisu.
        </li>
    </ul>
    <h2>4. Warunki oraz zasady rejestracji</h2>
    <ul>
        <li>Usługobiorcy mogą korzystać z Serwisu bez konieczności rejestracji.</li>
        <li>
            Usługobiorcy muszą być zarejestrowani i posiadać konto w Serwisie by
            korzystać z dodatkowych usług świadczonych w Serwisie, dostępnych jedynie
            dla Usługobiorców po zalogowaniu.
        </li>
        <li>Rejestracja w Serwisie jest dobrowolna.</li>
        <li>Rejestracja w Serwisie jest nieodpłatna.</li>
        <li>
            Wymagania techniczne związane z rejestracją konta:
            <ul>
                <li>posiadanie indywidualnego konta poczty elektronicznej e-mail,</li>
            </ul>
        </li>
        <li>
            Rejestrujący się w Serwisie Usługobiorcy wyrażają zgodę na przetwarzanie
            ich danych osobowych przez Usługobiorcę w zakresie w jakim zostały one
            wprowadzone do Serwisu podczas procesu rejestracji oraz ich późniejszych
            zmianom lub usunięciu.
        </li>
        <li>
            Usługodawca ma prawo zawieszać lub usuwać konta Usługobiorców według
            własnego uznania, uniemożliwiając lub ograniczając w ten sposób dostęp do
            poszczególnych lub wszystkich usług, treści, materiałów i zasobów Serwisu,
            w szczególności jeżeli Usługobiorca dopuści się łamania Regulaminu,
            powszechnie obowiązujących przepisów prawa, zasad współżycia społecznego
            lub działa na szkodę Usługodawcy lub innych Usługobiorców, uzasadnionego
            interesu Usługodawcy oraz podmiotów trzecich współpracujących lub nie z
            Usługodawcą.
        </li>
        <li>
            Wszelkie usługi Serwisu mogą być zmieniane co do ich treści i zakresu,
            dodawane lub odejmowane, a także czasowo zawieszane lub dostęp do nich
            może być ograniczany, według swobodnej decyzji Usługodawcy, bez możliwości
            wnoszenia sprzeciwu w tym zakresie przez Usługobiorców.
        </li>
        <li>
            Dodatkowe zasady bezpieczeństwa w zakresie korzystania z konta:
            <ul>
                <li>
                    Zabrania się Usługobiorcom zarejestrowanym w Serwisie do udostępniania
                    loginu oraz hasła do swojego konta osobom trzecim.
                </li>
                <li>
                    Usługodawca nie ma prawa i nigdy nie będzie zażądać od Usługobiorcy
                    hasła do wybranego konta.
                </li>
            </ul>
        </li>
        <li>
            Usuwanie konta:
            <ul>
                <li>
                    Każdy Usługobiorca posiadający konto w Serwisie ma możliwość
                    samodzielnego usunięcia konta z Serwisu.
                </li>
                <li>
                    Usługobiorcy mogą to uczynić po zalogowaniu się w panelu w Serwisie.
                </li>
                <li>
                    Usunięcie konta skutkuje usunięciem wszelkich danych identyfikacyjnych
                    Usługobiorcy oraz anonimizacją nazwy użytkownika i adresu e-mail.
                </li>
            </ul>
        </li>
    </ul>
    <h2>5. Warunki świadczenia usług w Serwisie</h2>
    <ul>
        <li>
            Serwis udostępnia usługi i narzędzia umożliwiające Usługobiorcom
            interakcję z Serwisem w postaci:
            <ul>
                <li>Tworzenia wirtualnych zaproszeń weselnych,</li>
                <li>Generowania statystyk zwiazanych z stworzonym weselem,</li>
                <li>Udostepniania gościom linków do indywidualnych zaproszeń w postaci linków i kodów QR</li>
            </ul>
        </li>


        <li>
            Warunki umieszczania danych osobowych w serwisie:
            <ul>
                <li>
                    Użytkownik tworząc zaproszenia i wprowadzajac dane osobowe gości (imię i nazwisko) ma obowiązek
                    posiadać zgodę od osób których te dane dotyczą
                </li>
                <li>
                    Wszelakie rozczenia i pretensje zwiazane z wprowadzeniem danych osobowych powinny być kierowane
                    do użytkownika który je wprowadził
                </li>
            </ul>
        </li>
    </ul>
    <h2>6. Warunki komunikacji w Serwisie</h2>
    <ul>
        <li>
            Serwis udostępnia dane kontaktowe w postaci:
            <ul>
                <li>Adresu e-mail</li>
            </ul>
        </li>
        <li>
            W przypadku kontaktu Usługobiorcy z Usługodawcą, dane osobowe
            Usługobiorców będa przetwarzane zgodnie z "
            <a href={routes.global.rules}>Polityką Prywatności</a>",
            stanowiącą integralną część Regulaminu.
        </li>
        <li>
            Warunki umieszczania treści przez Usługobiorców w Serwisie:
            <ul>
                <li>
                    Zabrania się umieszczania w Serwisie treści obraźliwych lub
                    oszczerczych względem Usługodawcy, pozostałych Usługobiorców, osób
                    trzecich oraz podmiotów trzecich,
                </li>
                <li>
                    Zabrania się umieszczania w Serwisie materiałów tekstowych,
                    graficznych, audiowizualnych, skryptów, programów i innych utworów, na
                    które Usługobiorca nie posiada się licencji, lub których autor praw
                    majątkowych nie wyraził zgody na darmową publikację,
                </li>
                <li>
                    Zabrania się umieszczania w Serwisie treści wulgarnych,
                    pornograficznych, erotycznych i niezgodnych z polskim i europejskim
                    prawem a także odnośników do stron zawierających wskazane treści,
                </li>
                <li>
                    Zabrania się umieszczania w Serwisie skryptów i programów nadmiernie
                    obciążających serwer, oprogramowania nielegalnego, oprogramowania
                    służącego do naruszania zabezpieczeń oraz innych podobnych działań a
                    także odnośników do stron zawierających wskazane materiały,
                </li>
                <li>
                    Zabrania się umieszczania w Serwisie treści merketingowych i
                    reklamujących inne serwisy komercyjne, produkty, usługi czy komercyjne
                    strony internetowe
                </li>
            </ul>
        </li>
    </ul>
    <h2>7. Gromadzenie danych o Usługobiorcach</h2>
    <p>
        W celu prawidłowego świadczenia usług przez Serwis, zabezpieczenia prawnego
        interesu Usługodawcy oraz w celu zapewnienia zgodności działania Serwisu z
        obowiązującym prawem, Usługodawca za pośrednictwem Serwisu gromadzi i
        przetwarza niektóre dane o Użytkownikach.
    </p>
    <p>
        W celu prawidłowego świadczenia usług, Serwis wykorzystuje i zapisuje
        niektóre anonimowe informacje o Usługobiorcy w plikach cookies.
    </p>
    <p>
        Zakres, cele, sposób oraz zasady przetwarzania danych dostępne są w
        załącznikach do Regulaminu: „
        <a href={routes.global.rules}>
            Obowiązek informacyjny RODO
        </a>
        ” oraz w „<a href={routes.global.rules}>Polityce prywatności</a>„,
        stanowiących integralną część Regulaminu.
    </p>
    <ul>
        <li>
            Dane zbierane podczas rejestracji:
            <br />
            Nazwa użytkownika, imię i nazwisko, adres e-mail
            <p>
                W przypadku Usługobiorców zalogowanych (posiadających konto w Serwisie),
                w plikach cookies zapisywanych na urządzeniu Usługobiorcy może być
                umieszczony identyfikator Usługobiorcy powiązany z kontem Usługobiorcy
            </p>
        </li>
    </ul>
    <h2>8. Prawa autorskie</h2>
    <ul>
        <li>
            Właścicielem Serwisu oraz praw autorskich do serwisu jest Usługodawca.
        </li>
        <li>
            Część danych zamieszczonych w Serwisie są chronione prawami autorskimi
            należącymi do firm, instytucji i osób trzecich, niepowiązanych w
            jakikolwiek sposób z Usługodawcą, i są wykorzystywane na podstawie
            uzyskanych licencji, lub opartych na licencji darmowej.
        </li>
        <li>
            Na podstawie Ustawy z dnia 4 lutego 1994 o prawie autorskim zabrania się
            wykorzystywania, kopiowania, reprodukowania w jakiejkolwiek formie oraz
            przetrzymywania w systemach wyszukiwania z wyłączeniem wyszukiwarki
            Google, Bing, Yahoo, NetSprint, DuckDuckGo, Facebook oraz LinkedIn
            jakichkolwiek artykułów, opisów, zdjęć oraz wszelkich innych treści,
            materiałów graficznych, wideo lub audio znajdujących się w Serwisie bez
            pisemnej zgody lub zgody przekazanej za pomocą Komunikacji Drogą
            Elektroniczną ich prawnego właściciela.
        </li>
        <li>
            Zgodnie z Ustawą z dnia 4 lutego 1994 o prawie autorskim ochronie nie
            podlegają proste informacje prasowe, rozumiane jako same informacje, bez
            komentarza i oceny ich autora. Autor rozumie to jako możliwość
            wykorzystywania informacji z zamieszczonych w serwisie tekstów, ale już
            nie kopiowania całości lub części artykułów o ile nie zostało to oznaczone
            w poszczególnym materiale udostępnionym w Serwisie.
        </li>
    </ul>
    <h2>9. Zmiany Regulaminu</h2>
    <ul>
        <li>
            Wszelkie postanowienia Regulaminu mogą być w każdej chwili jednostronnie
            zmieniane przez Usługodawcę, bez podawania przyczyn.
        </li>
        <li>
            Informacja o zmianie Regulaminu będzie rozsyłana Drogą Elektroniczną do
            Usługobiorców zarejestrowanych w Serwisie.
        </li>
        <li>
            W przypadku zmiany Regulaminu jego postanowienia wchodzą w życie
            natychmiast po jego publikacji dla Usługobiorców nieposiadających konta w
            Serwisie.
        </li>
        <li>
            W przypadku zmiany Regulaminu jego postanowienia wchodzą w życie z
            7-dniowym okresem przejściowym dla Usługobiorców posiadających konta w
            Serwisie zarejestrowane przez zmianą Regulaminu.
        </li>
        <li>
            Traktuje się iż każdy Usługobiorca, kontynuujący korzystanie z Serwisu po
            zmianie Regulaminu akceptuje go w całości.
        </li>
    </ul>
    <h2>10. Postanowienia końcowe</h2>
    <ul>
        <li>
            Usługodawca nie odpowiada w żaden sposób, jak tylko pozwalają na to
            obowiązujące przepisy prawa, za treści przekazywane i publikowane w
            Serwisie przez Usługobiorców, za ich prawdziwość, rzetelność,
            autentyczność czy wady prawne.
        </li>
        <li>
            Usługodawca dokona wszelkich starań by usługi Serwisu były oferowane w
            sposób ciągły. Nie ponosi on jednak żadnej odpowiedzialności za zakłócenia
            spowodowane siłą wyższą lub niedozwoloną ingerencją Usługobiorców, osób
            trzecich czy działalnością zewnętrznych automatycznych programów.
        </li>
        <li>
            Usługodawca zastrzega sobie prawo do zmiany jakichkolwiek informacji
            umieszczonych w Serwisie w wybranym przez Usługodawcę terminie, bez
            konieczności uprzedniego powiadomienia Usługobiorców korzystających z
            usług Serwisu.
        </li>
        <li>
            Usługodawca zastrzega sobie prawo do czasowego, całkowitego lub
            częściowego wyłączenia Serwisu w celu jego ulepszenia, dodawania usług lub
            przeprowadzania konserwacji, bez wcześniejszego uprzedzania o tym
            Usługobiorców.
        </li>
        <li>
            Usługodawca zastrzega sobie prawo do wyłączenia Serwisu na stałe, bez
            wcześniejszego uprzedzania o tym Usługobiorców.
        </li>
        <li>
            Usługodawca zastrzega sobie prawo do dokonania cesji w części lub w
            całości wszelkich swoich praw i obowiązków związanych z Serwisem, bez
            zgody i możliwości wyrażania jakichkolwiek sprzeciwów przez Usługobiorców.
        </li>
        <li>
            Obowiązujące oraz poprzednie Regulaminy Serwisu znajduję się na tej
            podstronie pod aktualnym Regulaminem.
        </li>
        <li>
            We wszelkich sprawach związanych z działalnością Serwisu należy
            kontaktować się z Usługodawcę korzystając z jednej z poniższych form
            kontaktu:
            <ul>
                <li>Wysyłając wiadomość na adres e-mail: sebastian@skoczur.pl</li>
                <li>Poprzez połączenie telefoniczne z numerem: </li>
            </ul>
            Kontakt przy użyciu wskazanych środków komunikacji wyłącznie w sprawach
            związanych z prowadzonym Serwisem.
        </li>
    </ul>
</article>;

const privacy = <article>
    <h1 style={{ textAlign: "center" }}>Polityka Prywatności</h1>
    <br />
    <p>
        Poniższa Polityka Prywatności określa{" "}
        <strong>
            zasady zapisywania i uzyskiwania dostępu do danych na Urządzeniach
            Użytkowników
        </strong>{" "}
        korzystających z Serwisu do celów świadczenia usług drogą elektroniczną
        przez Administratora oraz{" "}
        <strong>
            zasady gromadzenia i przetwarzania danych osobowych Użytkowników
        </strong>
        , które zostały podane przez nich osobiście i dobrowolnie za pośrednictwem
        narzędzi dostępnych w Serwisie.
    </p>
    <p>
        Poniższa Polityka Prywatności jest integralną częścią{" "}
        <a href={routes.global.rules}>Regulaminu Serwisu</a>, który określa
        zasady, prawa i obowiązki Użytkowników korzystających z Serwisu.
    </p>
    <h2 style={{ textAlign: "center" }}>§1 Definicje</h2>
    <ul>
        <li>
            <p>
                <strong>Serwis</strong> - serwis internetowy "QRInvite" działający pod
                adresem https://qri.skoczur.pl
            </p>
        </li>
        <li>
            <p>
                <strong>Serwis zewnętrzny</strong> - serwisy internetowe partnerów,
                usługodawców lub usługobiorców współpracujących z Administratorem
            </p>
        </li>
        <li>
            <p>
                <strong>Administrator Serwisu / Danych</strong> - Administratorem
                Serwisu oraz Administratorem Danych (dalej Administrator) jest osoba
                fizyczna "Sebastian Koczur" zamieszkała w Andrychowie, świadcząca usługi
                drogą elektroniczną za pośrednictwem Serwisu
            </p>
        </li>
        <li>
            <p>
                <strong>Użytkownik</strong> - osoba fizyczna, dla której Administrator
                świadczy usługi drogą elektroniczną za pośrednictwem Serwisu.
            </p>
        </li>
        <li>
            <p>
                <strong>Urządzenie</strong> - elektroniczne urządzenie wraz z
                oprogramowaniem, za pośrednictwem którego Użytkownik uzyskuje dostęp do
                Serwisu
            </p>
        </li>
        <li>
            <p>
                <strong>Cookies (ciasteczka)</strong> - dane tekstowe gromadzone w
                formie plików zamieszczanych na Urządzeniu Użytkownika
            </p>
        </li>
        <li>
            <p>
                <strong>RODO</strong> - Rozporządzenie Parlamentu Europejskiego i Rady
                (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
                swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                (ogólne rozporządzenie o ochronie danych){" "}
            </p>
        </li>
        <li>
            <p>
                <strong>Dane osobowe</strong> - oznaczają informacje o zidentyfikowanej
                lub możliwej do zidentyfikowania osobie fizycznej („osobie, której dane
                dotyczą”); możliwa do zidentyfikowania osoba fizyczna to osoba, którą
                można bezpośrednio lub pośrednio zidentyfikować, w szczególności na
                podstawie identyfikatora takiego jak imię i nazwisko, numer
                identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub jeden
                bądź kilka szczególnych czynników określających fizyczną, fizjologiczną,
                genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość
                osoby fizycznej{" "}
            </p>
        </li>
        <li>
            <p>
                <strong>Przetwarzanie</strong> - oznacza operację lub zestaw operacji
                wykonywanych na danych osobowych lub zestawach danych osobowych w sposób
                zautomatyzowany lub niezautomatyzowany, taką jak zbieranie, utrwalanie,
                organizowanie, porządkowanie, przechowywanie, adaptowanie lub
                modyfikowanie, pobieranie, przeglądanie, wykorzystywanie, ujawnianie
                poprzez przesłanie, rozpowszechnianie lub innego rodzaju udostępnianie,
                dopasowywanie lub łączenie, ograniczanie, usuwanie lub niszczenie;{" "}
            </p>
        </li>
        <li>
            <p>
                <strong>Ograniczenie przetwarzania</strong> - oznacza oznaczenie
                przechowywanych danych osobowych w celu ograniczenia ich przyszłego
                przetwarzania{" "}
            </p>
        </li>
        <li>
            <p>
                <strong>Profilowanie</strong> - oznacza dowolną formę zautomatyzowanego
                przetwarzania danych osobowych, które polega na wykorzystaniu danych
                osobowych do oceny niektórych czynników osobowych osoby fizycznej, w
                szczególności do analizy lub prognozy aspektów dotyczących efektów pracy
                tej osoby fizycznej, jej sytuacji ekonomicznej, zdrowia, osobistych
                preferencji, zainteresowań, wiarygodności, zachowania, lokalizacji lub
                przemieszczania się{" "}
            </p>
        </li>
        <li>
            <p>
                <strong>Zgoda</strong> - zgoda osoby, której dane dotyczą oznacza
                dobrowolne, konkretne, świadome i jednoznaczne okazanie woli, którym
                osoba, której dane dotyczą, w formie oświadczenia lub wyraźnego
                działania potwierdzającego, przyzwala na przetwarzanie dotyczących jej
                danych osobowych{" "}
            </p>
        </li>
        <li>
            <p>
                <strong>Naruszenie ochrony danych osobowych</strong> - oznacza
                naruszenie bezpieczeństwa prowadzące do przypadkowego lub niezgodnego z
                prawem zniszczenia, utracenia, zmodyfikowania, nieuprawnionego
                ujawnienia lub nieuprawnionego dostępu do danych osobowych przesyłanych,
                przechowywanych lub w inny sposób przetwarzanych{" "}
            </p>
        </li>
        <li>
            <p>
                <strong>Pseudonimizacja</strong> - oznacza przetworzenie danych
                osobowych w taki sposób, by nie można ich było już przypisać konkretnej
                osobie, której dane dotyczą, bez użycia dodatkowych informacji, pod
                warunkiem że takie dodatkowe informacje są przechowywane osobno i są
                objęte środkami technicznymi i organizacyjnymi uniemożliwiającymi ich
                przypisanie zidentyfikowanej lub możliwej do zidentyfikowania osobie
                fizycznej{" "}
            </p>
        </li>
        <li>
            <p>
                <strong>Anonimizacja</strong> - Anonimizacja danych to nieodwracalny
                proces operacji na danych, który niszczy / nadpisuje "dane osobowe"
                uniemożliwiając identyfikację, lub powiązanie danego rekordu z
                konkretnym użytkownikiem lub osobą fizyczną.
            </p>
        </li>
    </ul>
    <h2 style={{ textAlign: "center" }}>§2 Inspektor Ochrony Danych</h2>
    <p>
        Na podstawie Art. 37 RODO, Administrator nie powołał Inspektora Ochrony
        Danych.
    </p>
    <p>
        W sprawach dotyczących przetwarzania danych, w tym danych osobowych, należy
        kontaktować się bezpośrednio z Administratorem.
    </p>
    <h2 style={{ textAlign: "center" }}>§3 Rodzaje Plików Cookies</h2>
    <ul>
        <li>
            <p>
                <strong>Cookies wewnętrzne</strong> - pliki zamieszczane i odczytywane z
                Urządzenia Użytkownika przez system teleinformatyczny Serwisu
            </p>
        </li>
        <li>
            <p>
                <strong>Cookies zewnętrzne</strong> - pliki zamieszczane i odczytywane z
                Urządzenia Użytkownika przez systemy teleinformatyczne Serwisów
                zewnętrznych. Skrypty Serwisów zewnętrznych, które mogą umieszczać pliki
                Cookies na Urządzeniach Użytkownika zostały świadomie umieszczone w
                Serwisie poprzez skrypty i usługi udostępnione i zainstalowane w
                Serwisie{" "}
            </p>
        </li>
        <li>
            <p>
                <strong>Cookies sesyjne</strong> - pliki zamieszczane i odczytywane z
                Urządzenia Użytkownika przez Serwis
                <span id="sz1" style={{ display: "none" }}>
                    lub Serwisy zewnętrzne
                </span>{" "}
                podczas jednej sesji danego Urządzenia. Po zakończeniu sesji pliki są
                usuwane z Urządzenia Użytkownika.
            </p>
        </li>
        <li>
            <p>
                <strong>Cookies trwałe</strong> - pliki zamieszczane i odczytywane z
                Urządzenia Użytkownika przez Serwis
                <span id="sz2" style={{ display: "none" }}>
                    lub Serwisy zewnętrzne
                </span>{" "}
                do momentu ich ręcznego usunięcia. Pliki nie są usuwane automatycznie po
                zakończeniu sesji Urządzenia chyba że konfiguracja Urządzenia
                Użytkownika jest ustawiona na tryb usuwanie plików Cookie po zakończeniu
                sesji Urządzenia.
            </p>
        </li>
    </ul>
    <h2 style={{ textAlign: "center" }}>§4 Bezpieczeństwo składowania danych</h2>
    <ul>
        <li>
            <p>
                <strong>Mechanizmy składowania i odczytu plików Cookie</strong> -
                Mechanizmy składowania, odczytu i wymiany danych pomiędzy Plikami
                Cookies zapisywanymi na Urządzeniu Użytkownika a Serwisem są realizowane
                poprzez wbudowane mechanizmy przeglądarek internetowych i nie pozwalają
                na pobieranie innych danych z Urządzenia Użytkownika lub danych innych
                witryn internetowych, które odwiedzał Użytkownik, w tym danych osobowych
                ani informacji poufnych. Przeniesienie na Urządzenie Użytkownika
                wirusów, koni trojańskich oraz innych robaków jest także praktycznie
                niemożliwe.
            </p>
        </li>
        <li>
            <p>
                <strong>Cookie wewnętrzne</strong> - zastosowane przez Administratora
                pliki Cookie są bezpieczne dla Urządzeń Użytkowników i nie zawierają
                skryptów, treści lub informacji mogących zagrażać bezpieczeństwu danych
                osobowych lub bezpieczeństwu Urządzenia z którego korzysta Użytkownik.
            </p>
        </li>
        <li>
            <p>
                <strong>Cookie zewnętrzne</strong> - Administrator dokonuje wszelkich
                możliwych działań w celu weryfikacji i doboru partnerów serwisu w
                kontekście bezpieczeństwa Użytkowników. Administrator do współpracy
                dobiera znanych, dużych partnerów o globalnym zaufaniu społecznym. Nie
                posiada on jednak pełnej kontroli nad zawartością plików Cookie
                pochodzących od zewnętrznych partnerów. Za bezpieczeństwo plików Cookie,
                ich zawartość oraz zgodne z licencją wykorzystanie przez zainstalowane w
                serwisie Skrypty, pochodzących z Serwisów zewnętrznych, Administrator
                nie ponosi odpowiedzialności na tyle na ile pozwala na to prawo. Lista
                partnerów zamieszczona jest w dalszej części Polityki Prywatności.
            </p>
        </li>
        <li>
            <p>
                <strong>Kontrola plików Cookie</strong>
            </p>
            <ul>
                <li>
                    <p>
                        Użytkownik może w dowolnym momencie, samodzielnie zmienić ustawienia
                        dotyczące zapisywania, usuwania oraz dostępu do danych zapisanych
                        plików Cookies przez każdą witrynę internetową
                    </p>
                </li>
                <li>
                    <p>
                        Informacje o sposobie wyłączenia plików Cookie w najpopularniejszych
                        przeglądarkach komputerowych dostępne są na stronie:{" "}
                        <a rel="external" href="https://nety.pl/jak-wylaczyc-pliki-cookie/">
                            jak wyłączyć cookie
                        </a>{" "}
                        lub u jednego ze wskazanych dostawców:
                    </p>
                    <ul>
                        <li>
                            <a
                                rel="nofollow external"
                                href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pl"
                            >
                                Zarządzanie plikami cookies w przeglądarce{" "}
                                <strong>Chrome</strong>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="nofollow external"
                                href="https://help.opera.com/pl/latest/web-preferences/"
                            >
                                Zarządzanie plikami cookies w przeglądarce{" "}
                                <strong>Opera</strong>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="nofollow external"
                                href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek"
                            >
                                Zarządzanie plikami cookies w przeglądarce{" "}
                                <strong>FireFox</strong>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="nofollow external"
                                href="https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies"
                            >
                                Zarządzanie plikami cookies w przeglądarce <strong>Edge</strong>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="nofollow external"
                                href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac"
                            >
                                Zarządzanie plikami cookies w przeglądarce{" "}
                                <strong>Safari</strong>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="nofollow external"
                                href="https://windows.microsoft.com/pl-pl/internet-explorer/delete-manage-cookies#ie=ie-11"
                            >
                                Zarządzanie plikami cookies w przeglądarce{" "}
                                <strong>Internet Explorer 11</strong>
                            </a>
                        </li>
                    </ul>
                    <p />
                </li>
                <li>
                    <p>
                        Użytkownik może w dowolnym momencie usunąć wszelkie zapisane do tej
                        pory pliki Cookie korzystając z narzędzi Urządzenia Użytkownika, za
                        pośrednictwem którego Użytkownik korzysta z usług Serwisu.
                    </p>
                </li>
            </ul>
            <p />
        </li>
        <li>
            <p>
                <strong>Zagrożenia po stronie Użytkownika </strong> - Administrator
                stosuje wszelkie możliwe środki techniczne w celu zapewnienia
                bezpieczeństwa danych umieszczanych w plikach Cookie. Należy jednak
                zwrócić uwagę, że zapewnienie bezpieczeństwa tych danych zależy od obu
                stron w tym działalności Użytkownika. Administrator nie bierze
                odpowiedzialności za przechwycenie tych danych, podszycie się pod sesję
                Użytkownika lub ich usunięcie, na skutek świadomej lub nieświadomej
                działalność Użytkownika, wirusów, koni trojańskich i innego
                oprogramowania szpiegującego, którymi może jest lub było zainfekowane
                Urządzenie Użytkownika. Użytkownicy w celu zabezpieczenia się przed tymi
                zagrożeniami powinni stosować się do{" "}
                <a rel="external" href="https://nety.pl/cyberbezpieczenstwo/">
                    zasad korzystania z internetu
                </a>
                .
            </p>
        </li>
        <li>
            <p>
                <strong>Przechowywanie danych osobowych</strong> - Administrator
                zapewnia, że dokonuje wszelkich starań, by przetwarzane dane osobowe
                wprowadzone dobrowolnie przez Użytkowników były bezpieczne, dostęp do
                nich był ograniczony i realizowany zgodnie z ich przeznaczeniem i celami
                przetwarzania. Administrator zapewnia także, że dokonuje wszelkich
                starań w celu zabezpieczenia posiadanych danych przed ich utratą,
                poprzez stosowanie odpowiednich zabezpieczeń fizycznych jak i
                organizacyjnych.
            </p>
        </li>
        <li>
            <p>
                <strong>Przechowywanie haseł</strong> - Administrator oświadcza, że
                hasła przechowywane są w zaszyfrowanej postaci, używając najnowszych
                standardów i wytycznych w tym zakresie. Deszyfracja podawanych w
                Serwisie haseł dostępu do konta jest praktycznie niemożliwa.
            </p>
        </li>
    </ul>
    <h2 style={{ textAlign: "center" }}>
        §5 Cele do których wykorzystywane są pliki Cookie
    </h2>
    <ul id="cele">
        <li>Usprawnienie i ułatwienie dostępu do Serwisu</li>
        <li>Personalizacja Serwisu dla Użytkowników</li>
        <li>Umożliwienie Logowania do serwisu</li>
        <li>Świadczenie usług społecznościowych</li>
    </ul>
    <h2 style={{ textAlign: "center" }}>
        §6 Cele przetwarzania danych osobowych
    </h2>
    <p>
        Dane osobowe dobrowolnie podane przez Użytkowników są przetwarzane w jednym
        z następujących celów:
    </p>
    <ul>
        <li>
            Realizacji usług elektronicznych:
            <ul>
                <li>
                    Usługi rejestracji i utrzymania konta Użytkownika w Serwisie i
                    funkcjonalności z nim związanych
                </li>
            </ul>
        </li>
        <li>
            Komunikacji Administratora z Użytkownikami w sprawach związanych z
            Serwisem oraz ochrony danych
        </li>
        <li>Zapewnienia prawnie uzasadnionego interesu Administratora</li>
    </ul>
    <p>
        Dane o Użytkownikach gromadzone anonimowo i automatycznie są przetwarzane w
        jednym z następujących celów:
    </p>
    <ul>
        <li>Zapewnienia prawnie uzasadnionego interesu Administratora</li>
    </ul>
    <h2 style={{ textAlign: "center" }}>
        §7 Pliki Cookies Serwisów zewnętrznych
    </h2>
    <p id="zewinfo">
        Administrator w Serwisie wykorzystuje skrypty javascript i komponenty webowe
        partnerów, którzy mogą umieszczać własne pliki cookies na Urządzeniu
        Użytkownika. Pamiętaj, że w ustawieniach swojej przeglądarki możesz sam
        decydować o dozwolonych plikach cookies jakie mogą być używane przez
        poszczególne witryny internetowe. Poniżej znajduje się lista partnerów lub
        ich usług zaimplementowanych w Serwisie, mogących umieszczać pliki cookies:{" "}
    </p>
    <ul id="zewnetrzne">
        <li>
            <strong>Usługi społecznościowe / łączone:</strong>
            <br />
            (Rejestracja, Logowanie, udostępnianie treści, komunikacja, itp.)
            <br />
            <ul>
                <li>
                    <a
                        rel="nofollow external"
                        href="https://www.facebook.com/legal/terms"
                    >
                        Facebook
                    </a>
                </li>
            </ul>
        </li>
    </ul>
    <p>
        Usługi świadczone przez podmioty trzecie są poza kontrolą Administratora.
        Podmioty te mogą w każdej chwili zmienić swoje warunki świadczenia usług,
        polityki prywatności, cel przetwarzania danych oraz sposów wykorzystywania
        plików cookie.
    </p>
    <h2 style={{ textAlign: "center" }}>§8 Rodzaje gromadzonych danych</h2>
    <p>
        Serwis gromadzi dane o Użytkownikach. Cześć danych jest gromadzona
        automatycznie i anonimowo, a część danych to dane osobowe podane dobrowolnie
        przez Użytkowników w trakcie zapisywania się do poszczególnych usług
        oferowanych przez Serwis.
    </p>
    <p>
        <strong>Anonimowe dane gromadzone automatycznie:</strong>
    </p>
    <ul>
        <li>Adres IP</li>
        <li>Typ przeglądarki</li>
        <li>Rozdzielczość ekranu</li>
        <li>Przybliżona lokalizacja</li>
        <li>Otwierane podstrony serwisu</li>
        <li>Czas spędzony na odpowiedniej podstronie serwisu</li>
        <li>Rodzaj systemu operacyjnego</li>
        <li>Adres poprzedniej podstrony</li>
        <li>Adres strony odsyłającej</li>
        <li>Język przeglądarki</li>
        <li>Prędkość łącza internetowego</li>
        <li>Dostawca usług internetowych</li>
    </ul>
    <p>
        <strong>Dane gromadzone podczas rejestracji:</strong>
    </p>
    <ul>
        <li>Imię / nazwisko / pseudonim</li>
        <li>Adres e-mail</li>
        <li>Inne dane zwykłe</li>
    </ul>
    <p>
        <strong>Dane gromadzone podczas zapisu do usługi Newsletter</strong>
    </p>
    <ul>
        <li>Adres e-mail</li>
    </ul>
    <p>
        Część danych (bez danych identyfikujących) może być przechowywana w plikach
        cookies. Cześć danych (bez danych identyfikujących) może być przekazywana do
        dostawcy usług statystycznych.
    </p>
    <h2 style={{ textAlign: "center" }}>
        §9 Dostęp do danych osobowych przez podmioty trzecie
    </h2>
    <p>
        Co do zasady jedynym odbiorcą danych osobowych podawanych przez Użytkowników
        jest Administrator. Dane gromadzone w ramach świadczonych usług nie są
        przekazywane ani odsprzedawane podmiotom trzecim.
    </p>
    <p>
        Dostęp do danych (najczęściej na podstawie Umowy powierzenia przetwarzania
        danych) mogą posiadać podmioty, odpowiedzialne za utrzymania infrastruktury
        i usług niezbędnych do prowadzenia serwisu tj.:
    </p>
    <ul>
        <li>
            Firmy hostingowe, świadczące usługi hostingu lub usług pokrewnych dla
            Administratora
        </li>
    </ul>
    <p>
        <strong>
            Powierzenie przetwarzania danych osobowych - Usługi Hostingu, VPS lub
            Serwerów Dedykowanych
        </strong>
    </p>
    <p>
        Administrator w celu prowadzenia serwisu korzysta z usług zewnętrznego
        dostawcy hostingu, VPS lub Serwerów Dedykowanych -{" "}
        <strong>
            <a
                rel="nofollow external"
                href="https://www.ovh.pl/ochrona-danych-osobowych/"
            >
                OVH sp. z o.o.
            </a>
        </strong>
        . Wszelkie dane gromadzone i przetwarzane w serwisie są przechowywane i
        przetwarzane w infrastrukturze usługodawcy zlokalizowanej w obrębie gramic
        Unii Europejskiej. Istnieje możliwość dostępu do danych wskutek prac
        serwisowych realizowanych przez personel usługodawcy. Dostęp do tych danych
        reguluje umowa zawarta pomiędzy Administratorem a Usługodawcą.
    </p>
    <br />
    <h2 style={{ textAlign: "center" }}>
        §10 Sposób przetwarzania danych osobowych
    </h2>
    <p>
        <strong>Dane osobowe podane dobrowolnie przez Użytkowników:</strong>
    </p>
    <ul>
        <li>
            Dane osobowe nie będą przekazywane poza Unię Europejską, chyba że zostały
            opublikowane na skutek indywidualnego działania Użytkownika (np.
            wprowadzenie komentarza lub wpisu), co sprawi, że dane będą dostępne dla
            każdej osoby odwiedzającej serwis.
        </li>
        <li>
            Dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania
            decyzji (profilowania).
        </li>
        <li>Dane osobowe nie będą odsprzedawane podmiotom trzecim.</li>
    </ul>
    <p>
        <strong>
            Dane anonimowe (bez danych osobowych) gromadzone automatycznie:
        </strong>
    </p>
    <ul>
        <li>
            Dane anonimiwe (bez danych osobowych) nie będą przekazywane poza Unię
            Europejską.
        </li>
        <li>
            Dane anonimiwe (bez danych osobowych) nie będą wykorzystywane do
            zautomatyzowanego podejmowania decyzji (profilowania).
        </li>
        <li>
            Dane anonimiwe (bez danych osobowych) nie będą odsprzedawane podmiotom
            trzecim.
        </li>
    </ul>
    <h2 style={{ textAlign: "center" }}>
        §11 Podstawy prawne przetwarzania danych osobowych
    </h2>
    <p>Serwis gromadzi i przetwarza dane Użytkowników na podstawie:</p>
    <ul>
        <li>
            Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
            kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
            przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
            danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
            danych)
            <ul>
                <li>
                    art. 6 ust. 1 lit. a<br />
                    <small>
                        osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich
                        danych osobowych w jednym lub większej liczbie określonych celów
                    </small>
                </li>
                <li>
                    art. 6 ust. 1 lit. b<br />
                    <small>
                        przetwarzanie jest niezbędne do wykonania umowy, której stroną jest
                        osoba, której dane dotyczą, lub do podjęcia działań na żądanie
                        osoby, której dane dotyczą, przed zawarciem umowy
                    </small>
                </li>
                <li>
                    art. 6 ust. 1 lit. f<br />
                    <small>
                        przetwarzanie jest niezbędne do celów wynikających z prawnie
                        uzasadnionych interesów realizowanych przez administratora lub przez
                        stronę trzecią
                    </small>
                </li>
            </ul>
        </li>
        <li>
            Ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz.U. 2018 poz.
            1000)
        </li>
        <li>
            Ustawa z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. 2004 nr 171
            poz. 1800)
        </li>
        <li>
            Ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych
            (Dz. U. 1994 Nr 24 poz. 83)
        </li>
    </ul>
    <h2 style={{ textAlign: "center" }}>
        §12 Okres przetwarzania danych osobowych
    </h2>
    <p>
        <strong>Dane osobowe podane dobrowolnie przez Użytkowników:</strong>
    </p>
    <p>
        Co do zasady wskazane dane osobowe są przechowywane wyłącznie przez okres
        świadczenia Usługi w ramach Serwisu przez Administratora. Są one usuwane lub
        anonimizowane w okresie do 30 dni od chwili zakończenia świadczenia usług
        (np. usunięcie zarejestrowanego konta użytkownika, wypisanie z listy
        Newsletter, itp.)
    </p>
    <p>
        Wyjątek stanowi sytuacja, która wymaga zabezpieczenia prawnie uzasadnionych
        celów dalszego przetwarzania tych danych przez Administratora. W takiej
        sytuacji Administrator będzie przechowywał wskazane dane, od czasu żądania
        ich usunięcia przez Użytkownika, nie dłużej niż przez okres 3 lat w
        przypadku naruszenia lub podejrzenia naruszenia zapisów regulaminu serwisu
        przez Użytkownika
    </p>
    <p>
        <strong>
            Dane anonimowe (bez danych osobowych) gromadzone automatycznie:
        </strong>
    </p>
    <p>
        Anonimowe dane statystyczne, niestanowiące danych osobowych, są
        przechowywane przez Administratora w celu prowadzenia statystyk serwisu
        przez czas nieoznaczony
    </p>
    <h2 style={{ textAlign: "center" }}>
        §13 Prawa Użytkowników związane z przetwarzaniem danych osobowych
    </h2>
    <p>Serwis gromadzi i przetwarza dane Użytkowników na podstawie:</p>
    <ul>
        <li>
            <p>
                <strong>Prawo dostępu do danych osobowych</strong>
                <br />
                Użytkownikom przysługuje prawo uzyskania dostępu do swoich danych
                osobowych, realizowane na żądanie złożone do Administratora
            </p>
        </li>
        <li>
            <p>
                <strong>Prawo do sprostowania danych osobowych</strong>
                <br />
                Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego
                sprostowania danych osobowych, które są nieprawidłowe lub / oraz
                uzupełnienia niekompletnych danych osobowych, realizowane na żądanie
                złożone do Administratora
            </p>
        </li>
        <li>
            <p>
                <strong>Prawo do usunięcia danych osobowych</strong>
                <br />
                Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego
                usunięcia danych osobowych, realizowane na żądanie złożone do
                AdministratoraW przypadku kont użytkowników, usunięcie danych polega na
                anonimizacji danych umożliwiających identyfikację Użytkownika.
                Administrator zastrzega sobie prawo wstrzymania realizacji żądania
                usunięcia danych w celu ochrony prawnie uzasadnionego interesu
                Administratora (np. w gdy Użytkownik dopuścił się naruszenia Regulaminu
                czy dane zostały pozyskane wskutek prowadzonej korespondencji).
                <br />W przypadku usługi Newsletter, Użytkownik ma możliwość
                samodzielnego usunięcia swoich danych osobowych korzystając z odnośnika
                umieszczonego w każdej przesyłanej wiadomości e-mail.
            </p>
        </li>
        <li>
            <p>
                <strong>Prawo do ograniczenia przetwarzania danych osobowych</strong>
                <br />
                Użytkownikom przysługuje prawo ograniczenia przetwarzania danych
                osobowych w przypadkach wskazanych w art. 18 RODO, m.in. kwestionowania
                prawidłowość danych osobowych, realizowane na żądanie złożone do
                Administratora
            </p>
        </li>
        <li>
            <p>
                <strong>Prawo do przenoszenia danych osobowych</strong>
                <br />
                Użytkownikom przysługuje prawo uzyskania od Administratora, danych
                osobowych dotyczących Użytkownika w ustrukturyzowanym, powszechnie
                używanym formacie nadającym się do odczytu maszynowego, realizowane na
                żądanie złożone do Administratora
            </p>
        </li>
        <li>
            <p>
                <strong>
                    Prawo wniesienia sprzeciwu wobec przetwarzania danych osobowych
                </strong>
                <br />
                Użytkownikom przysługuje prawo wniesienia sprzeciwu wobec przetwarzania
                jego danych osobowych w przypadkach określonych w art. 21 RODO,
                realizowane na żądanie złożone do Administratora
            </p>
        </li>
        <li>
            <p>
                <strong>Prawo wniesienia skargi</strong>
                <br />
                Użytkownikom przysługuje prawo wniesienia skargi do organu nadzorczego
                zajmującego się ochroną danych osobowych.
            </p>
        </li>
    </ul>
    <h2 style={{ textAlign: "center" }}>§14 Kontakt do Administratora</h2>
    <p>Z Administratorem można skontaktować się w jeden z poniższych sposobów</p>
    <ul>
        <li>
            <p>
                <strong>Adres poczty elektronicznej</strong> - sebastian@skoczur.pl
            </p>
        </li>
        <li>
            <p>
                <strong>Formularz kontaktowy</strong> - dostępny pod adresem: /kontakt
            </p>
        </li>
    </ul>
    <h2 style={{ textAlign: "center" }}>§15 Wymagania Serwisu</h2>
    <ul>
        <li>
            <p>
                Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu Użytkownika
                może spowodować nieprawidłowe działanie niektórych funkcji Serwisu.
            </p>
        </li>
        <li>
            <p>
                Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo
                działające funkcje Serwisu w przypadku gdy Użytkownik ograniczy w
                jakikolwiek sposób możliwość zapisywania i odczytu plików Cookie.
            </p>
        </li>
    </ul>
    <h2 style={{ textAlign: "center" }}>§16 Linki zewnętrzne</h2>
    <p>
        W Serwisie - artykułach, postach, wpisach czy komentarzach Użytkowników mogą
        znajdować się odnośniki do witryn zewnętrznych, z którymi Właściciel serwisu
        nie współpracuje. Linki te oraz strony lub pliki pod nimi wskazane mogą być
        niebezpieczne dla Twojego Urządzenia lub stanowić zagrożenie bezpieczeństwa
        Twoich danych. Administrator nie ponosi odpowiedzialności za zawartość
        znajdującą się poza Serwisem.
    </p>
    <h2 style={{ textAlign: "center" }}>§17 Zmiany w Polityce Prywatności</h2>
    <ul>
        <li>
            <p>
                Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej
                Polityki Prywatności bez konieczności informowania o tym Użytkowników w
                zakresie stosowania i wykorzystywania danych anonimowych lub stosowania
                plików Cookie.
            </p>
        </li>
        <li>
            <p>
                Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej
                Polityki Prywatności w zakresie przetwarzania Danych Osobowych, o czym
                poinformuje Użytkowników posiadających konta użytkownika lub zapisanych
                do usługi newsletter, za pośrednictwem poczty elektronicznej w terminie
                do 7 dni od zmiany zapisów. Dalsze korzystanie z usług oznacza
                zapoznanie się i akceptację wprowadzonych zmian Polityki Prywatności. W
                przypadku w którym Użytkownik nie będzie się zgadzał z wprowadzonymi
                zmianami, ma obowiązek usunąć swoje konto z Serwisu lub wypisać się z
                usługi Newsletter.
            </p>
        </li>
        <li>
            <p>
                Wprowadzone zmiany w Polityce Prywatności będą publikowane na tej
                podstronie Serwisu.
            </p>
        </li>
        <li>
            <p>Wprowadzone zmiany wchodzą w życie z chwilą ich publikacji.</p>
        </li>
    </ul>
</article>;

export { rodo, regulations, privacy }