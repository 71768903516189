import { Form, FloatingLabel } from "react-bootstrap";

const FastSelect = (props) => {

    const values = props.options;
    const names = (props.names) ? props.names : props.options;

    let indexOfValue = -1;
    let value;

    if(values){
        if (!values.includes(props.value)) {
            if (props.default) {
                value = props.default;
            } else {
                value = "?";
            }
        } else {
            value = props.value;
            indexOfValue = values.indexOf(value);
        }
    }



    function FormSelectRener() {
        return (
            <Form.Select id={props.id} size={props.size} onChange={(e) => props.onChange(e.target.value)}
                className={props.selectClassName} disabled={!props.enabled} value={value}>

                {indexOfValue === -1 && values &&
                    <option value={value} disabled hidden>{names[indexOfValue]}</option>
                }

                {values && values.map((opt, idx) => (
                    <option value={opt} key={opt}>{names[idx]}</option>
                ))}

            </Form.Select>
        );
    }

    return (<>
        {props.label &&
            <FloatingLabel controlId={props.id} label={props.label} size={props.size} className={props.labelClassName}>
                {FormSelectRener()}
            </FloatingLabel>
        }
        {!props.label && FormSelectRener()}

    </>);


};

export default FastSelect;