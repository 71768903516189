import { Button, Card, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import api from "Defines/Api";
import useApi from "Hooks/useApi";

import * as Icon from 'react-bootstrap-icons';

import EditPersonField from "./EditPersonField";
import { useState } from "react";
import ApiButton from "Components/Fetching/ApiButton";
import { useEffect } from "react";
import { useRef } from "react";
import FastCheck from "Components/Form/FastCheck";
import FastInput from "Components/Form/FastInput";
import routes from "Defines/Routes";
import useNewTab from "Hooks/useNewTab";
import useDebounce from "Hooks/useDebounce";
import ApiToast from "Components/Fetching/apiToast";
import useEffectNoFirstRender from "Hooks/useEffectNoFirstRender";
import { stringToNiceDate, stringToNiceTimeAndDate } from "Functions/DataTime";
import InfoTooltip from "Components/Others/InfoTooltip";
import RadioButtons from "Components/Form/RadioButtons";
import BreakpointInfo from "Utils/BreakpointInfo";


const InvitationSummary = (props) => {

    const newTab = useNewTab();

    const wedding = props.wedding;
    const persons = props.persons;
    const invitation = props.invitation;

    const transportRadios = [
        { text: <><Icon.CarFrontFill className="RF-3" /></>, value: 1, variant: 'outline-success', tip: "Potrzebuję" },
        { text: <><Icon.XLg className="RF-3" /></>, value: 0, variant: 'outline-danger', tip: "Nie potrzebuję" },
    ];
    const hotelRadios = [
        { text: <><Icon.House className="RF-3" /></>, value: 1, variant: 'outline-success', tip: "Potrzebuję" },
        { text: <><Icon.XLg className="RF-3" /></>, value: 0, variant: 'outline-danger', tip: "Nie potrzebuję" },
    ];


    /*

     $table->id();
            $table->timestamps();

            $table->unsignedBigInteger('weddingId');
            $table->foreign('weddingId')->references('id')->on('weddings')->cascadeOnDelete();

            $table->string('group',60)->nullable();

            $table->string('notifyEmail', 100)->nullable();
            $table->string('notePublic', 256)->nullable();
            $table->string('notePrivate', 256)->nullable();

            $table->boolean('publicQrCode');

            --$table->boolean('inviteDelivered');
            --$table->boolean('acceptTransport');
            --$table->boolean('acceptHotel');

            $table->dateTime('lastVisited')->nullable();

            */

    return (<>

        {invitation && <>

            <Card className="mt-5">
                <Card.Header className="RFT-2 RF-4 d-flex flex-column flex-lg-row justify-content-beetwen align-items-center">
                    <div className="w-100 d-flex justify-content-center align-items-center">
                        {invitation.name}
                    </div>
                    <div className="RF-2 d-flex justify-content-center align-items-center gap-2">
                        {invitation.publicQrCode &&
                            <InfoTooltip placement="bottom" className="RFT-2" tooltip="Zaproszenie interaktywne">
                                <Icon.QrCode className="text-success" />
                            </InfoTooltip>
                        }
                        {!invitation.publicQrCode &&
                            <InfoTooltip placement="bottom" className="RFT-2" tooltip="Zaproszenie klasyczne">
                                <Icon.QrCode className="text-danger" />
                            </InfoTooltip>
                        }
                        {invitation.needTransport &&
                            <InfoTooltip placement="bottom" className="RFT-2" tooltip="Transport potrzebny">
                                <Icon.CarFrontFill className="text-success" />
                            </InfoTooltip>
                        }
                        {!invitation.needTransport &&
                            <InfoTooltip placement="bottom" className="RFT-2" tooltip="Transport nie potrzebny">
                                <Icon.CarFrontFill className="text-danger" />
                            </InfoTooltip>
                        }
                        {invitation.needHotel &&
                            <InfoTooltip placement="bottom" className="RFT-2" tooltip="Hotel potrzebny">
                                <Icon.HouseFill className="text-success" />
                            </InfoTooltip>
                        }
                        {!invitation.needHotel &&
                            <InfoTooltip placement="bottom" className="RFT-2" tooltip="Hotel nie potrzebny">
                                <Icon.HouseFill className="text-danger" />
                            </InfoTooltip>
                        }
                        {invitation.lastVisited &&
                            <InfoTooltip placement="bottom" className="RFT-2" tooltip="Zaproszenie otworzone">
                                <Icon.EnvelopePaperHeartFill className="text-success" />
                            </InfoTooltip>
                        }
                        {!invitation.lastVisited && invitation.inviteDelivered &&
                            <InfoTooltip placement="bottom" className="RFT-2" tooltip="Zaproszenie dostarczone">
                                <Icon.EnvelopeFill className="text-info" />
                            </InfoTooltip>
                        }
                        {!invitation.lastVisited && !invitation.inviteDelivered &&
                            <InfoTooltip placement="bottom" className="RFT-2" tooltip="Zaproszenie nie dostarczone">
                                <Icon.ArchiveFill className="text-muted" />
                            </InfoTooltip>
                        }
                    </div>
                </Card.Header>

                <Card.Footer>
                        asd
                </Card.Footer>

                <Card.Body>

                    {Array.isArray(persons) && persons.length > 0 &&
                        persons.map((person, idx, array) => (<span key={person.id}>
                            <EditPersonField person={person} wedding={wedding}
                                onPersonEdit={props.onPersonEdit} />
                            {array.length - 1 !== idx && <span className="d-xl-none"><hr className="mx-0" /></span>}
                        </span>))
                    }
                    {Array.isArray(persons) && persons.length == 0 && <>
                        <Row>
                            <Col className="RFT-2 text-center text-muted">
                                Jeszcze nie dodano osób do tego zaproszenia, możesz to zrobic używając przycisku <Icon.PersonFillAdd className="text-success" />
                            </Col>
                        </Row>
                    </>}

                </Card.Body>

                <Card.Footer>
                    <Row className="RFT-2">

                        <Col xs={10} sm={6} md={5} className="d-flex justify-content-center align-items-center">
                            {invitation.lastVisited && <>
                                <InfoTooltip tooltip="Zaproszenie ostatnio otwarte dnia" className="RFT-2">
                                    <Icon.EnvelopeOpenFill className="me-2" />
                                    {stringToNiceDate(invitation.lastVisited)}
                                </InfoTooltip>

                            </>}
                            {!invitation.lastVisited && <>
                                <InfoTooltip tooltip="Zaproszenie nie zostało jeszcze otwarte" className="RFT-2">
                                    <Icon.CalendarXFill className="me-2" />
                                    Nie otworzone
                                </InfoTooltip>

                            </>}
                        </Col>
                        <Col xs={10} sm={6} md={5} className="d-flex justify-content-center align-items-center">
                            {invitation.notifyEmailAnonymous && <>
                                <InfoTooltip tooltip="Dodano adres email do potwierdzenia zaproszenia" className="RFT-2">
                                    <Icon.EnvelopeAtFill className="me-2" />
                                    {invitation.notifyEmailAnonymous}
                                </InfoTooltip>

                            </>}
                            {!invitation.notifyEmailAnonymous && <>
                                <InfoTooltip tooltip="Jeszcze nie dodano adresu email do zaproszenia" className="RFT-2">
                                    <Icon.EnvelopeSlashFill className="me-2" />
                                    Nie dodano
                                </InfoTooltip>

                            </>}
                        </Col>

                        <Col xs={2} sm={6} md={2} className="d-flex justify-content-end">
                            <ApiButton variant="success" api={api.persons.post} sendData={{ invitationId: invitation.id, name: "Nowa osoba" }}
                                onSuccess={(dat) => props.onPersonEdit(dat)} showError hideTextOnPending size="sm"
                                labelClassName="RFT-2 RF-4 text-white d-flex align-items-center" className="">
                                <Icon.PersonFillAdd />
                            </ApiButton>

                        </Col>
                    </Row>
                </Card.Footer>



            </Card>

        </>}


    </>);
}

export default InvitationSummary;