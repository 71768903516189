import { useDocumentTitle } from "usehooks-ts";

const ContactScreen = (props) => {

    useDocumentTitle("Kontakt");

    return (<>
        <div className="mt-3">
            <div className="h4">Sebastian Koczur</div>
            <a href = "mailto: s.koczur97@gmail.com">s.koczur97@gmail.com</a>
        </div>
    </>)
}

export default ContactScreen;