import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { useEffect, useState } from 'react';

import api from "Defines/Api";
import useApi from "Hooks/useApi";

import './MessageSender.css'
import 'CSS/fonts.css'

import PostToast from "Components/Fetching/PostToast";


const MessageSender = (props) => {

    const invitation = props.invitation;

    const apiMessage = useApi(api.public.postMessage);

    const [messageValue, setMessageValue] = useState("");
    const [messageSendButtonsEnabled, setMessageSendButtonsEnabled] = useState(false);

    useEffect(() => {
        if (messageValue === "") {
            setMessageSendButtonsEnabled(false);
        } else {
            setMessageSendButtonsEnabled(true);
        }
    }, [messageValue]);

    const deleteMessage = () => {
        setMessageValue("");
    }
    const createMessage = () => {
        apiMessage.send({
            token: invitation.token,
            message: messageValue
        });
        deleteMessage();
    }


    return (<>
        <PostToast isPending={apiMessage.isPending} errors={apiMessage.errors} className="RFT-2 RF-5" />

        <Card>
            <Card.Header>
                <div className="RFT-2 RF-5 text-center">Wyślij wiadomość prywatną do pary młodej <span className="RFT-2 RF-7">(opcjonalne)</span></div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Form.Control
                            as="textarea"
                            id="inputMessage"
                            placeholder="Wpisz wiadomość..."
                            className="MessageTextArea RFT-2 RF-5"
                            onInput={(e) => {
                                e.target.style.height = "";
                                e.target.style.height = Math.min(e.target.scrollHeight + 2) + "px";

                                setMessageValue(e.target.value);
                            }}
                            value={messageValue}
                        />
                    </Col>
                </Row>
                {messageSendButtonsEnabled &&
                    <Row className="d-flex justify-content-center gap-2 mt-2">
                        <Col xs={5} lg={3}>
                            <Button variant="danger" className="MessageFormButton RFT-2 RF-6 w-100"
                                onClick={() => deleteMessage()} disabled={!messageSendButtonsEnabled}>
                                Usuń
                            </Button>

                        </Col>
                        <Col xs={5} lg={3}>
                            <Button variant="primary" className="MessageFormButton RFT-2 RF-6 w-100"
                                onClick={() => createMessage()} disabled={!messageSendButtonsEnabled}>
                                Wyślij
                            </Button>
                        </Col>
                    </Row>
                }


            </Card.Body>
        </Card>
    </>);

};

export default MessageSender;