import api from "Defines/Api";
import { getApplicationServerKeyUint8Array } from "Defines/webPush";
import useApi from "Hooks/useApi";
import useNotificationPermission from "Hooks/useNotificationPermission";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";

import * as Icon from 'react-bootstrap-icons';


const NotificationButton = (props) => {

    const [subscription, setSubscription] = useState(undefined);
    //const [subscriptionState, setSubscriptionState] = useState(undefined);

    const { browserHasNotification, notifyPermission, askForNotifyPermission } = useNotificationPermission();

    const subApiTest = useApi(api.user.notification.check)
    const subApiPost = useApi(api.user.notification.post)
    const subApiDelete = useApi(api.user.notification.delete)


    //LOAD SUB ON LOAD
    useEffect(() => {
        navigator.serviceWorker.ready
            .then(function (registration) {
                registration.pushManager.getSubscription()
                    .then(sub => {
                        if (sub) {
                            subApiTest.send({ endpoint: sub.endpoint })
                                .then(() => {
                                    setSubscription(sub);
                                }).catch((err) => {
                                    setSubscription(false);
                                    sub.unsubscribe();
                                })

                        } else {
                            setSubscription(false);
                        }

                    })
                    .catch(err => {
                        setSubscription(false);
                    });
            }).catch(err => {
                setSubscription(false);
            })
    }, []);



    //valid subscribtion if set
    useEffect(() => {

    }, [subscription])


    const click = () => {
        if (notifyPermission !== true) {
            askForNotifyPermission().then(() => {
                subscribe();
            })
        } else {
            if (subscription) {
                unsubscribe();
            } else {
                subscribe();
            }
        }
    }

    const unsubscribe = () => {
        console.log("unsubscribing")
        setSubscription(undefined);

        if (subscription) {
            subscription.unsubscribe()
                .then((successful) => {
                    subApiDelete.send({ endpoint: subscription?.endpoint }).then(() => {
                        setSubscription(false);
                        console.log("unsubscibe pass")
                    })

                })
                .catch((e) => {
                    console.log("unsubscibe fail")
                });

        } else {
            setSubscription(false);
        }

    }

    const subscribe = () => {

        console.log("subscribing")
        setSubscription(undefined);

        navigator.serviceWorker.ready
            .then(registration => {
                registration.pushManager.subscribe({
                    userVisibleOnly: true, //Set user to see every notification
                    applicationServerKey: getApplicationServerKeyUint8Array()
                })
                    .then(sub => {
                        subApiPost.send({
                            endpoint: sub.endpoint,
                            publicKey: sub.toJSON().keys.p256dh,
                            authToken: sub.toJSON().keys.auth
                        }).then(() => {
                            setSubscription(sub);
                            console.log("subscribing done")
                        })
                    })
                    .catch(function (error) {
                        console.error('Push notification subscription error: ', error);
                    });
            })
    }


    return <>
        <Button variant="outline-dark" className={props.className} onClick={click}>
            {subscription && <Icon.BellFill className="" />}
            {subscription === undefined && <Spinner size="sm" />}
            {!subscription && subscription !== undefined && <Icon.BellSlashFill className="" />}

        </Button>
    </>;
}

export default NotificationButton;