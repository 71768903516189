import { Row, Col, Image, Button } from "react-bootstrap";
import { Textfit } from 'react-textfit';
import { useEffect } from "react";

import { stringToNiceDate, deleteSeconds } from "Functions/DataTime";

import api from "Defines/Api";

import * as Icon from 'react-bootstrap-icons';
import './WeddingInfo.css'
import 'CSS/fonts.css'
import { useDocumentTitle } from "usehooks-ts";

const WeddingInfo = (props) => {

    const getData = (key) => {
        if (key in props.wedding)
            return props.wedding[key];
        return null;
    }

    useDocumentTitle(getData("bride") + " & " + getData("groom"));

    const openInNewTab = (url) => {
        window.open(url, '_blank').focus();
    }


    return (
        <>
            {props.wedding &&
                <>

                    <Row className="d-flex justify-content-center">
                        <Col className="text-center">
                            <Image alt="Header of this invitation" className="InvitationHeaderImg"
                                src={api.storage.header.showUrl(getData("token"))} fluid rounded />
                        </Col>
                    </Row>


                    <Row className="d-flex justify-content-center">
                        <div className="RFT-1 text-center"> <Textfit max={120} mode="single">{getData("bride")} & {getData("groom")}</Textfit></div>
                        <div className="RFT-2 w-75 text-muted text-center"><Textfit max={80} mode="single"> {stringToNiceDate(getData("date"), 1)} </Textfit></div>

                    </Row>


                    <hr className="my-3" />


                    <Row className="d-flex justify-content-evenly ">
                        <Col md={5} className="">
                            <Row className="d-flex justify-content-center">
                                <Textfit mode="single" max={40} className="text-center RFT-2 RF-4">
                                    <span >ŚLUB&nbsp;</span>
                                    <span className="">{deleteSeconds(getData("ceremonyHour"))}</span>
                                </Textfit>
                            </Row>
                            <Row className="RFT-2 RF-5 text-muted d-flex justify-content-center text-center">
                                {getData("ceremonyPlace")}
                            </Row>
                        </Col>

                        <Col md={5} className="">
                            <Row className="d-flex justify-content-center">
                                <Textfit mode="single" max={40} className="text-center RFT-2 RF-4">
                                    <span >WESELE&nbsp;</span>
                                    <span className="">{deleteSeconds(getData("weddingHour"))}</span>
                                </Textfit>
                            </Row>
                            <Row className="RFT-2 RF-5 text-muted d-flex justify-content-center text-center">
                                {getData("weddingPlace")}
                            </Row>
                        </Col>
                    </Row>

                    <hr className="my-3" />

                    <Row className="text-center">
                        <div className="RFT-2 text-center"><Textfit min={10} max={35} mode="single" > Prosimy o potwierdzenie przybycia do</Textfit></div>
                        <div className="RFT-2 text-muted text-center px-5"><Textfit min={18} max={32} mode="single" > {stringToNiceDate(getData("inviteResponseDate"), 1)}</Textfit></div>
                    </Row>

                    <hr className="my-3" />

                    {getData("giftVariants") &&
                        <Row className="d-flex justify-content-evenly">
                            {getData("giftVariants").map((g, idx) => (
                                <Col className="text-center" key={g+"-"+idx} xs={5} md={3} xl={2}>
                                    <Image alt={g + ". Gift recomendation"} className="GiftVariantsImages"
                                        src={api.giftVariants.showUrl(g)} fluid />
                                </Col>
                            ))}
                        </Row>
                    }



                    {getData("links") && getData("links").length > 0 && <>

                        <hr className="my-3" />

                        <Row className="d-flex justify-content-center g-2 my-2">
                            {getData("links").map((link, idx) => (
                                <Col key={link[0] + link[1] + idx} xs={10} md={5} xl={3} xxl={3} className="d-grid">
                                    <Button variant="secondary" className="rounded-lg shadow-none LinksButtons p-3 
                                        d-flex justify-content-center align-items-center" onClick={() => openInNewTab(link[1])}>
                                        <Icon.Link45deg className="LinksIcon me-2 w-10" />
                                        <div className="RFT-2 RF-6 w-90">{link[0]}</div>
                                    </Button>

                                </Col>
                            ))}
                        </Row>
                    </>}

                    <div className="my-2" />

                </>
            }


        </>
    );

};

export default WeddingInfo;