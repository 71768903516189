import { useEffect } from "react";
import { useState } from "react";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";


const ChangeNameModal = (props) => {

    //show, onCancel, onSubmit

    const [isToValidate, setIsToValidate] = useState(false);
    const [name, setName] = useState("");
    
    useEffect(()=>{
        setName(props.value)
    },[props.show])

    const onChangeName = (e) => {
        setIsToValidate(true);
        setName(e.target.value);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            setIsToValidate(false);
            props.onSubmit(name);
            setName("");
        }
    };

    return (<>
        <Modal show={props.show} onHide={props.onCancel} centered={props.centered}>

            <Modal.Header closeButton>
                <Modal.Title className="RFT-2">Zmień nazwę konta</Modal.Title>
            </Modal.Header>

            <Form noValidate validated={isToValidate} onSubmit={handleSubmit} className="w-100">
                <Modal.Body>
                    <FloatingLabel controlId="floatingNameReg" label="Nazwa" className="RFT-2" size="lg" >
                        <Form.Control type="text" placeholder=" " className="RFT-3 shadow-none mb-2" size="lg"
                            value={name} onChange={(e) => { onChangeName(e); }}
                            minLength={4} required />
                        <Form.Control.Feedback type="invalid">
                            Nazwa musi posiadać co najmniej 4 znaki
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" className="RFT-2" onClick={props.onCancel}>Anuluj</Button>
                    <Button variant="primary" type="submit" className="RFT-2">Zapisz</Button>
                </Modal.Footer>

            </Form>
        </Modal>

    </>);

};

export default ChangeNameModal;