import { Spinner } from "react-bootstrap";
import './LoadingSpinner.css'


const LoadingSpinner = () => {
    return (<>
        <div className="SpinnerBackground" />
        <Spinner animation="border" role="status" className="Spinner" />
    </>);
}

export default LoadingSpinner;