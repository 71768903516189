import { Row, Col, Button } from "react-bootstrap";

import * as Icon from 'react-bootstrap-icons';

import StatisticsPieChart from "./StatisticsPieChart";
import BreakpointInfo from "Utils/BreakpointInfo";


const WeddingShowStatistics = (props) => {

    const wedding = props.wedding;

    const getOsobText = (qty) => {
        const qty10 = qty % 10;
        if (qty10 == 0 || qty10 >= 5) return "Osób";
        if (qty10 == 1) {
            if (qty < 10)
                return "Osoba";
            else
                return "Osoby";
        }
        if (qty10 > 1 && qty10 < 5) return "Osoby";
    }
    const getZaproszeniaText = (qty) => {
        const qty10 = qty % 10;
        if (qty10 == 0 || qty10 >= 5) return "Zaproszeń";
        if (qty10 == 1) {
            if (qty < 10)
                return "Zaproszenie";
            else
                return "Zaproszenia";
        }
        if (qty10 > 1 && qty10 < 5) return "Zaproszenia";
    }

    const colors = [
        "#d6b500", // Złoty
        "#556B2F", // Zielony oliwkowy
        "#6356ba", // Błękit indygo
        "#D2691E", // Czekoladowy
        "#CD853F", // Brązowe
        "#008B8B", // Cyjan ciemny
        "#8A2BE2", // Fiolet
        "#FFD700", // Żółty
        "#4169E1", // Błękit królewski
        "#FF4500", // Pomarańczowy czerwony
        "#8B4513", // Brąz ciemny
        "#20B2AA", // Błękit morski
        "#FF69B4", // Różowy intensywny
        "#FFA07A", // Pomarańczowy pastelowy
        "#556B2F", // Zielony oliwkowy
        "#9400D3", // Purpurowy
        "#FF6347", // Pomarańczowy
        "#FF8C00", // Pomarańczowy ciemny
        "#556B2F", // Zielony oliwkowy
        "#8B0000", // Ciemnoczerwony
    ];

    const titleClassName = "RF-4 RFT-2 font-weight-bold ";
    const labelClassName = "RF-5 RFT-2 ";

    const presenceData = [
        {
            color: '#027233',
            value: wedding?.statistics.presence.yes,
            icon: <Icon.CheckLg className="RF-5" />
        },
        {
            color: '#c48106',
            value: wedding?.statistics.presence.dontKnow,
            icon: <Icon.QuestionLg className="RF-5" />
        },
        {
            color: '#cf3507',
            value: wedding?.statistics.presence.no,
            icon: <Icon.XLg className="RF-5" />
        },
    ];

    const deliveredData = [
        {
            color: '#027233',
            value: wedding?.statistics.inviteDelivered.opened,
            icon: <Icon.EnvelopePaperHeartFill className="" />
        },
        {
            color: '#0DCAF0',
            value: wedding?.statistics.inviteDelivered.yes,
            icon: <Icon.EnvelopeFill className="" />
        },
        {
            color: '#6c757d',
            value: wedding?.statistics.inviteDelivered.no,
            icon: <Icon.ArchiveFill className="" />
        }
    ];

    const hotelData = [
        {
            color: '#027233',
            value: wedding?.statistics.needs.hotel.yes,
            icon: <Icon.House className="" />
        },
        {
            color: '#cf3507',
            value: wedding?.statistics.needs.hotel.no,
            icon: <Icon.XLg className="" />
        }
    ];

    const transportData = [
        {
            color: '#027233',
            value: wedding?.statistics.needs.transport.yes,
            icon: <Icon.CarFrontFill className="" />
        },
        {
            color: '#cf3507',
            value: wedding?.statistics.needs.transport.no,
            icon: <Icon.XLg className="" />
        }
    ];

    const dietData = Object.entries(wedding?.statistics.preferences.diets).map((v, idx, arr) => ({
        color: colors[0 + idx],
        icon: v[0],
        value: v[1]
    }));

    const ageRangesData = Object.entries(wedding?.statistics.preferences.ageRanges).map((v, idx, arr) => ({
        color: colors[0 + idx],
        icon: v[0],
        value: v[1]
    }));


    //console.log(wedding?.statistics.preferences) //<BreakpointInfo />

    const xs=12;
    const sm=12;
    const md=6;
    const lg=5;
    const xl=4;
    const xxl=3;

    return (

        <Row className="d-flex justify-content-center g-5">
            
            {wedding.statistics.presence.sum > 0 && <>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} className="d-flex justify-content-center ">
                    <StatisticsPieChart data={presenceData} gap={0} title="Obecność"
                        titleClassName={titleClassName} labelClassName={labelClassName} sumNameFn={getOsobText} />
                </Col>

                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} className="d-flex">
                    <StatisticsPieChart data={deliveredData} gap={0} title="Zaproszonych"
                        titleClassName={titleClassName} labelClassName={labelClassName} sumNameFn={getZaproszeniaText} />
                </Col>



                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} className="d-flex">
                    <StatisticsPieChart data={hotelData} gap={0} title="Hotel"
                        titleClassName={titleClassName} labelClassName={labelClassName} sumNameFn={getOsobText} />
                </Col>

                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} className="d-flex">
                    <StatisticsPieChart data={transportData} gap={0} title="Transport"
                        titleClassName={titleClassName} labelClassName={labelClassName} sumNameFn={getOsobText} />
                </Col>



                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl+1} className="d-flex">
                    <StatisticsPieChart data={dietData} gap={0} title="Dieta"
                        titleClassName={titleClassName} labelClassName={labelClassName} sumNameFn={getOsobText} />
                </Col>

                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl+1} className="d-flex">
                    <StatisticsPieChart data={ageRangesData} gap={0} title="Wiek"
                        titleClassName={titleClassName} labelClassName={labelClassName} sumNameFn={getOsobText} />
                </Col>

            </>}
            {wedding.statistics.presence.sum == 0 &&
                <Col xs={12} className="text-center my-5">
                    <div className="RFT-2 RF-5 text-center">
                        Statystyki będą dostępne gdy dodasz pierwszych gości
                    </div>
                </Col>
            }
        </Row>
    );


}

export default WeddingShowStatistics;