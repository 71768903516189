import { useContext, useEffect, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";

import api from "Defines/Api";

import { UserContext } from "Defines/Contexts";

import ContainerWithImage from "Components/Layout/ContainerWithImage";

import exitImg from 'Img/unDraw/Exit.svg'
import "./AuthScreens.css"
import routes from "Defines/Routes";
import ApiButton from "Components/Fetching/ApiButton";
import { useDocumentTitle } from "usehooks-ts";


const LogoutScreen = (props) => {

    useDocumentTitle("Wylogowywanie");

    const { saveUser } = useContext(UserContext);

    return (<>
        <Container fluid className="AuthContainer ">
            <Row className="d-flex justify-content-center">
                <Col className="mt-1" md={6} sm={8}>
                    <Card>
                        <Card.Header className="text-center">
                            <span className="RFT-2 RF-3 ">WYLOGOWYWANIE</span>
                        </Card.Header>
                        <Card.Body>
                            <ContainerWithImage image={exitImg} lg={12}
                                className="align-self-center" imgMaxWidth="300px">

                                <div className="d-grid gap-2">
                                    <ApiButton api={api.auth.postLogout} labelClassName="RFT-2" type="submit"
                                        okRedirect={routes.global.home} size="lg" showError defaultClick
                                        onSuccess={(dat) => saveUser(null)}>
                                        Wylogowywanie
                                    </ApiButton >
                                </div>

                            </ContainerWithImage>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    </>);

};

export default LogoutScreen;