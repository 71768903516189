import useApi from "Hooks/useApi";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Spinner, Popover, Overlay, OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';

const ApiButton = (props) => {

    const navigate = useNavigate();

    const [okTimeout, setOkTimeout] = useState(props.okTimeout);

    const variant = (props.variant) ? props.variant : "primary";

    //api, sendData, urlAddon, onSuccess, onError, errorTime, okTime, okRedirect, okRedirectTimeout , size, className, 
    //disabled, hideTextOnPending, showError, defaultClick, onClick

    const apiCall = useApi(props.api, props.urlAddon);

    const [isError, setIsError] = useState(false);
    const [isOk, setIsOk] = useState(false);

    useEffect(() => {
        if (!apiCall.isPending && (apiCall.errors || apiCall.data)) {
            let timeout = 0;
            if (apiCall.errors) {
                setIsOk(false);
                setIsError(true);
                if (props.onError) props.onError();
                timeout = (props.errorTime) ? props.errorTime : 3000;
            } else {
                setIsOk(true);
                setIsError(false);
                //if (props.onSuccess) props.onSuccess();
                timeout = (props.okTime) ? props.okTime : 2000;
                if (props.okRedirect) {
                    setTimeout(() => {
                        navigate(props.okRedirect);
                    }, (props.okRedirectTimeout) ? props.okRedirectTimeout : 0);
                }
            }
            setTimeout(() => {
                setIsError(false);
                setIsOk(false);
            }, timeout);
        }
    }, [apiCall.isPending]);

    useEffect(() => {
        if (props.defaultClick){
            onClick();
        }
        
    }, [])

    const onClick = () => {
        if (props.onClick) props.onClick();
        apiCall.send(props.sendData);
    }

    useEffect(() => {
        if (apiCall.isPending == false && apiCall.wasSended && props.onSuccess && !apiCall.isError)
            props.onSuccess(apiCall.data);

    }, [apiCall.isPending])

    return (<>
        {!apiCall.isPending && !isError && !isOk &&
            <Button variant={variant} size={props.size} className={props.className} type={props.type}
                onClick={onClick} disabled={props.disabled}>
                <span className={props.labelClassName}>
                    {props.children}
                </span>
            </Button>}

        {apiCall.isPending &&
            <Button variant={variant} size={props.size} className={props.className} disabled={props.disabled}>
                <div className="d-flex align-items-center justify-content-center">
                    <span className={props.labelClassName}>
                        {!props.hideTextOnPending && <>{props.children} &nbsp;</>}
                        <Spinner animation="border" size="sm" />
                    </span>
                    
                </div>
            </Button>
        }
        {!apiCall.isPending && isError &&
            <Button variant="danger" size={props.size} className={props.className}
                onClick={() => { setIsOk(false); setIsError(false); }} disabled={props.disabled}>
                <div className="d-flex align-items-center justify-content-center">
                    <span className={props.labelClassName}>
                        {!props.hideTextOnPending && <>
                            {!props.showError && props.children}
                            {props.showError && apiCall.errors[0] && apiCall.errors[0]}
                            &nbsp;
                        </>}
                        <Icon.ExclamationTriangleFill />
                    </span>
                </div>

            </Button>
        }
        {!apiCall.isPending && isOk &&
            <Button variant="success" size={props.size} className={props.className}
                onClick={() => { setIsOk(false); setIsError(false); }} disabled={props.disabled}>
                <span className={props.labelClassName}>
                    {!props.hideTextOnPending && <>{props.children} &nbsp;&nbsp;</>}
                    <Icon.CheckLg />
                </span>
            </Button>
        }
    </>);


};
export default ApiButton;