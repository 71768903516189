import ApiButton from 'Components/Fetching/ApiButton';
import ApiToast from 'Components/Fetching/apiToast';
import FastInput from 'Components/Form/FastInput';
import FastSelect from 'Components/Form/FastSelect';
import RadioButtons from 'Components/Form/RadioButtons';
import InfoTooltip from 'Components/Others/InfoTooltip';
import api from 'Defines/Api';
import useApi from 'Hooks/useApi';
import useDebounce from 'Hooks/useDebounce';
import useEffectNoFirstRender from "Hooks/useEffectNoFirstRender";
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';


const EditPersonField = (props) => {

    const wedding = props.wedding;
    const person = props.person;

    const debouncedPerson = useDebounce(person, 1000)
    const patchPersonApi = useApi(api.persons.patch, person.id);

    useEffectNoFirstRender(() => {
        patchPersonApi.send(debouncedPerson)
    }, [debouncedPerson])

    const setPerson = (person) => {
        props.onPersonEdit(person)
    }

    const radios = [
        { text: <><Icon.CheckLg /></>, value: 1, variant: 'outline-success', tip: "Będę!" },
        { text: <><Icon.QuestionLg /></>, value: 2, variant: 'outline-warning', tip: "Nie wiem" },
        { text: <><Icon.XLg /></>, value: 3, variant: 'outline-danger', tip: "Nie będzie mnie" },
    ];

    return (<>

        <ApiToast useApi={patchPersonApi} className="RFT-2 RF-5" />


        <Row className="d-flex g-2 my-2">
            <Col xl={5} lg={7} md={6} xs={12}>
                <InputGroup>
                    <FastInput label="Imię i nazwisko" labelClassName="RFT-2 " inputClassName="RFT-3 RF-5"
                        value={person.name} onChange={v => setPerson({ ...person, name: v })} />

                    <ApiButton variant="danger" api={api.persons.delete} urlAddon={person.id} onSuccess={() => props.onPersonEdit(person, true)}
                        hideTextOnPending labelClassName="RF-4 text-white d-flex align-items-center" >
                        <Icon.PersonXFill />
                    </ApiButton>

                </InputGroup>
            </Col>

            <Col xl={2} lg={5} md={6} sm={6} xs={6}>
                <RadioButtons id={`PersonFormPresence-${person.id}`} radios={radios}
                    className="d-flex mb-1 h-100"
                    value={person.presence} onChange={v => setPerson({ ...person, presence: v })}
                    default={2} enabled
                    tipPlacement='bottom' tipClass="RFT-2 RF-7"
                    buttonClass="shadow-none d-flex align-items-center justify-content-center RF-3" />
            </Col>

            <Col xl={2} lg={4} md={4} sm={6} xs={6}>
                <FastSelect label="Wiek" labelClassName="RFT-2" selectClassName="RFT-3"
                    options={["---", ...wedding.ageRanges]} enabled default="---"
                    value={person.ageRange} onChange={v => setPerson({ ...person, ageRange: v })} />
            </Col>

            <Col xl={2} lg={4} md={4} sm={6} xs={6}>
                <FastSelect label="Dieta" labelClassName="RFT-2" selectClassName="RFT-3"
                    options={["---", ...wedding.diets]} enabled default="---"
                    value={person.diet} onChange={v => setPerson({ ...person, diet: v })} />
            </Col>

            <Col xl={1} lg={4} md={4} sm={6} xs={6}>
                <FastInput label="Stolik" type="number" banChars={['e', '-']} labelClassName="RFT-2" inputClassName="RFT-3"
                    value={person.tableNumber} onChange={v => setPerson({ ...person, tableNumber: (v == "") ? null : v })} />
            </Col>

        </Row>


    </>);
}

export default EditPersonField;