
import './Screen404.css'
import 'CSS/fonts.css'
import { Row, Col } from 'react-bootstrap';

import * as Icon from 'react-bootstrap-icons';
import { useDocumentTitle } from 'usehooks-ts';

const Screen404 = (props) => {

    useDocumentTitle("404");

    return (<>
        <Row className="d-flex justify-content-center">
            <Col lg={6} md={12} className='d-flex justify-content-center align-items-center'>
                <div className='text-center'>
                    <p className='RFT-2 RF-1'><span className='text-muted'>ERROR&nbsp;</span>404</p>
                    <p className='RFT-2 RF-4 text-muted'>Nadleciała deszczowa chmura...</p>
                </div>
            </Col>
            <Col lg={6} md={12} className='Icon404CloudCol d-flex justify-content-center align-items-center'>
                <Icon.CloudDrizzleFill className="Icon404Cloud" />
            </Col>
        </Row>
    </>);


};

export default Screen404;