import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import  routes  from "Defines/Routes";

const ProtectedRoute = (props) => {

    const redirectPath = (props.redirect) ? props.redirect : routes.auth.login;

    const navigate = useNavigate();

    useEffect(() => {
        let t;
        if (!props.user) {
            t = setTimeout(() => {
                navigate(redirectPath);
            }, 200);
        }
        return (() => clearTimeout(t));
    });
    return <Outlet />;
}

export default ProtectedRoute;