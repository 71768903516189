function stringToNiceDate(dateString, formOfMoth = 0) {
    const monthNames = [["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec",
        "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"
    ], ["Stycznia", "Lutego", "Marca", "Kwietnia", "Maja", "Czerwca",
        "Lipca", "Sierpnia", "Września", "Października", "Listopada", "Grudnia"
    ]];

    if (dateString) {
        const date = new Date(dateString);
        return date.getDate() + " " + monthNames[formOfMoth][date.getMonth()] + " " + date.getFullYear();
    }
    return null;
}

function stringToNiceTimeAndDate(timeString, formOfMoth = 0) {
    if (timeString) {
        const time = new Date(timeString);
        return stringToNiceDate(timeString, formOfMoth) + ", " + time.getHours() + ":" + time.getMinutes();
    }
    return null;
}


function deleteSeconds(time) {
    if (time) {
        var splittedString = time.split(":");
        return splittedString[0] + ":" + splittedString[1];
    }
    return null;
}

export { stringToNiceDate, deleteSeconds, stringToNiceTimeAndDate };