
import ContainerWithImage from 'Components/Layout/ContainerWithImage';
import { UserContext } from 'Defines/Contexts';
import routes from 'Defines/Routes';
import wedImg from 'Img/unDraw/Wedding.svg'
import { useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle } from 'usehooks-ts';

const HomeScreen = (props) => {

    useDocumentTitle("QRInvite");

    const navigate = useNavigate();

    const { user, saveUser } = useContext(UserContext);

    return (<>
        <Row>
            <ContainerWithImage xl={9} lg={8} sm={12} image={wedImg} reverse className="d-flex flex-column d-flex justify-content-between">
                <Row>
                    <h1 className="RFT-3 text-start">Cyfrowe odpowiedzi na zaproszenia weselne!</h1>
                    <p className="RF-5 RFT-3 text-start">Twórz unikalne kody QR do każdego zaproszenia.</p>

                </Row>

                <Row>
                    <Col className="d-flex gap-2">
                        <Button variant="primary" className="RFT-2" onClick={() => navigate(routes.global.howitsworks)}>
                            Jak to działa?
                        </Button>

                        {!user &&
                            <Button variant="secondary" className="RFT-2" onClick={() => navigate(routes.auth.login)}>
                                Zaloguj się
                            </Button>
                        }

                        {user &&
                            <Button variant="secondary" className="RFT-2" onClick={() => navigate(routes.weddings.list)}>
                                Moje wesela
                            </Button>
                        }

                    </Col>
                </Row>


            </ContainerWithImage>
        </Row>
    </>);


};

//value={value} onChange={handleChange}

export default HomeScreen;