import { Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { Button, Container } from "react-bootstrap";

import routes from "Defines/Routes";
import { UserContext, AppSettingsContext } from "Defines/Contexts";

import PublicInvitationScreen from "Screens/PublicInvitation/PublicInvitationScreen";
import HomeScreen from "Screens/HomeScreen";

import AppNavbar from "Components/Global/AppNavbar";
import AppFooter from "Components/Global/AppFooter";
import Screen404 from "Screens/404/Screen404";
import LoginScreen from "Screens/Auth/LoginScreen";
import RegisterScreen from "Screens/Auth/RegisterScreen";
import LogoutScreen from "Screens/Auth/LogoutScreen";
import MyProfileScreen from "Screens/Profile/MyProfileScreen";
import WantResetPasswordScreen from "Screens/Auth/WantResetPasswordScreen";
import SendVerifyEmailScreen from "Screens/Auth/SendVerifyEmailScreen";
import ConfirmEmailScreen from "Screens/Auth/ConfirmEmailScreen";
import ResetPasswordScreen from "Screens/Auth/ResetPasswordScreen";
import WeddingListScreen from "Screens/Weddings/WeddingListScreen";
import ProtectedRoute from "Utils/ProtectedRoute";
import LoadingSpinner from "Components/Others/LoadingSpinner";
import EditWeddingScreen from "Screens/Weddings/EditWeddingScreen";
import WeddingShowScreen from "Screens/Weddings/WeddingShowScreen";
import EditGuestsScreen from "Screens/Weddings/Guests/EditGuestsScreen";
import WeddingMessagesScreen from "Screens/Weddings/WeddingMessagesScreen";
import RulesScreen from "Screens/RulesScreen";
import CookiesModal from "Components/Modals/CookiesModal";
import HowItsWorksScreen from "Screens/HowItsWorks";
import ContactScreen from "Screens/ContactScreen";
import useNotificationPermission from "Hooks/useNotificationPermission";
import { useEffect } from "react";
import { arrayBufferToBase64, getApplicationServerKeyUint8Array } from "Defines/webPush";
import useApi from "Hooks/useApi";
import api from "Defines/Api";
import SecretScreen from "Screens/SecretScreen";


const AppRouter = (props) => {

    const { user } = useContext(UserContext);
    const { cookiesConsent } = useContext(AppSettingsContext);


    return (<>
        {user === undefined && <LoadingSpinner />}
        {user !== undefined &&
            <Container fluid className="bgContainer">

                <CookiesModal />

                <AppNavbar />

                <Container className="appContainer">
                    <Routes>
                        {/*  ----  PUBLIC ROUTES  ----  */}
                        <Route exact path={routes.global.home} element={<HomeScreen />} />
                        <Route exact path={routes.global.rules} element={<RulesScreen />} />
                        <Route exact path={routes.global.howitsworks} element={<HowItsWorksScreen />} />
                        <Route exact path={routes.global.contact} element={<ContactScreen />} />

                        <Route exact path={routes.secret} element={<SecretScreen />} />

                        {/*  ----  PUBLIC INVITATION ROUTES  ----  */}
                        <Route path={routes.global.publicInvitation} element={<PublicInvitationScreen />} />

                        {/*  ----  AUTH ROUTES  ----  */}
                        <Route path={routes.auth.logout} element={<LogoutScreen />} />
                        <Route path={routes.auth.login} element={<LoginScreen />} />
                        <Route path={routes.auth.register} element={<RegisterScreen />} />
                        <Route path={routes.auth.wantPasswordReset} element={<WantResetPasswordScreen />} />
                        <Route path={routes.auth.passwordReset} element={<ResetPasswordScreen />} />
                        <Route path={routes.auth.sendVerifyEmail} element={<SendVerifyEmailScreen />} />
                        <Route path={routes.auth.confirmEmail} element={<ConfirmEmailScreen />} />

                        {/*  ----  USER ROUTES  ----  */}
                        <Route element={<ProtectedRoute user={user} />}>

                            <Route path={routes.user.profile} element={<MyProfileScreen />} />
                            <Route path={routes.weddings.list} element={<WeddingListScreen />} />

                            <Route path={routes.weddings.show} element={<WeddingShowScreen />} />
                            <Route path={routes.weddings.edit} element={<EditWeddingScreen />} />
                            <Route path={routes.weddings.guests} element={<EditGuestsScreen />} />
                            <Route path={routes.weddings.messages} element={<WeddingMessagesScreen />} />


                        </Route>

                        {/*  ----  404  404  404  ----  */}
                        <Route path="*" element={<Screen404 />} />

                    </Routes>

                </Container>

                <AppFooter />

            </Container>
        }
    </>);

}

export default AppRouter;