import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const InfoTooltip = (props) => {

    const place = (props.placement) ? props.placement : "auto";
    const tooltip = props.tooltip;
    const showTime = (props.showTime) ? props.showTime : 3000;

    const [shown, setShown] = useState(false);

    const onClick = (e) => {
        setShown(true);
        if (!shown) {
            setTimeout(() => {
                setShown(false);
            }, showTime)
        }
    }

    return (<>
        <span onClick={onClick} onMouseEnter={(e) => setShown(true)} onMouseLeave={(e) => setShown(false)}>
            <OverlayTrigger
                placement={place}
                delay={{ show: 300, hide: 300 }}
                show={shown}
                overlay={
                    <Tooltip {...props}>
                        {tooltip}
                    </Tooltip>
                } >

                <span>
                    {props.children}
                </span>

            </OverlayTrigger>
        </span>
    </>);
}

export default InfoTooltip;