import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState, useEffect } from "react";

import FastSelect from "Components/Form/FastSelect";
import { CompactPicker } from "react-color";

import { dotsTypes, cornersTypes, cornersDotTypes, qrCodeExampleUrl } from "Defines/QrCodesDefines";

import api from "Defines/Api";
import QRCode from "Components/Others/QRCode";
import PhotoUploader from "Components/Fetching/PhotoUploader";
import useForceUpdate from "Hooks/useForceRender";


const QRCodeEditorModal = (props) => {

    const qrDefault = {
        dotsOptions: {
            color: "#000000",
            type: "square"
        },
        cornersSquareOptions: {
            color: "#000000",
            type: "square"
        },
        cornersDotOptions: {
            color: "#000000",
            type: "square"
        },
    }

    //show, onCancel, onSubmit, centered, weddingID, qrStyle

    const [format, setFormatPriv] = useState();

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        setFormat({ ...props.qrStyle });
    }, [props.qrStyle]);

    const setFormat = (fo) => {
        setFormatPriv({ ...qrDefault, ...fo });
    }

    const modifyFormat = (key, value) => {
        var valuePath = key.split('.');
        var last = valuePath.pop();

        var output = { ...format }
        var temp = output;

        for (let i = 0; i < valuePath.length; i++) {
            if (!temp[valuePath[i]]) {
                temp[valuePath[i]] = {};
            }
            temp = temp[valuePath[i]];
        }
        temp[last] = value;

        setFormat(output);
    }
    const getFormat = (key) => {
        var valuePath = key.split('.');
        var last = valuePath.pop();
        var temp = { ...format };

        try {
            for (let i = 0; i < valuePath.length; i++) {
                temp = temp[valuePath[i]];
            }
            return temp[last];
        } catch (err) {
            return 0;
        }
    }

    const onReset = () => {
        setFormat(null);
    }

    const onCancel = () => {
        props.onCancel();
        setFormat(null);
    }

    const onSave = () => {
        props.onSubmit(format);
        setFormat(null);
    }


    return (<>

        <Modal show={props.show} onHide={onCancel} centered={props.centered}>

            <Modal.Header closeButton>
                <Modal.Title className="RFT-2">Styl QRCode</Modal.Title>
            </Modal.Header>

            <Modal.Body>


                <Row className="mb-4">
                    <div className="text-danger RFT-2 text-center">Aby kody QR były poprawnie rozpoznawane przez wszystkie urządzenia unikaj wybujałych stylów</div>
                    <div className="w-100 d-flex justify-content-center">
                        <QRCode format={format} size={400} url={qrCodeExampleUrl} className="d-none d-lg-block" image={api.storage.logo.showUrl(props.wedding.token)} />
                        <QRCode format={format} size={250} url={qrCodeExampleUrl} className="d-lg-none" image={api.storage.logo.showUrl(props.wedding.token)} />
                    </div>
                    <div className="text-success RFT-2 text-center">Warto sprawdzić działanie swoim telefonem</div>
                </Row>
                <Row>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><span className="RFT-2">Wzór</span></Accordion.Header>
                            <Accordion.Body>

                                <FastSelect options={dotsTypes.types} names={dotsTypes.names} enabled value={getFormat("dotsOptions.type")}
                                    onChange={(val) => { modifyFormat("dotsOptions.type", val) }} label="Typ kropek"
                                    selectClassName="RFT-2" labelClassName="RFT-2" />

                                <div className="w-100 d-flex justify-content-center mt-2">
                                    <CompactPicker color={getFormat("dotsOptions.color")} onChange={(col) => { if (col) modifyFormat("dotsOptions.color", col.hex); }} />
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><span className="RFT-2">Znaczniki</span></Accordion.Header>
                            <Accordion.Body>

                                <FastSelect options={cornersTypes.types} names={cornersTypes.names} enabled value={getFormat("cornersSquareOptions.type")}
                                    onChange={(val) => { modifyFormat("cornersSquareOptions.type", val) }} label="Typ kropek"
                                    selectClassName="RFT-2" labelClassName="RFT-2" />

                                <div className="w-100 d-flex justify-content-center mt-2">
                                    <CompactPicker color={getFormat("cornersSquareOptions.color")} onChange={(col) => { if (col) modifyFormat("cornersSquareOptions.color", col.hex); }} />
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><span className="RFT-2">Wnętrze znaczników</span></Accordion.Header>
                            <Accordion.Body>

                                <FastSelect options={cornersDotTypes.types} names={cornersDotTypes.names} enabled value={getFormat("cornersDotOptions.type")}
                                    onChange={(val) => { modifyFormat("cornersDotOptions.type", val) }} label="Typ kropek"
                                    selectClassName="RFT-2" labelClassName="RFT-2" />

                                <div className="w-100 d-flex justify-content-center mt-2">
                                    <CompactPicker color={getFormat("cornersDotOptions.color")} onChange={(col) => { if (col) modifyFormat("cornersDotOptions.color", col.hex); }} />
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><span className="RFT-2">Logo</span></Accordion.Header>
                            <Accordion.Body className="d-flex justify-content-center text-center ">
                                <Row>
                                    <PhotoUploader photoLink={api.storage.logo.showUrl(props.wedding.token)}
                                        showPhoto photoRounded
                                        formats="image/png"
                                        apiDelete={api.storage.logo.delete} apiDeleteAddon={props.wedding.id}
                                        apiPost={api.storage.logo.post} apiPostAddon={props.wedding.id}
                                        onDelete={() => { forceUpdate(); }}
                                        onUpload={() => { forceUpdate(); }} />
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><span className="RFT-2">Tło</span></Accordion.Header>
                            <Accordion.Body>

                                <Form.Check type="checkbox" id="Transparent" className="RFT-2 RF-5" inline>
                                    <Form.Check.Input type="checkbox" required
                                        checked={getFormat("backgroundOptions.color") == null} onChange={(e) => {
                                            if (!e.target.checked)
                                                modifyFormat("backgroundOptions.color", "#FFFFFF");
                                            else
                                                modifyFormat("backgroundOptions.color", null);
                                        }} />
                                    <Form.Check.Label className="RFT-2 RF-6 me-2">
                                        Przeźroczyste
                                    </Form.Check.Label>
                                </Form.Check>

                                {getFormat("backgroundOptions.color") != null &&
                                    <div className="w-100 d-flex justify-content-center mt-2">
                                        <CompactPicker color={getFormat("backgroundOptions.color")}
                                            onChange={(col) => { if (col) modifyFormat("backgroundOptions.color", col.hex); }} />
                                    </div>
                                }

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </Row>






            </Modal.Body>

            <Modal.Footer>
                <Button variant="warning" className="RFT-2" onClick={onReset}>Resetuj</Button>
                <Button variant="secondary" className="RFT-2" onClick={onCancel}>Anuluj</Button>
                <Button variant="danger" className="RFT-2" onClick={onSave}>Zapisz</Button>
            </Modal.Footer>


        </Modal>

    </>);

};

export default QRCodeEditorModal;