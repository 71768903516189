import { Card } from "react-bootstrap"

const InvitationNote = (props) => {
    return (<>
        <Card className="">
            <Card.Header>
                <div className="RFT-2 RF-5 text-center">Notatka pary młodej</div>
            </Card.Header>
            <Card.Body>
                <div className="RFT-3 RF-5">{props.note}</div>
            </Card.Body>
        </Card>
    </>)
}

export default InvitationNote;