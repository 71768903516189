import { Card, Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

import * as Icon from 'react-bootstrap-icons';


import routes from "Defines/Routes";
import api from "Defines/Api";
import useApi from "Hooks/useApi";

import WeddingInfo from "Screens/PublicInvitation/components/WeddingInfo";
import LoadingSpinner from "Components/Others/LoadingSpinner";

import StatisticsPieChart from "./components/StatisticsPieChart";

import ConfirmDeleteModal from "Components/Modals/ConfirmDeleteModal";
import WeddingShowStatistics from "./components/WeddingShowStatistics";


const WeddingShowScreen = (props) => {

    const { wedId } = useParams();
    const navigate = useNavigate();

    const getApi = useApi(api.weddings.get, wedId, true);
    const deleteApi = useApi(api.weddings.delete);

    const [showDelete, setShowDelete] = useState(false);
    const onDeleteSubmit = () => {
        deleteApi.send(null, getApi.data.id)
            .then(() => navigate(routes.weddings.list));

        setShowDelete(false);
    }


    return (<>
        {getApi.isPending && <LoadingSpinner />}
        {getApi.data && <>
            <WeddingInfo wedding={getApi.data} />

            <ConfirmDeleteModal show={showDelete} centered confirmationText={getApi.data.bride + " " + getApi.data.groom}
                onCancel={() => setShowDelete(false)} onSubmit={onDeleteSubmit} />

            <Row>
                <Col>
                    <Card className="mt-4">
                        <Card.Body>
                            <WeddingShowStatistics wedding={getApi.data}/>
                        </Card.Body>
                        <Card.Footer>
                            <Row className="d-flex justify-content-center g-3">

                                <Col lg={2} sm={6} xs={12} className="d-grid">
                                    <Button variant="primary" className="RFT-2 shadow-none"
                                        onClick={() => navigate(routes.weddings.editGen(getApi.data.id))}>
                                        EDYTUJ
                                    </Button>
                                </Col>

                                <Col lg={2} sm={6} xs={12} className="d-grid">
                                    <Button variant="secondary" className="RFT-2 shadow-none"
                                        onClick={() => navigate(routes.weddings.messagesGen(getApi.data.id))}>
                                        Wiadomości
                                    </Button>
                                </Col>

                                <Col lg={2} sm={6} xs={12} className="d-grid">
                                    <Button variant="secondary" className="RFT-2 shadow-none"
                                        onClick={() => navigate(routes.weddings.guestsGen(getApi.data.id))}>
                                        GOŚCIE
                                    </Button>
                                </Col>

                                <Col lg={2} sm={6} xs={12} className="d-grid">
                                    <Button variant="danger" className="RFT-2 shadow-none" onClick={() => setShowDelete(true)}>
                                        USUŃ
                                    </Button>
                                </Col>

                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </>}
    </>);
}

export default WeddingShowScreen;