const dotsTypes = {
    types: ['square', 'rounded', 'extra-rounded', 'dots', 'classy', 'classy-rounded'],
    names: ['Kwadratowe', 'Zaokrąglone', 'Bardzo zaokrąglone', 'Kropki', 'Skośne', 'Skośne i zaokrąglone']
};

const cornersTypes = {
    types: ['square', 'extra-rounded', 'dot'],
    names: ['Kwadratowe', 'Zaokrąglone', 'Okrągłe']
};

const cornersDotTypes = {
    types: ['square', 'dot'],
    names: ['Kwadratowe', 'Kropki']
};

const qrCodeExampleUrl = "https://qri.skoczur.pl/inv/000000000000000000000000000000000000000000000000000000000000";

export { dotsTypes, cornersTypes, cornersDotTypes, qrCodeExampleUrl };