import { toBeDisabled } from "@testing-library/jest-dom/dist/matchers";
import ApiButton from "Components/Fetching/ApiButton";
import LoadingSpinner from "Components/Others/LoadingSpinner";
import api from "Defines/Api";
import { stringToNiceTimeAndDate } from "Functions/DataTime";
import useApi from "Hooks/useApi";
import useForceUpdate from "Hooks/useForceRender";
import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import { useDocumentTitle } from "usehooks-ts";

const WeddingMessagesScreen = (props) => {

    useDocumentTitle("Wiadomości od gości");

    const { wedId } = useParams();

    const getApi = useApi(api.invitations.get(wedId, true, false, true), "", true);

    const ref = useForceUpdate();

    const toDa = (dat) => {
        return (stringToNiceTimeAndDate(dat, 1));
    }

    return (<>
        {getApi.isPending && <LoadingSpinner />}

        {!getApi.data && !getApi.isPending && <>
            <div className="RFT-2 RF-4 text-center mt-5">
                Nie masz jeszcze żadnych wiadomości od gości 
            </div>
            <div className="RF-1 text-center mt-1">
                <Icon.EnvelopeDashFill/>
            </div>
        </>}

        {getApi.data && <>
            {
                getApi.data.map((inv, invIdx) => {
                    if (inv.messages.length) {
                        return (
                            <Card className="mb-3" key={inv.id}>
                                <Card.Header className="text-center RFT-2 RF-4">
                                    {inv.name}
                                </Card.Header>

                                {inv.messages.map((mess, messIdx) => (
                                    <Card className="m-2" key={mess.id}>
                                        <Card.Body className="RFT-3 RF-5">
                                            {mess.message}
                                        </Card.Body>
                                        <Card.Footer>
                                            <Row className="d-flex justify-content-between g-2">

                                                <Col lg={3} sm={7} className="text-start align-self-center RFT-2">
                                                    {toDa(mess.created_at)}
                                                </Col>

                                                <Col lg={2} sm={5} className="d-grid gap-2">
                                                    <ApiButton api={api.messages.delete} urlAddon={mess.id} className="RFT-2 RF-6" variant="danger"
                                                        size="sm"
                                                        onSuccess={() => {
                                                            getApi.data[invIdx].messages.splice(messIdx, 1);
                                                            setTimeout(() => ref(), 1000);
                                                        }}>
                                                        Usuń
                                                    </ApiButton>
                                                </Col>

                                            </Row>
                                        </Card.Footer>
                                    </Card>
                                ))}

                            </Card>
                        )
                    }
                })
            }

        </>}

    </>);
}

export default WeddingMessagesScreen;

/*

            getApi.data.map((inv, invIdx) => {
                if (inv.messages.length) {
                    return (
                        <Card className="mb-3" key={inv.id}>
                            <Card.Header className="text-center RFT-2 RF-4">
                                {inv.name}
                            </Card.Header>

                            {inv.messages.map((mess, messIdx) => (
                                <Card className="m-2" key={mess.id}>
                                    <Card.Body className="RFT-3 RF-5">
                                        {mess.message}
                                    </Card.Body>
                                    <Card.Footer>
                                        <Row className="d-flex justify-content-between g-2">

                                            <Col lg={3} sm={7} className="text-start align-self-center RFT-2">
                                                {toDa(mess.created_at)}
                                            </Col>

                                            <Col lg={2} sm={5} className="d-grid gap-2">
                                                <ApiButton api={api.messages.delete} urlAddon={mess.id} className="RFT-2 RF-6" variant="danger"
                                                    size="sm"
                                                    onSuccess={() => {
                                                        getApi.data[invIdx].messages.splice(messIdx, 1);
                                                        setTimeout(() => ref(), 1000);
                                                    }}>
                                                    Usuń
                                                </ApiButton>
                                            </Col>

                                        </Row>
                                    </Card.Footer>
                                </Card>
                            ))}

                        </Card>
                    )
                }
            })

*/