import { Row, Button, Card, Modal, Form } from "react-bootstrap";
import { useState } from "react";

import api from "Defines/Api";
import useApi from "Hooks/useApi";

import 'CSS/fonts.css'


import PostToast from "Components/Fetching/PostToast";

const EmailReminderSetter = (props) => {

    const hideEmail = function (email) {
        if (!email)
            return "";
        return email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")
    };

    const invData = props.invitation;

    const apiInvitation = useApi(api.public.patchInvitation);

    const [showEditor, setShowEditor] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    const [email, setEmail] = useState(invData.notifyEmailAnonymous);//hideEmail(

    const [isToValidate, setIsToValidate] = useState(false);

    const abortSubmit = () => {
        setShowEditor(false);
        setIsToValidate(false);
    }

    const deleteEmail = () => {
        setShowEditor(false);
        setIsToValidate(false);

        setEmail("");
        apiInvitation.send({
            token: invData.token,
            notifyEmail: ""
        });
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            setShowEditor(false);
            setIsToValidate(false);

            setEmail(hideEmail(newEmail));

            apiInvitation.send({
                token: invData.token,
                notifyEmail: newEmail
            });
        }
    };


    const onEmailChange = (val) => {
        setNewEmail(val);
        setIsToValidate(true)
    }

    const modal = (
        <Modal show={showEditor} onHide={abortSubmit} centered>
            <Form noValidate validated={isToValidate} onSubmit={handleSubmit}>

                <Modal.Header closeButton>
                    <Modal.Title className="RFT-2">Zmiana adresu email</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group>
                        <Form.Control type="email" className="RFT-3" placeholder="Uzupełnij email" onChange={(e) => onEmailChange(e.target.value)} required />
                        <Form.Control.Feedback type="invalid" className="RFT-3">
                            Podaj prawidłowy adres email
                        </Form.Control.Feedback>
                        {/*<Form.Text className="text-muted" >
                            Otrzymasz przypomnienie 3 dni przed terminem
                        </Form.Text>*/ }
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="shadow-none RFT-2" variant="primary" type="submit">Zapisz</Button>
                    <Button className="shadow-none RFT-2" variant="secondary" onClick={abortSubmit}>Anuluj</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );

    return (<>

        <PostToast isPending={apiInvitation.isPending} errors={apiInvitation.errors} className="RFT-2 RF-5" />

        <Card className="mb-2 d-flex">
            <Card.Header>
                <div className="RFT-2 RF-5 text-center"> PRZYPOMINAJKA </div>
            </Card.Header>

            <Card.Body className="">
                <div className="RFT-3 RF-5 text-center">Otrzymaj meilowo przypomnienie o potwierdzeniu zaproszenia w terminie</div>
            </Card.Body>

            {email &&
                <Card.Footer>
                    <div className="RFT-3 RF-5 text-center">{email}</div>
                </Card.Footer>
            }

            <Card.Footer>
                {!email &&
                    <Button variant="secondary" className="shadow-none RFT-2 RF-5 w-100" onClick={() => { setShowEditor(true) }} disabled={!props.enabled}>
                        Dodaj email
                    </Button>
                }
                {email &&
                    <Button variant="danger" className="shadow-none RFT-2 RF-5 w-100" onClick={deleteEmail} disabled={!props.enabled}>
                        Usuń email
                    </Button>
                }
            </Card.Footer>
        </Card>

        {modal}

    </>);
};

export default EmailReminderSetter;