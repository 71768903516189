import { useEffect, useState } from "react";
import { AppSettingsContext } from "Defines/Contexts";
import useEffectNoFirstRender from "Hooks/useEffectNoFirstRender";


const AppSettingsProvider = (props) => {
    const [cookiesConsent, setCookiesConsent] = useState(null);

    const [loaded, setLoaded] = useState(false);

    const configCookiesConsent = (state) => {

        setCookiesConsent(state);
    }

    useEffect(() => {

        if (loaded) {//SAVING
            const settings = {
                cookiesConsent: cookiesConsent,
            }
            localStorage.setItem("appSettings", JSON.stringify(settings));



        } else {//LOADING
            try {
                const settings = JSON.parse(localStorage.appSettings);

                setCookiesConsent(settings.cookiesConsent);
                setLoaded(true);
                return;
            } catch { }

            setCookiesConsent(undefined);
            setLoaded(true);
        }
    });


    return (
        <AppSettingsContext.Provider
            value={{
                cookiesConsent, configCookiesConsent
            }}>

            {props.children}
        </AppSettingsContext.Provider>
    );

};


export default AppSettingsProvider;