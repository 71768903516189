import { useEffect, useState } from "react";
import api from "Defines/Api";
import { UserContext } from "Defines/Contexts";


const UserProvider = (props) => {
    const [user, setUser] = useState(undefined);
    const saveUser = (newUser) => {
        const mergedUser = (newUser) ? { ...user, ...newUser } : null;
        localStorage.setItem("user", JSON.stringify(mergedUser));
        setUser(mergedUser);
    }

    useEffect(() => {

        if (localStorage.user) {
            try {
                const us = JSON.parse(localStorage.user);
                if ('id' in us && 'email' in us && 'name' in us && 'apiToken' in us) {
                    validateUser(us);
                    return;
                }
            } catch { }
        }
        setUser(null);
    }, []);

    const validateUser = (us) => {

        fetch(api.user.get.url, { headers: { Authorization: "Bearer " + us.apiToken } })
            .then(res => {
                if (res.ok)
                    setUser(us);
                else
                    setUser(null);

            })
            .catch(err => {
                setUser(null);
            });
    }


    return (
        <UserContext.Provider value={{ user, saveUser }}>
            {props.children}
        </UserContext.Provider>
    );

};


export default UserProvider;