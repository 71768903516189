import { useDocumentTitle } from "usehooks-ts";

const { regulations, rodo, privacy } = require("Defines/Rules");
const { Tabs, Tab } = require("react-bootstrap");

const RulesScreen = (props) => {
    useDocumentTitle("Regulacje serwisu");

    return (<>
        <Tabs defaultActiveKey="Regulations" className="RFT-2">
            <Tab eventKey="Regulations" title="Regulamin" className="RFT-3">
                <br />
                {regulations}
            </Tab>
            <Tab eventKey="Rodo" title="RODO" className="RFT-3">
                <br />
                {rodo}
            </Tab>
            <Tab eventKey="Privacy" title="Polityka prywatności" className="RFT-3">
                <br />
                {privacy}
            </Tab>
        </Tabs>

    </>);
}


export default RulesScreen;