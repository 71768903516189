import { useState, useEffect } from "react";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";


const ChangePasswordModal = (props) => {

    //show, onCancel, onSubmit

    const [isToValidate, setIsToValidate] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const [confPattern, setConfPattern] = useState("");

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            setIsToValidate(false);
            props.onSubmit(oldPassword, password, passwordConfirmation);
            setOldPassword("");
            setPassword("");
            setPasswordConfirmation("");
        }
    };

    const onChangeOldPassword = (e) => {
        setIsToValidate(true);
        setOldPassword(e.target.value);
    }
    const onChangePassword = (e) => {
        setIsToValidate(true);
        setPassword(e.target.value);
    }
    const onChangePasswordConfirmation = (e) => {
        setIsToValidate(true);
        setPasswordConfirmation(e.target.value);
    }

    useEffect(() => {
        if (password == passwordConfirmation) {
            setConfPattern(".{8,}");
        } else {
            setConfPattern("");
        }
    }, [password, passwordConfirmation]);

    return (<>
        <Modal show={props.show} onHide={props.onCancel} centered={props.centered}>

            <Modal.Header closeButton>
                <Modal.Title className="RFT-2">Zmień hasło</Modal.Title>
            </Modal.Header>

            <Form noValidate validated={isToValidate} onSubmit={handleSubmit} className="w-100">
                <Modal.Body>


                    <FloatingLabel controlId="floatingPassword" label="Bieżące Hasło" className="mb-3 RFT-2" size="lg" >
                        <Form.Control type="password" placeholder=" " className="RFT-3 shadow-none"
                            size="lg" required value={oldPassword}
                            onChange={(e) => onChangeOldPassword(e)} />
                        <Form.Control.Feedback type="invalid">
                            Wpisz stare hasło
                        </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingPassReg" label="Nowe Hasło" className="mb-3 RFT-2" size="lg" >
                        <Form.Control type="password" placeholder=" " className="RFT-3 shadow-none" size="lg"
                            value={password} onChange={(e) => { onChangePassword(e); }}
                            required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[0-9a-zA-Z!@#$%&^*()]{8,}$" />
                        <Form.Control.Feedback type="invalid">
                            Musi Zawierać:<br />
                            -Co najmniej 8 znaków<br />
                            -Co najmniej jedną dużą literę<br />
                            -Co najmniej jedną liczbę
                        </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingPassConfirmReg" label="Potwierdź nowe hasło" className="mb-3 RFT-2" size="lg" >
                        <Form.Control type="password" placeholder=" " className="RFT-3 shadow-none" size="lg"
                            value={passwordConfirmation} onChange={(e) => { onChangePasswordConfirmation(e) }}
                            required pattern={confPattern} />
                        <Form.Control.Feedback type="invalid">
                            Hasła nie są identyczne
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" className="RFT-2" onClick={props.onCancel}>Anuluj</Button>
                    <Button variant="primary" type="submit" className="RFT-2">Zapisz</Button>
                </Modal.Footer>

            </Form>
        </Modal>

    </>);

};

export default ChangePasswordModal;