import api from "Defines/Api";
import useApi from "Hooks/useApi";

import { useEffect, useState, useRef } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";

import ApiButton from "./ApiButton";

const PhotoUploader = (props) => {

    /*
    props:
    photoLink
    showPhoto
    photoRounded
    formats
    apiDelete
    apiDeleteAddon
    apiPost
    apiPostAddon

    onDelete
    onUpload

    useCard
    */

    const [fileExists, setFileExists] = useState(null);
    const [file, setFile] = useState(null);

    const fileRef = useRef();

    useEffect(() => {
        refFileExistance();
    }, []);

    const refFileExistance = () => {
        fetch(props.photoLink)
            .then(res => {
                if (res.ok)
                    setFileExists(true);
                else
                    setFileExists(false);
            }).catch(err => {
                setFileExists(false);
            });
    }

    const onFileChange = (event) => {
        if (event.target.files.length > 0) {
            setFile(event.target.files[0]);
        } else {
            setFile(null);
        }
    };

    const getPostSendData = () => {
        var da = new FormData();
        da.append('image', file);
        return da;
    }

    const onUpload = () => {
        api.refImages();
        
        fileRef.current.value = null;
        setFile(null);

        if (props.onUpload)
            props.onUpload();

        refFileExistance();
    }

    const onDelete = () => {
        api.refImages();

        if (props.onDelete)
            props.onDelete();
        
        refFileExistance();
    }

    const renderBody = () => (<>
        {props.showPhoto && fileExists && <Image src={props.photoLink} fluid rounded={props.photoRounded} />}

        <Row className={(props.showPhoto ? "mt-2 mx-0" : "")}>
            <Form.Control ref={fileRef} type="file" className="RFT-3" accept={props.formats} onChange={(e) => onFileChange(e)} />
        </Row>
    </>);

    const renderFooter = () => (<>

        <Row className="d-flex justify-content-center g-3">
            <Col lg={5} sm={6} xs={12} className="d-grid">
                <ApiButton variant="danger" className="RFT-2" onSuccess={onDelete}
                    api={props.apiDelete} urlAddon={props.apiDeleteAddon} disabled={!fileExists}>
                    Usuń obraz
                </ApiButton>
            </Col>

            <Col lg={5} sm={6} xs={12} className="d-grid">
                <ApiButton variant="success" className="RFT-2" onSuccess={onUpload}
                    api={props.apiPost} urlAddon={props.apiPostAddon} disabled={!file}
                    sendData={getPostSendData()}>
                    Prześlij obraz
                </ApiButton>
            </Col>
        </Row>

    </>);


    return (<>
        {props.useCard &&
            <Card.Body>
                {renderBody()}
            </Card.Body>
        }
        {!props.useCard && renderBody()}

        {props.useCard &&
            <Card.Footer>
                {renderFooter()}
            </Card.Footer>
        }
        {!props.useCard && <div className="mt-2 g-2">{renderFooter()}</div>}



    </>);
}
export default PhotoUploader;