import FastCheck from "Components/Form/FastCheck";
import { AppSettingsContext } from "Defines/Contexts";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Modal, Button, Card } from "react-bootstrap";


const CookiesModal = (props) => {

    const { cookiesConsent, configCookiesConsent } = useContext(AppSettingsContext);


    const [consent, setConsent] = useState(true);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (cookiesConsent === undefined) {
            setShow(true);
        }
    }, [cookiesConsent])

    return (<>
        <Modal show={show} onHide={props.onCancel} centered>

            <Modal.Header>
                <Modal.Title className="RFT-2">Zgody</Modal.Title>
            </Modal.Header>


            <Modal.Body>

                <Card>
                    <Card.Body>
                        <FastCheck label="Zgoda na ciasteczka" checked={consent} onChange={(s) => setConsent(s)} />
                        <div className="mx-2">
                            <div>Usprawnienie i ułatwienie dostępu do Serwisu</div>
                            <div>Personalizacja Serwisu dla Użytkowników</div>
                            <div>Umożliwienie Logowania do serwisu</div>
                            <div>Świadczenie usług społecznościowych</div>
                        </div>
                    </Card.Body>
                </Card>



            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" className="RFT-2" onClick={() => { setShow(false); configCookiesConsent(consent) }}>Zapisz</Button>
            </Modal.Footer>


        </Modal>

    </>);

};

export default CookiesModal;