import { Row, Col, Card } from "react-bootstrap";

import * as Icon from 'react-bootstrap-icons';
import { useDocumentTitle } from "usehooks-ts";

const HowItsWorksScreen = (props) => {

    useDocumentTitle("Jak to działa?")

    const xl = 3;

    const stepColProps = {
        className: "d-flex flex-column align-items-center mb-5",
        xl: 3,
        lg: 4,
        sm: 6,
        xs: 12,
    }

    const infoColProps = {
        className: "d-flex flex-column justify-content-center mb-2",
        xl: 4,
        lg: 5,
        md: 5,
        sm: 6,
        xs: 12,
    }

    return (<>
        <Row>
            <Col>
                <div className="h1 RFT-2 mb-3 mt-3 text-center">Jak działa aplikacja internetowa QRInvite?</div>
                <hr />
            </Col>
        </Row>
        <Row className="mt-3 g-3 d-flex justify-content-center">
            <Col {...stepColProps} >
                <div className="h3 RFT-3">KROK 1</div>
                <div className="h1"><Icon.PersonCircle /></div>
                <div className="RFT-3 text-center">
                    Utwórz konto i zaloguj się.
                </div>
            </Col>

            <Col {...stepColProps} >
                <div className="h3 RFT-3">KROK 2</div>
                <div className="h1"><Icon.CalendarEvent /></div>
                <div className="RFT-3 text-center">
                    Stwórz profil swojego wesela.<br /> Dodaj zdjęcie główne.
                </div>
            </Col>

            <Col {...stepColProps} >
                <div className="h3 RFT-3">KROK 3</div>
                <div className="h1"><Icon.CardList /></div>
                <div className="RFT-3 text-center">
                    Uzupełnij lub zaimportuj listę gości.
                </div>
            </Col>

            <Col {...stepColProps} >
                <div className="h3 RFT-3">KROK 4</div>
                <div className="h1"><Icon.QrCode /></div>
                <div className="RFT-3 text-center">Wygeneruj kody QR do każdego zaproszenia.</div>
            </Col>

            <Col {...stepColProps} >
                <div className="h3 RFT-3">KROK 5</div>
                <div className="h1"><Icon.EnvelopePaperHeart /></div>
                <div className="RFT-3 text-center">Dostarcz każdemu gościowi zaproszenie<br />z cyfrowym kodem. </div>
            </Col>

            <Col {...stepColProps} >
                <div className="h3 RFT-3">KROK 6</div>
                <div className="h1"><Icon.Calendar2Check /></div>
                <div className="RFT-3 text-center">Zbieraj odpowiedzi z weselnych ankiet.</div>
            </Col>

            <Col {...stepColProps} >
                <div className="h3 RFT-3">KROK 7</div>
                <div className="h1"><Icon.CalendarHeart /></div>
                <div className="RFT-3 text-center">Przeżyj swoje cudowne wesele!</div>
            </Col>

            <hr />

        </Row>

        <Row className="d-flex justify-content-evenly align-items-start">

            <Col {...infoColProps}>
                <div className="h3 RFT-2 text-start">Dla gości</div>
                <div className="RFT-3">
                    <ul>
                        <li>Przekazanie odpowiedzi parze młodej w dowolnym czasie.</li>
                        <li>Przesyłanie wiadomości do pary młodej.</li>
                        <li>Przypomnienie mailowe o odpowiedzi.</li>
                        <li>Dostęp do linków publikowanych przez parę młodą.</li>
                        <li>Ankieta weselna dla gości:
                            <ul>
                                <li>Potwierdzanie obecości.</li>
                                <li>Informowanie o diecie.</li>
                                <li>Informowanie o wieku gości.</li>
                            </ul>
                        </li>
                        <li>Informacja o terminie i godzinie wesela.</li>
                        <li>Informacja o stoliku do którego zostaliśmy przydzieleni.</li>
                    </ul>
                </div>
            </Col>

            <Col {...infoColProps}>
                <div className="h3 RFT-2 text-start">Dla pary młodej</div>
                <div className="RFT-3">
                    <ul>
                        <li>Zbieranie odpowiedzi od gości bez udziału pary młodej.</li>
                        <li>Łatwe zarządzenie listą gości i ich obecnością.</li>
                        <li>Raport zaproszeń, wszelkie informacje pomocne przy planowaniu.</li>
                        <li>Dodawanie linków, goście mogą mieć prosty dostęp np. do zdjęć po weselu.</li>
                    </ul>
                </div>
            </Col>

        </Row>


    </>)
}


export default HowItsWorksScreen;