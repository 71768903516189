import { useRef, useEffect } from "react";

const useEffectNoFirstRender = (fun, matrix) => {
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        fun();
    }, matrix);

    useEffect(() => {
        return (() => firstRender.current = true);
    }, []);

};

export default useEffectNoFirstRender;