import api from "Defines/Api";


import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import QRCodeStyling from "qr-code-styling";

import JSZip from "jszip";
import FileSaver from 'file-saver';
import { qrCodeGeneratorProps } from "Defines/QrCodesDefines";
import routes from "Defines/Routes";
import { useState } from "react";
import FastSelect from "Components/Form/FastSelect";
import FastCheck from "Components/Form/FastCheck";
import FastInput from "Components/Form/FastInput";

const GenerateQRCodesButton = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [extension, setExtension] = useState("png");
    const [qrSize, setQRSize] = useState(1000);

    const [useCustomName, setUseCustomName] = useState(false);
    const [customName, setCustomName] = useState("[nr]_QRI_[name]");

    const [preparingProgress, setPreparingProgress] = useState(null);

    const generateQrCodes = () => {

        var zip = new JSZip();

        async function generateFiles() {

            const hasLogo = await fetch(api.storage.logo.showUrl(props.wedding.token));
            let idx = 1;

            const length = props.invitations.reduce((acc, cur) => cur.publicQrCode ? ++acc : acc, 0);

            for (const inv of props.invitations) {
                if (inv.publicQrCode) {

                    const qrCode = new QRCodeStyling({
                        ...props.wedding.qrStyle,
                        data: routes.global.publicDomainInvitationGen(inv.token),
                        image: (hasLogo.ok) ? api.storage.logo.showUrl(props.wedding.token) : null,
                        width: qrSize,
                        height: qrSize
                    });

                    const qrFile = await qrCode.getRawData(extension);

                    let fileName = customName;
                    fileName = fileName.replace("[nr]", idx);
                    fileName = fileName.replace("[name]", inv.name);

                    zip.file(fileName + "." + extension, qrFile);
                }

                setPreparingProgress(idx + "/" + length);

                idx++;
            }
        }

        generateFiles()
            .then(() => {
                zip.generateAsync({ type: "blob" })
                    .then((content) => {
                        setPreparingProgress(null);
                        FileSaver.saveAs(content, "QRI_" + props.wedding.bride + "_" + props.wedding.groom + ".zip");
                        setShowModal(false);

                    });

            });
    }

    const QRConfigModal =
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title className="RFT-2">Generuj kody QR</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <FastInput type="number" label="Rozmiar kodów [px]" labelClassName="RFT-2" inputClassName="RFT-3"
                    banChars={['e', 'E', '+', '-']} min={10} max={2000} disabled={preparingProgress}
                    value={qrSize} onChange={v => setQRSize(v)} />

                <FastSelect label="Rozszerzenie plików" labelClassName="RFT-2 mt-2" selectClassName="RFT-2" enabled={!preparingProgress}
                    options={['png', 'jpeg', 'webp', 'svg']}  value={extension} onChange={v => setExtension(v)} />

                <FastCheck label="Niestandardowa nazwa plików" frame className="mt-2"
                    checked={useCustomName} onChange={v => setUseCustomName(v)} disabled={preparingProgress}/>

                {useCustomName && <>
                    <FastInput type="text" label="Nazwa plików" labelClassName="RFT-2 mt-2" inputClassName="RFT-3"
                        value={customName} onChange={v => setCustomName(v)} disabled={preparingProgress}/>
                    <Form.Text>
                        <div className="mt-0 ms-1">
                            <div>[nr] - Numer porządkowy</div>
                            <div>[name] - Nazwa zaproszenia</div>
                        </div>
                    </Form.Text>
                </>}

            </Modal.Body>


            <Modal.Footer>
                <Button variant="secondary" className="RFT-2" onClick={() => setShowModal(false)}>Anuluj</Button>
                <Button variant="primary" className="RFT-2" onClick={generateQrCodes}>
                    {!preparingProgress && <>Generuj</>}
                    {preparingProgress && <>
                        Generowanie&nbsp;{preparingProgress}
                    </>}

                </Button>
            </Modal.Footer>


        </Modal>;



    return (<>

        {QRConfigModal}

        <Button className={props.className} variant={props.variant} onClick={() => setShowModal(true)}>
            {props.children}
        </Button>
    </>);
}

export default GenerateQRCodesButton;