import { Row, Col, Button } from "react-bootstrap";

import api from "Defines/Api";
import useApi from "Hooks/useApi";

import LoadingSpinner from "Components/Others/LoadingSpinner";
import WeddingField from "./components/WeddingField";
import ApiButton from "Components/Fetching/ApiButton";
import { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import routes from "Defines/Routes";
import { useDocumentTitle } from "usehooks-ts";
//import CreateWeddingModal from "Components/Modals/CreateWeddingModal";



const WeddingListScreen = (props) => {

    useDocumentTitle("Lista moich wesel");
    
    const getWeds = useApi(api.weddings.get, "", true);

    const navigate = useNavigate();

    const [weddings, setWeddings] = useState(null);

    useEffect(() => {
        setWeddings(getWeds.data)
    }, [getWeds.data])

    const onRefresh = (d) => {
        //console.log(d)
        //setWeddings(weddings.concat(d))

        navigate(routes.weddings.editGen(d.id))

    };

    const createWeddingData = {
        bride: "xyz",
        groom: "abc",
        date: "2100-10-10"
    };



    return (<>
        {getWeds.isPending && <LoadingSpinner />}

        {weddings && <>

            {/*<CreateWeddingModal show={true} centered/> */}

            <Row>
                {weddings.length === 0 && <>
                    <span className="RFT-2 RF-3 my-3 text-center">
                        Nie masz żadnych wesel 😥
                    </span>
                </>}

                {weddings.map((wedding) => (
                    <WeddingField key={wedding.id} wedding={wedding} />
                ))}
            </Row>
        </>}

        {!getWeds.isPending && <>
            <Row className="m-1 d-flex justify-content-center">
                <Col xs={12} sm={8} lg={6} className="d-flex justify-content-center">
                    <div className="d-grid gap-2 w-100 RF-4">
                        <Button className="RFT-2 RF-4 mx-2" onClick={() => navigate(routes.weddings.createEdit)}>
                            Dodaj wesele
                        </Button>
                    </div>
                </Col>
            </Row>
        </>}



    </>);

}

export default WeddingListScreen;

