import { Container, Row, Col } from "react-bootstrap";
import logo from 'Img/Branding/LogoNapisBezQr.svg'

import './AppFooter.css'
import { useContext } from "react";
import { AppSettingsContext } from "Defines/Contexts";

const AppFooter = () => {

    return (
        <footer>
            <Row className="FotterAll align-items-center mt-5 pb-2 bg-light flex-fill ">
                <Col className="text-center">
                    <div><img alt="Page footer" className="FooterImg mt-2 mb-1" src={logo} /></div>
                    <span className="mb-2">&copy; Copyright 2023 QRInvite</span>
                </Col>
            </Row>
        </footer>
    );

}

export default AppFooter;