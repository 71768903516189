
const { REACT_APP_PUBLIC_URL } = process.env;

const routes = {

    redirectOnUnAuth: "/",

    global: {
        home: "/",
        contact: "/contact", //"contact/",
        rules: "/rules",
        howitsworks:"/howWorks",

        publicInvitation: "/inv/:invTok",
        publicInvitationGen: (token) => (routes.global.publicInvitation.replace(":invTok", token)),
        publicInvitationPrivateGen: (token) => (routes.global.publicInvitation.replace(":invTok", token)+"?private"),

        publicDomainInvitationGen: (token) => (REACT_APP_PUBLIC_URL + routes.global.publicInvitation.replace(":invTok", token)),
    },

    auth: {
        login: "/auth/login/",
        register: "/auth/register/",
        logout: "/auth/logout/",

        wantPasswordReset: "/auth/wantPasswordReset/",
        passwordReset: "/auth/passwordReset/:token",
        passwordResetGen: (token) => (routes.auth.passwordReset.replace(":token", token)),

        sendVerifyEmail: "/auth/sendVerifyEmail/",
        confirmEmail: "/auth/confirmEmail/:token",
        confirmEmailGen: (token) => (routes.auth.confirmEmail.replace(":token", token)),
    },

    user: {
        profile: "/user/profile/",
    },
    weddings: {
        list: "/user/weddings/",

        show: "/user/weddings/:wedId",
        showGen: (id) => (routes.weddings.show.replace(":wedId", id)),

        createEdit: "/user/weddings/create/edit",

        edit: "/user/weddings/:wedId/edit",
        editGen: (id) => (routes.weddings.edit.replace(":wedId", id)),

        messages: "/user/weddings/:wedId/messages",
        messagesGen: (id) => (routes.weddings.messages.replace(":wedId", id)),

        guests: "/user/weddings/:wedId/guests/:invId?",
        guestsGen: (id, invId=null) => {
            let route = routes.weddings.guests.replace(":wedId", id)
            if(invId === null )
                route = route.replace(":invId?", "")
            else
                route = route.replace(":invId?", invId)
            return route;
        },
    },

    secret: "/sk/ultra/secret",

};

export default routes;