import { Button, Card, Col, Image, Row,Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import FastInput from "Components/Form/FastInput";
import LoadingSpinner from "Components/Others/LoadingSpinner";
import ArrayEditor from "Components/Form/ArrayEditor";
import InfoTooltip from "Components/Others/InfoTooltip";

import routes from "Defines/Routes";
import api from "Defines/Api";
import useApi from "Hooks/useApi";

import * as Icon from "react-bootstrap-icons";

import SelectGiftVariantsModal from "Components/Modals/SelectGiftVariantsModal";
import QRCodeEditorModal from "Components/Modals/QRCodeEditorModal";
import LinkEditor from "Components/Form/LinkEditor";
import QRCode from "Components/Others/QRCode";

import ApiButton from "Components/Fetching/ApiButton";
import PhotoUploader from "Components/Fetching/PhotoUploader";
import useForceUpdate from "Hooks/useForceRender";
import { qrCodeExampleUrl } from "Defines/QrCodesDefines";
import { useDocumentTitle } from "usehooks-ts";

const EditWeddingScreen = (props) => {

    useDocumentTitle("Edycja wesela");

    const { wedId } = useParams();

    const isNewScreen = (wedId == "create");

    const getApi = useApi(api.weddings.get, wedId, !isNewScreen);

    const [wedding, setWedding] = useState(null);

    if (isNewScreen && !wedding) {
        setWedding({});
    }

    const [showGiftEditor, setShowGiftEditor] = useState(false);
    const [showQrCodeEditor, setShowQrCodeEditor] = useState(false);

    const navigate = useNavigate();
    const forceUpdate = useForceUpdate();


    useEffect(() => {
        if (getApi.data) {
            setWedding(structuredClone(getApi.data));
        }
    }, [getApi.data]);



    return (<>

        {!wedding && <LoadingSpinner />}

        {wedding && <>
            <SelectGiftVariantsModal centered
                value={wedding.giftVariants}
                onChange={val => setWedding({ ...wedding, giftVariants: val })}
                show={showGiftEditor}
                onCancel={() => setShowGiftEditor(false)}
                onSubmit={() => setShowGiftEditor(false)}
            />

            <QRCodeEditorModal wedding={wedding} show={showQrCodeEditor}
                qrStyle={wedding.qrStyle}
                onCancel={() => setShowQrCodeEditor(false)}
                onSubmit={(format) => {
                    setShowQrCodeEditor(false);
                    setWedding({ ...wedding, qrStyle: format });
                }}
            />
        </>}


        {wedding && <Card>


                <Card.Header className="text-center">
                    <span className="RFT-2 RF-3 ">Edytuj wesele</span>
                </Card.Header>

                <Card.Body>


                    <Card>
                        <Card.Header><span className="RFT-2 RF-5">Dane wesela</span></Card.Header>
                        <Card.Body>

                            <Row className="g-1">
                                <Col md={4}>
                                    <FastInput label="Pani Młoda" size="" labelClassName="RFT-2"
                                        inputClassName="RFT-3 shadow-none"
                                        value={(wedding.bride) ? wedding.bride : ""}
                                        onChange={val => setWedding({ ...wedding, bride: val })} />
                                </Col>
                                <Col md={4}>
                                    <FastInput label="Pan Młody" size="" labelClassName="RFT-2"
                                        inputClassName="RFT-3 shadow-none"
                                        value={(wedding.groom) ? wedding.groom : ""}
                                        onChange={val => setWedding({ ...wedding, groom: val })} />
                                </Col>

                                <Col md={4}>
                                    <FastInput label="Data" type="date" size="" labelClassName="RFT-2"
                                        inputClassName="RFT-3 shadow-none"
                                        value={(wedding.date) ? wedding.date : ""}
                                        onChange={val => setWedding({ ...wedding, date: val })} />
                                </Col>
                            </Row>

                            <Row className="d-flex align-items-center g-1 mt-1">
                                <Col md={4}>
                                    <FastInput label="Godzina ślubu" type="time" size="" labelClassName="RFT-2"
                                        inputClassName="RFT-3 shadow-none"
                                        value={(wedding.ceremonyHour) ? wedding.ceremonyHour : ""}
                                        onChange={val => setWedding({ ...wedding, ceremonyHour: val })} />
                                </Col>
                                <Col md={8} className="d-none d-md-block">
                                    <FastInput label="Miejsce ślubu" size="" labelClassName="RFT-2"
                                        inputClassName="RFT-3 shadow-none"
                                        value={(wedding.ceremonyPlace) ? wedding.ceremonyPlace : ""}
                                        onChange={val => setWedding({ ...wedding, ceremonyPlace: val })} />
                                </Col>
                                <Col md={8} className="d-md-none">
                                    <FastInput label="Miejsce ślubu" as="textarea" size="" labelClassName="RFT-2"
                                        style={{ height: '110px', resize: "none" }} inputClassName="RFT-3 shadow-none"
                                        value={(wedding.ceremonyPlace) ? wedding.ceremonyPlace : ""}
                                        onChange={val => setWedding({ ...wedding, ceremonyPlace: val })} />
                                </Col>
                            </Row>

                            <Row className="d-flex align-items-center g-1 mt-1">
                                <Col md={4}>
                                    <FastInput label="Godzina wesela" type="time" size="" labelClassName="RFT-2"
                                        inputClassName="RFT-3 shadow-none"
                                        value={(wedding.weddingHour) ? wedding.weddingHour : ""}
                                        onChange={val => setWedding({ ...wedding, weddingHour: val })} />
                                </Col>
                                <Col md={8} className="d-none d-md-block">
                                    <FastInput label="Miejsce Wesela" size="" labelClassName="RFT-2"
                                        inputClassName="RFT-3 shadow-none"
                                        value={(wedding.weddingPlace) ? wedding.weddingPlace : ""}
                                        onChange={val => setWedding({ ...wedding, weddingPlace: val })} />
                                </Col>
                                <Col md={8} className="d-md-none">
                                    <FastInput label="Miejsce Wesela" as="textarea" size="" labelClassName="RFT-2"
                                        style={{ height: '110px', resize: "none" }} inputClassName="RFT-3 shadow-none"
                                        value={(wedding.weddingPlace) ? wedding.weddingPlace : ""}
                                        onChange={val => setWedding({ ...wedding, weddingPlace: val })} />
                                </Col>
                            </Row>

                            <Row className="d-flex align-items-stretch g-1 mt-1">
                                <Col lg={7}>
                                    <Card className="h-100">
                                        <Card.Header className="RFT-2 RF-5" >
                                            Zdjęcie w tle&nbsp;
                                            <InfoTooltip placement="top" className="RFT-2" tooltip="Wyświetlane w zaproszeniach">
                                                <Icon.InfoCircleFill />
                                            </InfoTooltip>
                                        </Card.Header>


                                        <PhotoUploader photoLink={api.storage.header.showUrl(wedding.token)}
                                            showPhoto photoRounded useCard
                                            formats="image/jpeg"
                                            apiDelete={api.storage.header.delete} apiDeleteAddon={wedding.id}
                                            apiPost={api.storage.header.post} apiPostAddon={wedding.id}
                                            onDelete={() => { forceUpdate(); }}
                                            onUpload={() => { forceUpdate(); }} />


                                    </Card>
                                </Col>
                                <Col lg={5}>
                                    <Card className="h-100">
                                        <Card.Header>
                                            <span className="RFT-2 RF-5">
                                                QrCode&nbsp;
                                                <InfoTooltip placement="top" className="RFT-2" tooltip="Wygląd qrcode który znajdzie się w twoich zaproszeniach">
                                                    <Icon.InfoCircleFill />
                                                </InfoTooltip>
                                            </span>
                                        </Card.Header>
                                        <Card.Body className="d-flex justify-content-center align-items-center">
                                            <div className="d-none d-md-block">
                                                <QRCode size={300} url={qrCodeExampleUrl} format={wedding.qrStyle}
                                                    image={api.storage.logo.showUrl(wedding.token)} />
                                            </div>
                                            <div className="d-md-none">
                                                <QRCode size={180} url={qrCodeExampleUrl} format={wedding.qrStyle}
                                                    image={api.storage.logo.showUrl(wedding.token)} />
                                            </div>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Row className="d-flex justify-content-center">
                                                <Col xl={5} lg={6} md={6} xs={12} className="d-grid">
                                                    <Button className="RFT-2" variant="primary" onClick={() => setShowQrCodeEditor(true)}>
                                                        Edytuj
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="d-flex align-items-center g-1 mt-1">
                                <Card>
                                    <Card.Header className="RFT-2 RF-5" >
                                        Warianty prezentów
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className="d-flex justify-content-center">
                                            {wedding.giftVariants &&
                                                wedding.giftVariants.map((dat) => (
                                                    <Col key={dat} xs={6} sm={4} xl={3} className="text-center">
                                                        <Image fluid src={api.giftVariants.showUrl(dat)} />
                                                    </Col>
                                                ))}
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Row className="d-flex justify-content-center">
                                            <Col xl={3} lg={4} md={6} xs={12} className="d-grid">
                                                <Button className="RFT-2" onClick={() => setShowGiftEditor(true)}>
                                                    Edytuj
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </Row>

                            <Row className="g-1 mt-1">
                                <LinkEditor header={
                                    <div className="d-flex justify-content-between">
                                        <span className="RFT-2 RF-5">
                                            Linki w zaproszeniu&nbsp;
                                            <InfoTooltip placement="top" className="RFT-2" tooltip="Dostępne dla gości po otwarciu zaproszenia on-line">
                                                <Icon.InfoCircleFill />
                                            </InfoTooltip>
                                        </span>
                                    </div>
                                }
                                    value={wedding.links} onChange={(v) => setWedding({ ...wedding, links: v })}
                                    inputClassName="RFT-3" textClassName="RFT-2" placeholder="Dodaj..." />
                            </Row>

                        </Card.Body>
                    </Card>


                    <Card className="mt-2">
                        <Card.Header>
                            <span className="RFT-2 RF-4">Ankieta weselna</span>
                        </Card.Header>
                        <Card.Body>
                            <Row className="g-1 ">
                                <Col md={12}>
                                    <FastInput label="Termin odpowiedzi gości" type="date" size="" labelClassName="RFT-2"
                                        inputClassName="RFT-2 shadow-none"
                                        value={(wedding.inviteResponseDate) ? wedding.inviteResponseDate : ""}
                                        onChange={val => setWedding({ ...wedding, inviteResponseDate: val })}
                                    />
                                </Col>
                            </Row>

                            <Row className="g-1 mt-1">
                                <ArrayEditor header={
                                    <div className="d-flex justify-content-between">
                                        <span className="RFT-2 RF-5">
                                            Diety&nbsp;
                                            <InfoTooltip placement="top" className="RFT-2" tooltip="Przydatne przy podsumowaniu dla kuchni weselnej">
                                                <Icon.InfoCircleFill />
                                            </InfoTooltip>
                                        </span>
                                    </div>
                                }
                                    value={wedding.diets} onChange={(v) => setWedding({ ...wedding, diets: v })}
                                    inputClassName="RFT-3" placeholder="Dodaj..." />
                            </Row>
                            <Row className="g-1 mt-1">
                                <ArrayEditor header={
                                    <div className="d-flex justify-content-between">
                                        <span className="RFT-2 RF-5">
                                            Przedziały wiekowe&nbsp;
                                            <InfoTooltip placement="top" className="RFT-2" tooltip="Przydatne przy liczeniu pełnoletnich oraz zniżek od wieku gości">
                                                <Icon.InfoCircleFill />
                                            </InfoTooltip>
                                        </span>
                                    </div>
                                }
                                    value={wedding.ageRanges} onChange={(v) => setWedding({ ...wedding, ageRanges: v })}
                                    inputClassName="RFT-3" placeholder="Dodaj..." />
                            </Row>

                        </Card.Body>

                    </Card>

                </Card.Body >
                <Card.Footer>
                    <Row className="d-flex justify-content-center g-1">
                        <Col lg={2} sm={6} xs={12} className="d-grid">
                            <Button variant="warning" className="RFT-2 shadow-none" onClick={() => setWedding(structuredClone(getApi.data))}>
                                Przywróć
                            </Button>
                        </Col>

                        {isNewScreen &&
                            <Col lg={2} sm={6} xs={12} className="d-grid">
                                <ApiButton variant="success" className="RFT-2 shadow-none"
                                    api={api.weddings.post} sendData={wedding} showError
                                    onSuccess={res => navigate(routes.weddings.showGen(res.id))}>
                                    Zapisz
                                </ApiButton>
                            </Col>
                        }

                        {!isNewScreen &&
                            <Col lg={2} sm={6} xs={12} className="d-grid">
                                <ApiButton variant="success" className="RFT-2 shadow-none"
                                    api={api.weddings.patch} urlAddon={wedding.id} sendData={wedding}
                                    okRedirect={routes.weddings.showGen(wedding.id)} showError>
                                    Zapisz
                                </ApiButton>
                            </Col>
                        }

                    </Row>
                </Card.Footer>
        </Card >}
    </>);


};

export default EditWeddingScreen;