
import { useRef, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import InfoTooltip from 'Components/Others/InfoTooltip';
import * as Icon from 'react-bootstrap-icons';
import { isMobile } from 'react-device-detect';


const StatisticsPieChart = (props) => {

    const title = props.title;
    const labelClassName = props.labelClassName;
    const colors = props.colors;

    const ref = useRef(null);

    //Calculate value sum
    const valueSum = props.data.reduce((total, val) => (total + val.value), 0);

    //Calculate pie data full array
    const pieData = props.data.map((dat, idx, arr) => ({
        title: "Test",
        color: dat.color,
        order: idx,
        icon: dat.icon,
        value: dat.value,
        percent: Math.floor((dat.value / valueSum) * 100),
    }));

    //Calculate last element of array data percent to fit 100%
    const percentSum = pieData.slice(0, -1).reduce((total, val) => (total + val.percent), 0);
    pieData[pieData.length - 1].percent = 100 - percentSum;

    const description = <>

        {pieData.map(v => (
            <span key={v.order} style={{ color: v.color }} className={"text-center " + props.labelClassName}>
                {v.icon} - {v.value} {props.sumNameFn && props.sumNameFn(v.value)}
            </span>
        ))}
        {props.sumNameFn &&
            <span className={"text-center border-top border-dark w-100 px-2 mt-2 " + labelClassName}>{valueSum} {props.sumNameFn(valueSum)}</span>
        }


    </>;

    return (<>
        <div className="d-flex flex-column align-items-center justify-content-start w-100">

            <div className="d-flex flex-column justify-content-center align-items-center">
                <span className={"text-center w-100 mb-2 " + props.titleClassName}>{title}</span>

                <div style={{ maxWidth: "250px" }}>
                    <PieChart data={pieData} lineWidth={50} startAngle={270}

                        label={d => ((d.dataEntry.percent > 10) ? d.dataEntry.percent + '%' : "")}

                        labelStyle={{ fontSize: "8px", fontWeight:"bold", fontFamily:"Aboreto, cursive"}}
                        labelPosition={75}

                    />
                </div>
            </div>


            <div className="d-flex flex-column mt-3">
                {description}
            </div>
        </div>

    </>);
}


/*       <PieDonutChart
                    classNameSvgGroupText="bg-primary"
                    data={pieData}
                    parentRef={ref}
                    gap={(props.gap || props) ? props.gap : 2}
                    text={valueSum +"\n"+props.sumNameFn(valueSum)}
                    hoverScaleRatio={(props.hoverScaleRatio) ? props.hoverScaleRatio : 1}
                    donutThickness={(props.donutThickness) ? props.donutThickness : 40}
                    colors={colors}
                />

*/
export default StatisticsPieChart;