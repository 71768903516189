
import { useParams } from "react-router-dom";
import { Row, Col, Card, Container} from "react-bootstrap";

import api from "Defines/Api";

import authImg from "Img/unDraw/Auth.svg";
import ContainerWithImage from "Components/Layout/ContainerWithImage";
import ApiButton from "Components/Fetching/ApiButton";
import routes from "Defines/Routes";
import { useDocumentTitle } from "usehooks-ts";

const ConfirmEmailScreen = (props) => {

    useDocumentTitle("Weryfikacja adresu email");

    const { token } = useParams();

    return (<>
        <Container fluid className="AuthContainer ">
            <Row className="d-flex justify-content-center">
                <Col className="mt-1" xl={4} md={5} xs={11}>
                    <Card>
                        <Card.Header className="text-center">
                            <span className="RFT-2 RF-4 ">
                                Potwierdzanie adresu e&#8209;mail
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <ContainerWithImage image={authImg} lg={12}
                                className="align-self-center" imgMaxWidth="300px">
                                <div className="d-grid gap-2">
                                    <ApiButton api={api.auth.postConfirmEmail} labelClassName="RFT-2" type="submit"
                                        okRedirect={routes.auth.login} okRedirectTimeout={500} size="lg" showError defaultClick sendData={{ token: token }}>
                                        Potwierdzanie
                                    </ApiButton >
                                </div>
                            </ContainerWithImage>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    </>);
};

export default ConfirmEmailScreen;