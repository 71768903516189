import { useState } from "react";
import { Form, FloatingLabel } from "react-bootstrap";

const FastInput = (props) => {

    //label, id, size, labelClassName, inputClassName, size, required, value, onChange, invalid, banChars,
    //autoComplete, minLength

    const [isToValidate, setIsToValidate] = useState(false);

    const blockInvalidChar = (e) => {
        if (props.banChars)
            if (props.banChars.includes(e.key))
                e.preventDefault();
    }

    const onChange = (e) => {
        let v = e.target.value;
        if (v) {
            setIsToValidate(true);
        } else {
            setIsToValidate(false);
        }
        /*
                if (props.min && v < props.min) 
                    v = props.min;
        
                if (props.max && v > props.max) 
                    v = props.max;
        */
        if (props.onChange) {
            props.onChange(v);
        }
    }

    const inputRender = () => (<>
        <Form.Control type={props.type} as={props.as} style={props.style} placeholder=" " className={props.inputClassName}
            size={props.size} required={props.required} value={(props.value) ? props.value : ""} min={props.min} max={props.max}
            onKeyDown={blockInvalidChar} isValid={props.isValid} isInvalid={props.isInvalid} disabled={props.disabled}
            onChange={onChange} autoComplete={props.autoComplete} minLength={props.minLength} />
        <Form.Control.Feedback type="invalid">
            {props.invalid}
        </Form.Control.Feedback>
    </>
    );


    const inputLabelRender = () => (<>
        {props.label &&
            <FloatingLabel controlId={props.id} label={props.label} size={props.size} className={props.labelClassName}>
                {inputRender()}
            </FloatingLabel>
        }
        {!props.label && inputRender()}
    </>);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (<>
        {props.validate && <>
            <Form noValidate validated={isToValidate} onSubmit={handleSubmit}>
                {inputLabelRender()}

            </Form>
        </>}
        {!props.validate && inputLabelRender()}
    </>);


};
export default FastInput;