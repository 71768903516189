import api from "Defines/Api";
import useApi from "Hooks/useApi";
import { Button } from "react-bootstrap";

const SecretScreen = (props) => {


    const noti = useApi(api.user.notification.test);
    const notify = () => {
        noti.send({
            title: "Testowe powiadomienie",
            body: "Body powiadomienia",
            icon: "/img/PWA_144.png",
            data:{
                link: "https://google.pl",
            },
            actions: [
                { action: '/user/weddings/', title: 'WP🥗🧀', },
                { action: '/user/weddings/1', title: 'google' }
            ],
            image: "/img/LogoNapis.png",
            requireInteraction: true
        });
    }


    return (<>

        <div className="d-grid w-100">
            <Button variant="primary" size="lg" className="RFT-2 RF-6" onClick={notify}>
                Test notify
            </Button>
        </div>

    </>)
}

export default SecretScreen;