
import { Row, Col, Form, Card, Container, FloatingLabel } from "react-bootstrap";
import { useEffect, useState } from "react";

import api from "Defines/Api";

import ContainerWithImage from "Components/Layout/ContainerWithImage";

import inboxImg from 'Img/unDraw/Inbox.svg'
import "./AuthScreens.css"
import ApiButton from "Components/Fetching/ApiButton";
import routes from "Defines/Routes";
import { useDocumentTitle } from "usehooks-ts";


const SendVerifyEmailScreen = (props) => {

    useDocumentTitle("Weryfikacja adresu email");

    const [isToValidate, setIsToValidate] = useState(false);
    const [email, setEmail] = useState("");


    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        setIsToValidate(true);

        if (form.checkValidity() === true) {
            setIsToValidate(false);

        }
    };

    const onFormChange = () => {
        setIsToValidate(true);

    };

    return (<>
        <Container fluid className="AuthContainer ">
            <Row className="d-flex justify-content-center">
                <Col className="mt-1" xl={9} md={8}>
                    <Card>
                        <Card.Header className="text-center">
                            <span className="RFT-2 RF-3 ">wyślij E-mail weryfikacyjny</span>
                        </Card.Header>
                        <Card.Body>
                            <ContainerWithImage image={inboxImg} xl={8} lg={12}
                                className="align-self-center" imgMaxWidth="300px">
                                <Form noValidate validated={isToValidate} onSubmit={handleSubmit} className="w-100 ">
                                    <Form.Group>
                                        <FloatingLabel controlId="floatingInput" label="E-mail" className="mb-3 RFT-2" size="lg" >
                                            <Form.Control type="email" placeholder=" " className="RFT-3 shadow-none mb-2"
                                                size="lg" required value={email}
                                                onChange={(e) => { setEmail(e.target.value); onFormChange(); }} />
                                            <Form.Control.Feedback type="invalid">
                                                Podaj prawidłowy adres email
                                            </Form.Control.Feedback>
                                        </FloatingLabel>


                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <ApiButton api={api.auth.postWantVerifyEmail} labelClassName="RFT-2" sendData={{ email: email }}
                                            okRedirect={routes.auth.login} size="lg" showError type="submit">
                                            Wyślij email
                                        </ApiButton >
                                    </div>

                                </Form>
                            </ContainerWithImage>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    </>);

}

export default SendVerifyEmailScreen;






