import { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Card, Container, FloatingLabel, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import api from "Defines/Api";
import useApi from "Hooks/useApi";

import { AppSettingsContext, UserContext } from "Defines/Contexts";

import ContainerWithImage from "Components/Layout/ContainerWithImage";

import logImg from 'Img/unDraw/Login.svg'
import "./AuthScreens.css"
import routes from "Defines/Routes";
import ApiButton from "Components/Fetching/ApiButton";
import { isMobile } from "react-device-detect";
import { useDocumentTitle } from "usehooks-ts";



const LoginScreen = (props) => {

    useDocumentTitle("Logowanie");
    const navigate = useNavigate();

    const apiLoginFb = useApi(api.auth.postLoginFb);

    const { saveUser } = useContext(UserContext);
    const { cookiesConsent } = useContext(AppSettingsContext);

    const [isToValidate, setIsToValidate] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        setIsToValidate(true);

        if (form.checkValidity() === true) {
            setIsToValidate(false);
        }
    };

    useEffect(() => {
        if (!apiLoginFb.isError && apiLoginFb.data) {
            saveUser(apiLoginFb.data);
            navigate(routes.user.profile);
        }
    }, [apiLoginFb.data, apiLoginFb.errors]);

    const onFormChange = () => {
        setIsToValidate(true);
    };

    const responseFacebook = (response) => {
        setIsToValidate(false);
        apiLoginFb.send({
            fbToken: response.accessToken,
            fbId: response.userID
        });
    }



    return (<>

        <Container fluid className="AuthContainer ">
            <Row className="d-flex justify-content-center">
                <Col className="mt-1" xl={9} md={8}>
                    <Card>
                        <Card.Header className="text-center">
                            <span className="RFT-2 RF-3 ">LOGOWANIE</span>
                        </Card.Header>
                        <Card.Body>
                            <ContainerWithImage image={logImg} xl={8} lg={12}
                                className="align-self-center" imgMaxWidth="300px">
                                <Row>
                                    <Form noValidate validated={isToValidate} onSubmit={handleSubmit}>
                                        <Form.Group>
                                            <FloatingLabel controlId="username" label="E-mail" className="mb-3 RFT-2" size="lg" >
                                                <Form.Control type="email" placeholder=" " className="RFT-3 shadow-none mb-2"
                                                    size="lg" required value={email} autoComplete="username"
                                                    onChange={(e) => { setEmail(e.target.value); onFormChange(); }} />
                                                <Form.Control.Feedback type="invalid">
                                                    Podaj prawidłowy adres email
                                                </Form.Control.Feedback>
                                            </FloatingLabel>

                                            <FloatingLabel controlId="current-password" label="Hasło" className="mb-3 RFT-2" size="lg" >
                                                <Form.Control type="password" placeholder=" " className="RFT-3 shadow-none"
                                                    size="lg" required value={password} autoComplete="current-password"
                                                    onChange={(e) => { setPassword(e.target.value); onFormChange(); }} />
                                                <Form.Control.Feedback type="invalid">
                                                    Wpisz hasło
                                                </Form.Control.Feedback>
                                            </FloatingLabel>

                                        </Form.Group>

                                        <div className="d-grid gap-2">
                                            <ApiButton api={api.auth.postLogin} labelClassName="RFT-2" type="submit"
                                                okRedirect={routes.user.profile} size="lg" showError
                                                sendData={{ email: email, password: password }}
                                                onSuccess={data => saveUser(data)}>
                                                Zaloguj
                                            </ApiButton >
                                        </div>

                                        {/* {cookiesConsent &&
                                            <ReactFacebookLogin
                                                appId="1291944814911583"
                                                fields="name,email"
                                                callback={responseFacebook}
                                                disableMobileRedirect={!isMobile}
                                                isMobile={isMobile}
                                                render={renderProps => (
                                                    <div className="d-grid gap-2 mt-2">
                                                        <Button onClick={renderProps.onClick} className="RFT-2 fbLoginButton">
                                                            {!apiLoginFb.isPending && <>Logowanie z Facebook</>}
                                                            {apiLoginFb.isPending && <>Logowanie...</>}
                                                        </Button>
                                                    </div>
                                                )}
                                            />
                                        } */}
                                        <div className="RFT-2 mt-2 RF-6">
                                            <span className="">
                                                Logując się akceptujesz&nbsp;
                                            </span>
                                            <Link to={routes.global.rules} className="">
                                                Regulaminy i polityke prywatności
                                            </Link>
                                        </div>


                                    </Form>

                                </Row>
                                <Row className="d-flex justify-content-around mt-2">

                                    <Link to={routes.auth.register} className="text-decoration-none">
                                        <div className="RFT-2 RF-6 p-1 text-secondary text-decoration-underline">
                                            Nie masz jeszcze konta?
                                        </div>
                                    </Link>
                                    <Link to={routes.auth.wantPasswordReset} className="text-decoration-none">
                                        <div className="RFT-2 RF-6 p-1 text-secondary text-decoration-underline">
                                            Zapomniałeś hasła?
                                        </div>
                                    </Link>

                                    <Link to={routes.auth.sendVerifyEmail} className="text-decoration-none">
                                        <div className="RFT-2 RF-6 p-1 text-secondary text-decoration-underline">
                                            Wyślij ponownie e-mail weryfikacyjny
                                        </div>
                                    </Link>

                                </Row>
                            </ContainerWithImage>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    </>);

};

export default LoginScreen;