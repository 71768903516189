
import { ButtonGroup, ToggleButton, Tooltip, OverlayTrigger } from "react-bootstrap";

const RadioButtons = (props) => {

    let val;
    if (props.radios.filter(e => e.value == props.value).length == 0) {
        val = props.default;
        props.onChange(val);
    } else {
        val = props.value;
    }

    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip" className={props.tipClass}>
            {text}
        </Tooltip>
    );


    return (<>
        <ButtonGroup size={props.size} id={props.id} className={props.className}>
            {props.radios.map((radio, idx) => (
                <OverlayTrigger
                    key={radio.value}
                    placement={(props.tipPlacement) ? props.tipPlacement : 'auto'}
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(radio.tip)}
                    trigger={(radio.tip) ? ['hover', 'focus'] : null}
                >

                    <ToggleButton

                        id={`RadioButtons-${props.id}-${radio.value}`}
                        type="radio"
                        variant={radio.variant}
                        value={radio.value}
                        checked={val == radio.value}
                        onChange={(e) => { props.onChange(e.currentTarget.value) }}
                        className={props.buttonClass}
                        disabled={!props.enabled}
                    >
                        {radio.text}
                    </ToggleButton>
                </OverlayTrigger>
            ))}
        </ButtonGroup>
    </>);

};

//

export default RadioButtons;