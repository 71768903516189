import { useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";

import * as Icon from 'react-bootstrap-icons';

const ArrayEditor = (props) => {

    let array = props.value;

    const [add, setAdd] = useState("");

    const onChange = (val, idx) => {
        array[idx] = val;
        props.onChange(array);
    }

    const onDelete = (idx) => {
        array.splice(idx, 1);
        props.onChange(array);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (add) {
            if (!array) {
                array = [add];
            } else {
                array.push(add);
            }

            setAdd("");
            props.onChange(array);
        }
    }


    return (<>
        <Card>
            <Card.Header> {props.header}</Card.Header>
            <Card.Body>
                <Row className="g-2">
                    {array && array.map(((val, idx) => (
                        <Col key={idx} xs={12} md={6} xl={4}>
                            <InputGroup>
                                <Form.Control className={props.inputClassName} value={val} onChange={(e) => onChange(e.target.value, idx)} />
                                <Button variant="danger" className="d-flex align-items-center shadow-none" onClick={() => { onDelete(idx) }}>
                                    <Icon.XLg style={{ fontSize: 20 }} />
                                </Button>
                            </InputGroup>
                        </Col>
                    )))}
                    {/* {{array.length == 0 && <>
                        <span className={props.inputClassName}>Pusto</span>
                    </>}} */}
                </Row>
            </Card.Body>
            <Card.Footer>
                <Row className="g-2">
                    <Col xs={12} md={6} xl={4}>
                        <Form onSubmit={handleSubmit} noValidate >
                            <InputGroup>
                                <Form.Control className={props.inputClassName} placeholder={props.placeholder}
                                    value={add} onChange={(e) => setAdd(e.target.value)} />
                                <Button variant="success" type="submit" className="d-flex align-items-center shadow-none">
                                    <Icon.PlusLg style={{ fontSize: 20 }} />
                                </Button>
                            </InputGroup>
                        </Form>

                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    </>)
}

export default ArrayEditor;