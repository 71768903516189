import { Row, Col, Form, Card, Container, FloatingLabel } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "Defines/Api";

import ContainerWithImage from "Components/Layout/ContainerWithImage";

import lovImg from 'Img/unDraw/Love.svg'
import "./AuthScreens.css"
import routes from "Defines/Routes";
import ApiButton from "Components/Fetching/ApiButton";
import FastInput from "Components/Form/FastInput";
import { useDocumentTitle } from "usehooks-ts";

const RegisterScreen = (props) => {

    useDocumentTitle("Rejestracja");

    const [isToValidate, setIsToValidate] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [acceptRules, setAcceptRules] = useState(false);

    const [confPattern, setConfPattern] = useState("");



    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            setIsToValidate(false);
        }
    };

    const onChangeName = (e) => {
        setIsToValidate(true);
        setName(e.target.value);
    }
    const onChangeEmail = (e) => {
        setIsToValidate(true);
        setEmail(e.target.value);
    }
    const onChangePassword = (e) => {
        setIsToValidate(true);
        setPassword(e.target.value);
    }
    const onChangePasswordConfirmation = (e) => {
        setIsToValidate(true);
        setPasswordConfirmation(e.target.value);
    }
    const onChangeAcceptRules = (e) => {
        setIsToValidate(true);
        setAcceptRules(e.target.checked);
    }

    useEffect(() => {
        if (password === passwordConfirmation) {
            setConfPattern(".{8,}");
        } else {
            setConfPattern("");
        }
    }, [password, passwordConfirmation]);

    return (<>
        <Container fluid className="AuthContainer ">
            <Row className="d-flex justify-content-center">
                <Col className="mt-1" xl={9} md={8}>
                    <Card>
                        <Card.Header className="text-center">
                            <span className="RFT-2 RF-3">REJESTRACJA</span>
                        </Card.Header>
                        <Card.Body>
                            <ContainerWithImage image={lovImg} xl={8} lg={12}
                                className="align-self-center" imgMaxWidth="300px">

                                {/*
                                <FastInput label="Nazwa konta" labelClassName="RFT-2" inputClassName="RFT-3 shadow-none mb-2" size="lg"
                                    onChange={setName} type="text" value={name} autoComplete="false" minLength={4} 
                                    validate invalid="afds"/>
                                */}

                                <Form noValidate validated={isToValidate} onSubmit={handleSubmit} className="w-100" autoComplete="off">



                                    <FloatingLabel controlId="register-name" label="Nazwa" className="mb-3 RFT-2" size="lg" >
                                        <Form.Control type="text" placeholder=" " className="RFT-3 shadow-none mb-2" size="lg"
                                            value={name} onChange={(e) => { onChangeName(e); }}
                                            minLength={4} autoComplete="false" required />
                                        <Form.Control.Feedback type="invalid">
                                            Nazwa musi posiadać co najmniej 4 znaki
                                        </Form.Control.Feedback>
                                    </FloatingLabel>

                                    <FloatingLabel controlId="register-email" label="E-mail" className="mb-3 RFT-2" size="lg" >
                                        <Form.Control type="email" placeholder=" " className="RFT-3 shadow-none mb-2" size="lg"
                                            value={email} onChange={(e) => { onChangeEmail(e); }}
                                            autoComplete="off" required />
                                        <Form.Control.Feedback type="invalid">
                                            Podaj prawidłowy adres e-mail
                                        </Form.Control.Feedback>
                                    </FloatingLabel>

                                    <FloatingLabel controlId="new-password" label="Hasło" className="mb-3 RFT-2" size="lg" >
                                        <Form.Control type="password" placeholder=" " className="RFT-3 shadow-none" size="lg"
                                            value={password} onChange={(e) => { onChangePassword(e); }}
                                            autoComplete="new-password" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[0-9a-zA-Z!@#$%&^*()]{8,}$" />
                                        <Form.Control.Feedback type="invalid">
                                            Musi Zawierać co najmniej:<br />
                                            -Osiem znaków<br />
                                            -Jedną małą i dużą literę<br />
                                            -Jedną liczbę
                                        </Form.Control.Feedback>
                                    </FloatingLabel>

                                    <FloatingLabel controlId="new-password-confirmation" label="Potwierdź hasło" className="mb-3 RFT-2" size="lg" >
                                        <Form.Control type="password" placeholder=" " className="RFT-3 shadow-none" size="lg"
                                            value={passwordConfirmation} onChange={(e) => { onChangePasswordConfirmation(e) }}
                                            autoComplete="new-password" required pattern={confPattern} />
                                        <Form.Control.Feedback type="invalid">
                                            Hasła nie są identyczne
                                        </Form.Control.Feedback>
                                    </FloatingLabel>

                                    <Form.Check type="checkbox" id="RegAcceptReg" className="RFT-2 RF-5 mb-2 me-0" inline>
                                        <Form.Check.Input type="checkbox" required checked={acceptRules} onChange={(e) => onChangeAcceptRules(e)} />
                                        <Form.Check.Label className="RFT-2 RF-5 me-0">
                                            <span className="RFT-2 RF-7 mb-2">
                                                Akceptuję&nbsp;
                                            </span>
                                            <Link to={routes.global.rules} className="RFT-2 RF-7">
                                                Regulaminy i polityke prywatności
                                            </Link>
                                        </Form.Check.Label>
                                        <Form.Control.Feedback type="invalid" className="RFT-2 RF-7 ms-0">
                                            Musisz zaakceptować zasady
                                        </Form.Control.Feedback>
                                    </Form.Check>

                                    <div className="d-grid gap-2">
                                        <ApiButton api={api.auth.postRegister} labelClassName="RFT-2" type="submit"
                                            okRedirect={routes.auth.login} size="lg" showError sendData={{
                                                email: email,
                                                name: name,
                                                password: password,
                                                password_confirmation: passwordConfirmation,
                                                acceptRules: acceptRules,
                                            }}>
                                            Zarejestruj
                                        </ApiButton >
                                    </div>

                                </Form>

                            </ContainerWithImage>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >

    </>);

};

export default RegisterScreen;