
import { Row, Col, Card } from "react-bootstrap";
import {  useState } from "react";

import api from "Defines/Api";
import useApi from "Hooks/useApi";

import * as Icon from 'react-bootstrap-icons';
import 'CSS/fonts.css'

import PostToast from "Components/Fetching/PostToast";
import RadioButtons from "Components/Form/RadioButtons";
import useEffectNoFirstRender from "Hooks/useEffectNoFirstRender";


const InvitationForm = (props) => {

    const apiInvitation = useApi(api.public.patchInvitation);

    const invData = props.invitation;
    const enableCar = invData.acceptTransport;
    const enableHotel = invData.acceptHotel;

    const [transport, setTransport] = useState(props.invitation.needTransport);
    const [hotel, setHotel] = useState(props.invitation.needHotel);

    const transportRadios = [
        { text: <><Icon.CarFrontFill className="RF-3" /></>, value: 1, variant: 'outline-success', tip: "Potrzebuję" },
        { text: <><Icon.XLg className="RF-3" /></>, value: 0, variant: 'outline-danger', tip: "Nie potrzebuję" },
    ];
    const hotelRadios = [
        { text: <><Icon.House className="RF-3" /></>, value: 1, variant: 'outline-success', tip: "Potrzebuję" },
        { text: <><Icon.XLg className="RF-3" /></>, value: 0, variant: 'outline-danger', tip: "Nie potrzebuję" },
    ];

    useEffectNoFirstRender(() => {
        apiInvitation.send({
            token: invData.token,
            needTransport: transport,
            needHotel: hotel
        });
    }, [transport, hotel]);

    return (<>

        <PostToast isPending={apiInvitation.isPending} errors={apiInvitation.errors} className="RFT-2 RF-5"/>

        <Card className="mb-2">
            <Card.Body>
                <Row className="d-flex align-items-end">

                    {enableCar && <Col className="text-center mb-2" lg={6} sm={12}>
                        <div className="mb-1 RFT-2 RF-5">Potrzebujecie transportu na wesele?</div>
                        <RadioButtons tipPlacement='bottom' tipClass="RFT-2 RF-7" id="TransportInvForm" radios={transportRadios}
                            default={0} enabled={props.enabled} value={transport} onChange={setTransport}
                            className="d-flex mb-1 " buttonClass="shadow-none d-flex align-items-center justify-content-center" />
                    </Col>}

                    {enableHotel && <Col className="text-center mb-2" lg={6} sm={12}>
                        <div className="mb-1 RFT-2 RF-5">Potrzebujecie noclegu na weselu?</div>
                        <RadioButtons tipPlacement='bottom' tipClass="RFT-2 RF-7" id="HotelInvForm" radios={hotelRadios}
                            default={false} enabled={props.enabled} value={hotel} onChange={setHotel}
                            className="d-flex mb-1 " buttonClass="shadow-none d-flex align-items-center justify-content-center" />
                    </Col>}

                    {((props.enableCar && !props.enableHotel) || (!props.enableCar && props.enableHotel)) && <>
                        <Col className="text-center mb-2" lg={6} sm={12}><div></div></Col>
                    </>}

                </Row>
            </Card.Body>
        </Card>

    </>);
}

export default InvitationForm;