import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import { redirectOnUnAuth } from "Defines/Routes";
import { UserContext } from "Defines/Contexts";

const { REACT_APP_FETCH_TIMEOUT } = process.env;

const useApi = (apiDefine, urlAddon = null, autoSendBody = null) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(undefined);
    const [isError, setIsError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [wasSended, setWasSended] = useState(false);

    const { user, saveUser } = useContext(UserContext);

    const method = apiDefine.method;
    const url = (urlAddon) ? apiDefine.url + "/" + urlAddon : apiDefine.url;
    const auth = apiDefine.auth;

    let abortCont = null;


    useEffect(() => {
        if (autoSendBody) {
            if (autoSendBody === true)
                send();
            else
                send(autoSendBody);
        }
        return () => {
            if (abortCont)
                abortCont.abort();
        }

    }, []);


    const parseErrors = (dat) => {
        let errorsArray = [];
        for (var propt in dat) {
            //console.log(propt + ': ' + dat[propt]);
            errorsArray.push(dat[propt]);
        }
        setErrors(errorsArray)
    }



    const send = (body = null, urlAddon = null) => {
        if (auth && !user?.apiToken) return;

        abortCont = new AbortController();

        setIsPending(true);
        setIsError(false);
        setErrors(null);
        setWasSended(true);


        return new Promise((resolve, reject) => {

            let options = {
                method: method,
                signal: abortCont.signal,
                headers: {}
            }
            if (auth)
                options.headers.Authorization = "Bearer " + user.apiToken;

            if (body)
                if (body instanceof FormData) {
                    options.body = body;
                } else {
                    options.body = JSON.stringify(body);
                    options.headers["Content-Type"] = "application/json";
                }


            setTimeout(() => {
                const finalUrl = (urlAddon) ? url + "/" + urlAddon : url;
                let resp;
                fetch(finalUrl, options)
                    .then(res => {
                        resp = res;

                        switch(res.status){
                            case 204:   //No Content
                            setIsError(false);
                            setErrors(false);
                            setData(null);
                            return;
                        }

                        return res.json();
                    })
                    .then(dat => {
                        switch (resp.status) {
                            case 200:   //OK
                            case 201:   //Created
                            case 202:   // Accepted
                                setIsError(false);
                                setErrors(false);
                                setData(dat);
                                break;

                            
                            case 401:   //Unauthorized
                                saveUser(false);
                            case 403:   //Forbidden
                            case 400:   //Bad Request
                            default:
                                setData(null);
                                setIsError(true);
                                parseErrors(dat);
                                break;
                        }
                        setIsPending(false)
                        if(resp.ok)
                            resolve(dat)
                        else
                            reject(dat)
                    })
                    .catch(error => {
                        setData(null);
                        if (error.name !== 'AbortError') {
                            setErrors(["Fail to fetch!"]);
                            setIsError(true);
                            setIsPending(false);
                            reject(error);
                        }
                    });

            }, REACT_APP_FETCH_TIMEOUT);

        });
    }

    return ({ send, data, isPending, wasSended, isError, errors });
}

export default useApi;