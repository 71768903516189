
function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function arrayBufferToBase64( buffer ) {
	var binary = '';
	var bytes = new Uint8Array( buffer );
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode( bytes[ i ] );
	}
	return window.btoa( binary );
}

function getApplicationServerKeyUint8Array() {
    return urlBase64ToUint8Array(
        'BCLTPIid1fE3u6daDM3ey3n74B-YuBfqR8iPhgiesz-8u4sk6Vlc9UE05Dp4CoO1qMRU8SNTDz2W6hZUGsZrU4s'
    );
}

export {getApplicationServerKeyUint8Array, arrayBufferToBase64}