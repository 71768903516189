import { useParams, Navigate, useSearchParams } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";

import api from 'Defines/Api';
import useApi from 'Hooks/useApi';


import WeddingInfo from "./components/WeddingInfo";
import InvitationInfo from "./components/InvitationInfo";
import LoadingSpinner from "Components/Others/LoadingSpinner";
import MessageSender from './components/MessageSender';
import InvitationNote from './components/InvitationNote';

const PublicInvitationScreen = (props) => {

    
    const { invTok } = useParams();
    const [searchParams] = useSearchParams();
    
    const apiData = useApi(api.public.getData, invTok+((searchParams.get("private")==null)?"":"?private"), true);

    const isEnableResponse = () => {
        if (!apiData.data.wedding.inviteResponseDate)
            return false;

        var resp = new Date(apiData.data.wedding.inviteResponseDate);
        var now = new Date();
        resp.setHours(0, 0, 1);
        now.setHours(0, 0, 0);

        return (resp >= now);
    }



    return (
        <>
            {apiData.isPending &&
                <LoadingSpinner />
            }
            {apiData.errors &&
                <Navigate to="/404" />
            }

            {apiData.data &&
                <>
                    <Row className="justify-content-center">
                        <WeddingInfo wedding={apiData.data.wedding} setTitle={true} />
                    </Row>


                    {apiData.data.persons &&
                        <Row className="justify-content-center">
                            <InvitationInfo invitation={apiData.data} enabled={isEnableResponse()} />
                        </Row>
                    }

                    {apiData.data.notePublic &&
                        <Row>
                            <Col className="mt-2">
                                <InvitationNote note={apiData.data.notePublic} />
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col className="mt-2">
                            <MessageSender invitation={apiData.data} />
                        </Col>
                    </Row>
                </>

            }
        </>
    );

};

export default PublicInvitationScreen;