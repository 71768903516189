import { useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";

import * as Icon from 'react-bootstrap-icons';

const LinkEditor = (props) => {

    let array = props.value;

    const [add, setAdd] = useState(["", ""]);

    const onChangeName = (val, idx) => {
        array[idx][0] = val;
        props.onChange(array);
    }

    const onChangeLink = (val, idx) => {
        array[idx][1] = val;
        props.onChange(array);
    }

    const onDelete = (idx) => {
        array.splice(idx, 1);
        props.onChange(array);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (add) {
            if (!array) {
                array = [add];
            } else {
                array.push(add);
            }

            setAdd(["", ""]);
            props.onChange(array);
        }
    }


    return (<>
        <Card>
            <Card.Header> {props.header}</Card.Header>
            <Card.Body>
                <Row className="g-2">
                    {array && array.map(((val, idx) => (
                        <Col key={idx} xs={12} className="mb-1">
                            <InputGroup>
                                <InputGroup.Text className={props.textClassName}>Nazwa</InputGroup.Text>
                                <Form.Control className={props.inputClassName}
                                    value={val[0]} onChange={(e) => onChangeName(e.target.value, idx)} />

                                <Button variant="danger" className="d-flex align-items-center shadow-none" onClick={() => { onDelete(idx) }}>
                                    <Icon.XLg style={{ fontSize: 20 }} />
                                </Button>

                            </InputGroup>
                            <InputGroup className="mt-1">
                                <InputGroup.Text className={props.textClassName}>Link</InputGroup.Text>
                                <Form.Control className={props.inputClassName}
                                    value={val[1]} onChange={(e) => onChangeLink(e.target.value, idx)} />


                            </InputGroup>
                        </Col>
                    )))}
                </Row>
            </Card.Body>
            <Card.Footer>
                <Row className="g-2">
                    <Col xs={12}>
                        <Form onSubmit={handleSubmit} noValidate >
                            <InputGroup>
                                <InputGroup.Text className={props.textClassName}>Nazwa</InputGroup.Text>
                                <Form.Control className={props.inputClassName}
                                    value={add[0]} onChange={(e) => setAdd([e.target.value, add[1]])} />
                            </InputGroup>
                            <InputGroup className="mt-1">
                                <InputGroup.Text className={props.textClassName}>Link</InputGroup.Text>
                                <Form.Control className={props.inputClassName}
                                    value={add[1]} onChange={(e) => setAdd([add[0], e.target.value])} />

                                <Button variant="success" type="submit" className="d-flex align-items-center shadow-none">
                                    <Icon.PlusLg style={{ fontSize: 20 }} />
                                </Button>
                            </InputGroup>
                        </Form>

                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    </>)
}

export default LinkEditor;