
const { REACT_APP_ENDPOINT } = process.env;

let refImagesRnd = Math.random();


const api = {

    refImages: () => {
        refImagesRnd = Math.random();
    },

    giftVariants: {
        index: { method: 'GET', url: REACT_APP_ENDPOINT + "/api/giftVariants", auth: false },
        showUrl: (id) => { return (REACT_APP_ENDPOINT + "/api/giftVariants/" + id + "?r=" + refImagesRnd) },
    },

    storage: {
        logo: {
            showUrl: (token) => { return (REACT_APP_ENDPOINT + "/api/storage/logo/" + token + "?r=" + refImagesRnd) },
            post: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/storage/logo", auth: true },
            delete: { method: 'DELETE', url: REACT_APP_ENDPOINT + "/api/storage/logo", auth: true },
        },
        header: {
            showUrl: (token) => { return (REACT_APP_ENDPOINT + "/api/storage/header/" + token + "?r=" + refImagesRnd) },
            post: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/storage/header", auth: true },
            delete: { method: 'DELETE', url: REACT_APP_ENDPOINT + "/api/storage/header", auth: true },
        }
    },

    public: {
        getData: { method: 'GET', url: REACT_APP_ENDPOINT + "/api/public/invitationData", auth: false },

        postMessage: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/public/message", auth: false },
        patchPerson: { method: 'PATCH', url: REACT_APP_ENDPOINT + "/api/public/person", auth: false },
        patchInvitation: { method: 'PATCH', url: REACT_APP_ENDPOINT + "/api/public/invitation", auth: false },
    },

    auth: {
        postLogin: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/auth/login", auth: false },
        postLoginFb: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/auth/facebook", auth: false },
        postLogout: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/auth/logout", auth: true },
        postRegister: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/auth/register", auth: false },

        postWantVerifyEmail: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/auth/wantSendConfirmEmail", auth: false },
        postConfirmEmail: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/auth/confirmEmail", auth: false },

        postWantResetPassword: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/auth/wantResetPassword", auth: false },
        postResetPassword: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/auth/resetPassword", auth: false },
    },

    user: {
        get: { method: 'GET', url: REACT_APP_ENDPOINT + "/api/user", auth: true },
        patch: { method: 'PATCH', url: REACT_APP_ENDPOINT + "/api/user", auth: true },
        delete: { method: 'DELETE', url: REACT_APP_ENDPOINT + "/api/user", auth: true },

        notification:{
            check: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/user/webPush/check", auth: true },
            post: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/user/webPush", auth: true },
            delete: { method: 'DELETE', url: REACT_APP_ENDPOINT + "/api/user/webPush", auth: true },

            test: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/user/webPush/notify", auth: true },
        }
    },

    weddings: {
        show: (wedId) => ({ method: 'GET', url: REACT_APP_ENDPOINT + "/api/weddings/" + wedId, auth: true }),

        get: { method: 'GET', url: REACT_APP_ENDPOINT + "/api/weddings", auth: true },
        delete: { method: 'DELETE', url: REACT_APP_ENDPOINT + "/api/weddings", auth: true },
        post: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/weddings", auth: true },
        patch: { method: 'PATCH', url: REACT_APP_ENDPOINT + "/api/weddings", auth: true },

    },

    invitations: {
        get: (wedId, persons = false, wedding = false, messages = false) => ({
            url: REACT_APP_ENDPOINT + "/api/invitations?" +
                ((wedId) ? "weddingId=" + wedId : "") +
                ((persons) ? "&persons" : "") +
                ((wedding) ? "&wedding" : "") +
                ((messages) ? "&messages" : ""),
            method: 'GET', auth: true
        }),

        delete: { method: 'DELETE', url: REACT_APP_ENDPOINT + "/api/invitations", auth: true },
        post: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/invitations", auth: true },
        patch: { method: 'PATCH', url: REACT_APP_ENDPOINT + "/api/invitations", auth: true },
    },

    persons: {
        get: (wedId = null, invId = null) => ({
            url: REACT_APP_ENDPOINT + "/api/persons/?" +
                ((wedId) ? "weddingId=" + wedId : "") +
                ((invId) ? "&invitationId=" + invId : ""),
            method: 'GET', auth: true
        }),
        post: { method: 'POST', url: REACT_APP_ENDPOINT + "/api/persons", auth: true },
        patch: { method: 'PATCH', url: REACT_APP_ENDPOINT + "/api/persons", auth: true },
        delete: { method: 'DELETE', url: REACT_APP_ENDPOINT + "/api/persons", auth: true },
    },

    messages: {
        delete: { method: 'DELETE', url: REACT_APP_ENDPOINT + "/api/messages", auth: true },
    },

};

export default api;