import { Row, Col, Image } from "react-bootstrap";


const ContainerWithImage = (props) => {

    //breakpoint

    const imageCol =
        <Col xxl={12 - props.xxl} xl={12 - props.xl} lg={12 - props.lg} md={12 - props.md} sm={12 - props.sm} xs={12 - props.xs}
            className="d-flex justify-content-center">
            <div style={{ maxWidth: props.imgMaxWidth }} className="d-flex align-items-center">
                <Image src={props.image} fluid />
            </div>
        </Col>

    const contentCol =
        <Col xxl={props.xxl} xl={props.xl} lg={props.lg} md={props.md} sm={props.sm} xs={props.xs}
            className={props.className}>
            {props.children}
        </Col>


    return (<>
        <Row className="g-3">
            {props.reverse && contentCol}
            {imageCol}
            {!props.reverse && contentCol}

        </Row>

    </>);
};

export default ContainerWithImage;