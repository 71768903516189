import { useEffect, useState } from "react";
import { Image, Spinner } from "react-bootstrap";

import QRCodeStyling from "qr-code-styling";

const QRCode = (props) => {
    //format: object, image: string ,size: number, url: string, className: string,
    const qrDefault = {
        dotsOptions: {
            color: "#000000",
            type: "square"
        },
        cornersSquareOptions: {
            color: "#000000",
            type: "square"
        },
        cornersDotOptions: {
            color: "#000000",
            type: "square"
        },
    }
    const qrConsts = {
        type: "png",
        qrOptions: {
            errorCorrectionLevel: 'M'
        },
        imageOptions: {
            hideBackgroundDots: true
        },
    }

    const [imageUrl, setImageUrl] = useState(null);

    const [qrURL, setQrURL] = useState("");
    const [isRendering, setIsRendering] = useState(true);

    useEffect(() => {
        setImageUrl(null);
        fetch(props.image)
            .then(res => {
                if (res.ok) {
                    setImageUrl(props.image);
                }
            });
    }, [props.image]);

    useEffect(() => {
        const defFormat = {
            image: imageUrl,
            width: props.size,
            height: props.size,
            data: props.url
        }
        const qrFormat = { ...qrDefault, ...props.format, ...defFormat, ...qrConsts };

        const tout = setTimeout(() => {
            setIsRendering(true);
        }, 400);

        new QRCodeStyling(qrFormat).getRawData()
            .then(dat => {
                URL.revokeObjectURL(qrURL);
                setQrURL(URL.createObjectURL(dat));
                clearTimeout(tout);
                setIsRendering(false);
            });

        return (() => clearTimeout(tout));

    }, [imageUrl, props.format, props.url, props.size])






    return (<div className={props.className}>
        {!isRendering && <Image src={qrURL} />}
        {isRendering &&
            <div style={{ height: props.size, width: props.size }}
                className="d-flex align-items-center justify-content-center">
                <Spinner animation="border" role="status" />
            </div>
        }
    </div>);
}

export default QRCode;