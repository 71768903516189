import { Dropdown } from "react-bootstrap";

import * as Icon from 'react-bootstrap-icons';

import './ProfileButton.css'
import 'CSS/fonts.css'
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "Defines/Contexts";
import routes from "Defines/Routes";

const ProfileButton = (props) => {

    const navigate = useNavigate();

    const { user } = useContext(UserContext);


    const handleLink = (link) => {
        props.handleToggleOffCanvas();
        navigate(link);
    }


    function truncate(str, n) {
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    };



    const dropdownMenuGuest = () => {
        return (<>
            <Dropdown.Item className="RFT-2" onClick={() => handleLink("/auth/login")}>
                <Icon.PersonLinesFill className="me-2" />
                Logowanie
            </Dropdown.Item>

            <Dropdown.Item className="RFT-2" onClick={() => handleLink("/auth/register")}>
                <Icon.PersonPlusFill className="me-2" />
                Rejestracja
            </Dropdown.Item>
        </>);
    }

    const dropdownMenuUser = () => {
        return (<>
            <Dropdown.Item className="RFT-2" onClick={() => handleLink(routes.user.profile)}>
                <Icon.PersonFill className="me-2" />
                Mój profil
            </Dropdown.Item>

            <Dropdown.Item className="RFT-2" onClick={() => handleLink(routes.weddings.list)}>
                <Icon.CalendarEventFill className="me-2" />
                Moje wesela
            </Dropdown.Item>

            <Dropdown.Item className="RFT-2" onClick={() => handleLink(routes.auth.logout)}>
                <Icon.BoxArrowLeft className="me-2" />
                Wyloguj
            </Dropdown.Item>
        </>);
    }

    return (

        <Dropdown  className={props.className}>
            <Dropdown.Toggle variant="outline-dark" className="shadow-none ProfileButton RFT-2 my-2">
                <Icon.PersonCircle className="ProfileButtonIcon me-3" />
                <span className="RFT-3 my-1 me-2">
                    {user && truncate(user.name, 16)}
                    {!user && truncate("Gość", 16)}
                </span>
            </Dropdown.Toggle>

            <Dropdown.Menu align="end" >
                {user && dropdownMenuUser()}
                {!user && dropdownMenuGuest()}

            </Dropdown.Menu>
        </Dropdown>
    );

}

/*

<Button variant="outline-dark" className="shadow-none ProfileButton RFT-2 my-1" onClick={() => click()}>
            <Icon.PersonCircle className="ProfileButtonIcon me-3" />
            {truncate("Sebastian Koczurkowski", 16)}

        </Button>

*/

export default ProfileButton;