import { BrowserRouter } from "react-router-dom";

import 'CSS/custom.scss'
import 'CSS/Other.css'
import 'CSS/fonts.css'
import 'App/App.css';

import UserProvider from "Utils/UserProvider";
import AppSettingsProvider from "Utils/AppSettingsProvider";

import AppRouter from "./AppRouter";

const App = () => {

    document.title = "QRInvite"

    return (
        <BrowserRouter>

            <AppSettingsProvider>

                <UserProvider>

                    <AppRouter />

                </UserProvider>

            </AppSettingsProvider>
            
        </BrowserRouter>
    );
}

export default App;