import { useState } from "react";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";


const ConfirmDeleteModal = (props) => {

    //show, onCancel, onSubmit, message, confirmationText

    const [isToValidate, setIsToValidate] = useState(false);
    const [text, setText] = useState("");

    const onChangeName = (e) => {
        setIsToValidate(true);
        setText(e.target.value.toLowerCase());
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            setIsToValidate(false);
            props.onSubmit(text);
            setText("");
        }
    };

    const onCancel = () => {
        setText("");
        props.onCancel();
    }

    return (<>
        <Modal show={props.show} onHide={props.onCancel} centered={props.centered}>

            <Modal.Header closeButton>
                <Modal.Title className="RFT-2">Potwierdź usunięcie</Modal.Title>
            </Modal.Header>

            <Form noValidate validated={isToValidate} onSubmit={handleSubmit} className="w-100">
                <Modal.Body>

                    <Form.Label className="RFT-2">
                        Przepisz "<span className="text-danger">{props.confirmationText}</span>" aby potwierdzić
                    </Form.Label>
                    <FloatingLabel controlId="floatingNameReg" label="potwierdzenie" className="RFT-2" size="lg" >
                        <Form.Control type="text" placeholder=" " className="RFT-2 shadow-none mb-2" size="lg"
                            value={text} onChange={(e) => { onChangeName(e); }}
                            minLength={4} required pattern={props.confirmationText.toLowerCase()} />
                        <Form.Control.Feedback type="invalid">
                            Nie poprawny tekst
                        </Form.Control.Feedback>
                    </FloatingLabel>


                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" className="RFT-2" onClick={onCancel}>Anuluj</Button>
                    <Button variant="danger" type="submit" className="RFT-2">Usuń</Button>
                </Modal.Footer>

            </Form>
        </Modal>

    </>);

};

export default ConfirmDeleteModal;