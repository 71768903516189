import { Card, Row, Col, Image, Button } from "react-bootstrap";
import { useState } from "react";
import { Textfit } from "react-textfit";

import useApi from "Hooks/useApi";
import api from "Defines/Api";

import { stringToNiceDate } from "Functions/DataTime";

import ConfirmDeleteModal from "Components/Modals/ConfirmDeleteModal"
import PostToast from "Components/Fetching/PostToast";

import './WeddingField.css'
import { useNavigate } from "react-router-dom";
import routes from "Defines/Routes";


const WeddingField = (props) => {

    const wedding = props.wedding;

    const deleteWedding = useApi(api.weddings.delete);

    const navigate = useNavigate();

    const [showDelete, setShowDelete] = useState(false);
    const onDeleteSubmit = () => {
        deleteWedding.send(null, wedding.id)
            .then(() => props.onRefresh());

        setShowDelete(false);
    }
    const onDeleteCancel = () => {
        setShowDelete(false);
    }

    const onDelete = () => {
        if (wedding.groom.length === wedding.bride.length && wedding.bride.length < 4) {
            onDeleteSubmit();
        } else {
            setShowDelete(true);
        }

    }

    return (<>

        <ConfirmDeleteModal show={showDelete} centered confirmationText={wedding.bride + " " + wedding.groom}
            onCancel={onDeleteCancel} onSubmit={onDeleteSubmit} />
        <PostToast isPending={deleteWedding.isPending} errors={deleteWedding.errors} className="RFT-2 RF-5" />

        <Card className="my-2 WeddingFieldLinkBg" onClick={() => navigate(routes.weddings.showGen(wedding.id))}>
            <Card.Body className="p-1">
                <Row className="m-1">
                    <Col lg={5} xs={12} className="d-flex align-items-center">
                        <Image src={api.storage.header.showUrl(wedding.token)} fluid rounded />
                    </Col>
                    <Col lg={7} xs={12} className="d-flex flex-column justify-content-center my-2">
                        <div className=" RFT-1 text-center mb-2">
                            <Textfit mode="single">
                                <span className="text-center">{wedding.bride} &</span>
                                <span className="text-center"> {wedding.groom}</span>
                            </Textfit>
                        </div>
                        <div className="text-center">
                            <Textfit mode="single" max={28}>
                                <div className="RFT-2">{stringToNiceDate(wedding.date, 1)}</div>
                            </Textfit>
                        </div>
                    </Col>
                    {/*<Col lg={2} xs={12} className="d-flex flex-column justify-content-center">
                        <Row>
                            <Col lg={12} sm={3} className="d-grid my-1">
                                <Button variant="secondary" className="RFT-2 shadow-none"
                                    onClick={() => navigate(routes.weddings.showGen(wedding.id))}>
                                    PRZEGLĄD
                                </Button>
                            </Col>
                            <Col lg={12} sm={3} className="d-grid my-1">
                                <Button variant="secondary" className="RFT-2 shadow-none"
                                    onClick={() => navigate(routes.weddings.editGen(wedding.id))}>
                                    EDYTUJ
                                </Button>
                            </Col>
                            <Col lg={12} sm={3} className="d-grid my-1">
                                <Button variant="secondary" className="RFT-2 shadow-none"
                                    onClick={() => navigate(routes.weddings.guests + wedding.id)}>
                                    GOŚCIE
                                </Button>
                            </Col>
                            <Col lg={12} sm={3} className="d-grid my-1">
                                <Button variant="danger" className="RFT-2 shadow-none" onClick={onDelete}>
                                    USUŃ
                                </Button>
                            </Col>
                        </Row>
                    </Col> */}

                </Row>
            </Card.Body>
        </Card>
    </>);
}

export default WeddingField;