import { Row, Col, Card, Toast, Spinner, ToastContainer } from "react-bootstrap";
import { useEffect, useState, useRef } from 'react';

const PostToast = (props) => {

    const [toastState, setToastState] = useState(null);
    const toastDisableTimeout = useRef(null);

    const position = (props.position) ? props.position : "bottom-end";
    const errorHideTime = (props.errorTime) ? props.errorTime : 3000;

    const pendingLabel = (props.pendingLabel) ? props.pendingLabel : "Zapisywanie";
    const okLabel = (props.okLabel) ? props.okLabel : "DANE ZAPISANE!";
    const errorLabel = (props.errorLabel) ? props.errorLabel : "Błąd!";

    //ispending, error, response, pendingLabel, errorLabel, okLabel

    useEffect(() => {
        if (props.isPending) {
            setToastState("Pending");
            if (toastDisableTimeout.current) clearTimeout(toastDisableTimeout.current);

        } else if (props.errors) {
            setToastState("Error");
            if (toastDisableTimeout.current) clearTimeout(toastDisableTimeout.current);

        } else if (toastState) {
            setToastState("Ok");
            toastDisableTimeout.current = setTimeout(() => {
                setToastState(null);
                toastDisableTimeout.current = null;
            }, errorHideTime);
        }
    }, [props.isPending, props.errors, toastState]);

    return (<>
        <ToastContainer position='bottom-end' className="p-3" containerPosition='fixed'>
            <Toast show={toastState} className="w-100" transition={false}>
                <Toast.Body className={props.className + " d-flex align-items-center"}>
                    {toastState === "Pending" && <>{pendingLabel}<Spinner animation="border" role="status" size="sm" className="ms-2" /></>}
                    {toastState === "Ok" && <span className="text-success">{okLabel}</span>}
                    {toastState === "Error" && <span className="text-danger">{errorLabel}</span>}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    </>);
}

export default PostToast;