
import { useParams } from "react-router-dom";

import api from "Defines/Api";
import useApi from "Hooks/useApi";
import { useEffect, useState } from "react";
import LoadingSpinner from "Components/Others/LoadingSpinner";
import { Button, Card, Col, InputGroup, Row, Form, Modal } from "react-bootstrap";
import ApiButton from "Components/Fetching/ApiButton";
import EditInvitationField from "./EditInvitationField";
import * as Icon from 'react-bootstrap-icons';

import GenerateQRCodesButton from "./components/GenerateQRCodesButton";
import { useDocumentTitle } from "usehooks-ts";
import InvitationSummary from "./InvitationSummary";

import { mkConfig, generateCsv, download } from "export-to-csv";

const EditGuestsScreen = (props) => {

    useDocumentTitle("Edycja gości weselnych");

    const { wedId,invId } = useParams();
    
    //console.log(invId)

    const getDataApi = useApi(api.invitations.get(wedId, true, true), null, true);

    const [wedding, setWedding] = useState(null);

    const [invitations, setInvitations] = useState([]);
    const [persons, setPersons] = useState([]);

    const [filter, setFilter] = useState("");
    const [showInvitations, setShowInvitations] = useState([]);
    const [showPersons, setShowPersons] = useState([]);

    function objectContains(object, value) {
        return JSON.stringify(object).toLocaleLowerCase().indexOf(value.toLowerCase()) !== -1;
    }

    function doFiltration() {
        if (filter !== "") {
            setShowInvitations(invitations.filter(inv => {
                const invPers = { ...inv, persons: persons.filter(pers => pers.invitationId == inv.id) };
                return objectContains(invPers, filter);
            }));

        } else {
            setShowInvitations(invitations);
        }

        setShowPersons(persons);
    }

    useEffect(() => {
        doFiltration();
    }, [filter, invitations, persons]);

    useEffect(() => {
        if (getDataApi.isPending == false) {
            setWedding((getDataApi.data.wedding) ? getDataApi.data.wedding : null);

            if (getDataApi.data.invitations) {
                setInvitations(getDataApi.data.invitations.map(inv => {
                    return { ...inv, persons: undefined };
                }));

                setPersons(getDataApi.data.invitations.map(inv => inv.persons).reduce((ac, val) => {
                    return ac.concat(val);
                }));
            } else {
                setInvitations([]);
                setPersons([]);
            }
        }
    }, [getDataApi.isPending]);


    const editInvitation = (data, deleteObj = false) => {
        if (deleteObj) {
            setInvitations(invitations.filter(inv => (inv.id != data.id)));
        } else {
            const index = invitations.findIndex(inv => (inv.id == data.id));
            if (index == -1) {
                setInvitations([...invitations, data]);
            } else {
                setInvitations(invitations.map(el => {
                    if (el.id == data.id) {
                        return { ...el, ...data };
                    }
                    return el;
                }))
            }
        }
    }

    const editPerson = (data, deleteObj = false) => {
        if (deleteObj) {
            setPersons(persons.filter(person => (person.id != data.id)));
        } else {
            const index = persons.findIndex(person => (person.id == data.id));
            if (index == -1) {
                setPersons([...persons, data]);
            } else {
                setPersons(persons.map(el => {
                    if (el.id == data.id) {
                        return { ...el, ...data };
                    }
                    return el;
                }))
            }
        }
    }


    const generatePersonsCsv = () => {
        const csvConfig = mkConfig({
            useKeysAsHeaders: true,

        });

        const pers = persons.map((p, idx) => ({
            ...p,
            needHotel: invitations.find(i => i.id === p.invitationId).needHotel,
            needTransport: invitations.find(i => i.id === p.invitationId).needTransport,
        }))


        const csv = generateCsv(csvConfig)(pers);
        download(csvConfig)(csv)
    }

    return (<>
        {getDataApi.isPending && <LoadingSpinner />}



        {wedding && <>

            <Row className="g-2">
                <Col md={6} xs={12}>
                    <InputGroup>
                        <InputGroup.Text><Icon.Search /></InputGroup.Text>
                        <Form.Control className="RFT-3" value={filter} onChange={v => setFilter(v.target.value)} />

                        {filter != "" &&
                            <Button variant="danger" className="d-flex align-items-center" onClick={() => setFilter("")}>
                                <Icon.XCircle />
                            </Button>
                        }
                    </InputGroup>
                </Col>
                <Col className="d-flex gap-2">
                    <GenerateQRCodesButton variant="secondary" className="RFT-2"
                        wedding={wedding} invitations={invitations}>
                        Generuj QRKODY
                    </GenerateQRCodesButton>
                    <Button className="RFT-2" onClick={generatePersonsCsv}>
                        Generuj podsumowanie gości
                    </Button>
                </Col>
            </Row>

            <hr />

            {Array.isArray(invitations) && <>
                {showInvitations.map((inv) => (
                    <InvitationSummary key={inv.id} wedding={wedding} invitation={inv} persons={showPersons.filter(person => person.invitationId == inv.id)}/>
                ))}
            </>}

            {filter != "" && showInvitations.length == 0 && <>
                <Card className="mt-5">
                    <Card.Body>
                        <Row >
                            <Col className="RFT-2 text-center text-muted">
                                Brak zaproszeń zawierających "{filter}"
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>}

            {filter == "" && invitations.length == 0 && <>
                <Card className="mt-5">
                    <Card.Body>
                        <Row >
                            <Col className="RFT-2 text-center text-muted">
                                Jeszcze nie dodano żadnego zaproszenia, możesz to zrobić używając przycisku <Icon.EnvelopePlusFill className="text-success" />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>}



            <Row className="mt-5 d-flex justify-content-center">
                <Col xs={12} sm={4} lg={2} className="d-grid">
                    <ApiButton variant="success" api={api.invitations.post} sendData={{ weddingId: wedding.id, name: "Nowe zaproszenie" }}
                        onSuccess={(dat) => editInvitation(dat)} showError
                        labelClassName="RFT-2 RF-4 text-white text-center py-2">
                        <Icon.EnvelopePlusFill />
                    </ApiButton>

                </Col>
            </Row>


        </>}
    </>);

}
export default EditGuestsScreen;