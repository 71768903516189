import { Row, Col, Card,} from "react-bootstrap";
import { Textfit } from 'react-textfit';

import 'CSS/fonts.css'

import PersonForm from "./PersonForm";
import EmailReminderSetter from "./EmailReminderSetter";
import InvitationForm from "./InvitationForm";


const InvitationInfo = (props) => {

    const invData = props.invitation;
    const editEnabled = props.enabled;

    return (
        <Row>
            <Card>
                <Card.Body className="px-2 py-2">
                    {invData && <>

                        <Row>
                            <Col lg={9} md={12} className="px-1">

                                <Col>
                                    {(invData.acceptTransport || invData.acceptHotel) &&
                                        <InvitationForm invitation={invData} enabled={editEnabled} />
                                    }
                                </Col>

                                {invData.persons.map(person => (
                                    <Card key={person.id} className="mb-2">
                                        <Card.Body>
                                            <PersonForm person={person} diets={invData.wedding.diets}
                                                ageRanges={invData.wedding.ageRanges} enableAnswer={editEnabled}
                                                showTables={invData.wedding.showGuestsTableLayout} invitation={invData} />
                                        </Card.Body>
                                    </Card>
                                ))}

                            </Col>
                            <Col lg={3} md={12} className="px-1">
                                <EmailReminderSetter invitation={props.invitation} enabled={editEnabled} />
                            </Col>
                        </Row>


                    </>}

                </Card.Body>
            </Card>
        </Row>);

};

export default InvitationInfo;

/*

<Row>
                            <Col md={12} className="">
                                <Textfit min={20} max={40} mode="single"><div className="RFT-2 text-center mt-2 mb-1"> {invData.name} </div></Textfit>
                            </Col>
                        </Row>

*/