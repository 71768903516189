import { Row, Col } from "react-bootstrap";
import { useState } from 'react';

import api from "Defines/Api";
import useApi from "Hooks/useApi";

import * as Icon from 'react-bootstrap-icons';
import 'CSS/fonts.css'


import FastSelect from "Components/Form/FastSelect";
import RadioButtons from "Components/Form/RadioButtons";
import useEffectNoFirstRender from "Hooks/useEffectNoFirstRender";
import PostToast from "Components/Fetching/PostToast";


const PersonForm = (props) => {

    const invData = props.invitation;
    const person = props.person;
    const ageRanges = props.ageRanges;
    const diets = props.diets;
    const enabled = props.enableAnswer;
    const showTables = props.showTables;

    const fetchPerson = useApi(api.public.patchPerson);

    const [presence, setPresence] = useState(props.person.presence);
    const [diet, setDiet] = useState(props.person.diet);
    const [ageRange, setAgeRange] = useState(props.person.ageRange);

    const radios = [
        { text: <><Icon.CheckLg  /></>, value: 1, variant: 'outline-success', tip: "Będę!" },
        { text: <><Icon.QuestionLg  /></>, value: 2, variant: 'outline-warning', tip: "Nie wiem" },
        { text: <><Icon.XLg /></>, value: 3, variant: 'outline-danger', tip: "Nie będzie mnie" },
    ];


    useEffectNoFirstRender(() => {
        fetchPerson.send({
            token: invData.token,
            id: person.id,
            presence: presence,
            diet: diet, ageRange: ageRange
        });
    }, [presence, diet, ageRange]);


    return (<>
        <PostToast isPending={fetchPerson.isPending} errors={fetchPerson.errors} className="RFT-2 RF-5" />

        <Row className="text-center mb-2 align-items-center">
            <Col className="d-flex justify-content-center" lg={9}>
                <div className="RFT-2 RF-4"> {person.name}</div>
            </Col>

            {showTables !== 0 && props.person.tableNumber &&
                <Col className="d-flex justify-content-center text-muted" lg={3}>
                    <div className="RFT-2 RF-5">Stolik {props.person.tableNumber}</div>
                </Col>
            }
        </Row>

        <hr className="mb-3 mt-0" />

        <Row className="d-flex g-1">
            <Col xs={12} sm={12} md={4}>
                <RadioButtons id={`PersonFormPresence-${person.id}`} radios={radios}
                    className="d-flex mb-1 h-100" onChange={setPresence}
                    value={presence} default={2} enabled={enabled}
                    tipPlacement='bottom' tipClass="RFT-2 RF-7"
                    buttonClass="shadow-none d-flex align-items-center justify-content-center RF-3" />

            </Col>
            <Col xs={12} sm={6} md={4}>

                <FastSelect id={`PersonFormDieteSelect-${person.id}`} size="" options={diets}
                    label="Dieta" onChange={setDiet} value={diet} default="..." enabled={enabled}
                    selectClassName="RFT-3" labelClassName="RFT-2" />


            </Col>
            <Col xs={12} sm={6} md={4}>
                <FastSelect id={`PersonFormAgeSelect-${person.id}`} size="" options={ageRanges}
                    label="Wiek" onChange={setAgeRange} value={ageRange} default="..." enabled={enabled}
                    selectClassName="RFT-3" labelClassName="RFT-2" />
            </Col>
        </Row>
    </>);

};

/*           
            "id": 1,
            "invitation_id": 1,
            "name": "Justyna Michalczyk",
            "price": "full",
            "diet": "wegan",
            "notePrivate": "Nie lubię jej!",
            "presence": null
*/

export default PersonForm;

/*

<ButtonGroup size="sm" className="d-flex mt-1 mb-1" id={`PersonFormPresence-${person.id}`}>
                    {radios.map((radio, idx) => (
                        <ToggleButton
                            key={radio.value * person.id}
                            id={`PersonPresenceRadio-${person.id}-${radio.value}`}
                            type="radio"
                            variant={radio.variant}
                            value={radio.value}
                            checked={presence == radio.value}
                            onChange={(e) => { setPresence(e.currentTarget.value) }}
                            className="shadow-none we d-flex align-items-center justify-content-center"
                        >
                            {radio.text}
                        </ToggleButton>
                    ))}
                </ButtonGroup>

*/