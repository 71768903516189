import { Card, Row, Col, Button } from "react-bootstrap";
import { useState, useContext } from "react";
import { Textfit } from "react-textfit";

import { UserContext } from "Defines/Contexts";

import api from "Defines/Api";
import useApi from "Hooks/useApi";

import ContainerWithImage from "Components/Layout/ContainerWithImage";
import PostToast from 'Components/Fetching/PostToast'
import ChangeNameModal from "Components/Modals/ChangeNameModal";

import profileImg from 'Img/unDraw/Profile.svg'
import ChangePasswordModal from "Components/Modals/ChangePasswordModal";
import ConfirmDeleteModal from "Components/Modals/ConfirmDeleteModal";
import { Navigate, useNavigate } from "react-router-dom";
import routes from "Defines/Routes";
import { useDocumentTitle } from "usehooks-ts";


const MyProfileScreen = () => {

    useDocumentTitle("Mój profil");

    const apiPatchUser = useApi(api.user.patch);
    //const { send, response, isPending: isPendingPatch, errors: errorsPatch } = useAuthSend('PATCH', api.auth.patchUser);

    const { user, saveUser } = useContext(UserContext);

    const navigate = useNavigate();

    const deleteApi = useApi(api.user.delete)

    const [showNameModal, setShowNameModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const onNameModalSubmit = (newName) => {
        user.name = newName;
        saveUser(user);

        apiPatchUser.send({ name: newName });
        setShowNameModal(false);
    };

    const onPasswordModalSubmit = (oldPassword, password, passwordConfirmation) => {
        apiPatchUser.send({
            password: oldPassword,
            newPassword: password,
            newPassword_confirmation: passwordConfirmation
        });
        setShowPasswordModal(false);
    };

    return (<>
        {user && <>

            <ChangeNameModal show={showNameModal} onCancel={() => setShowNameModal(false)} onSubmit={onNameModalSubmit} value={user.name} centered />
            <ChangePasswordModal show={showPasswordModal} onCancel={() => setShowPasswordModal(false)} onSubmit={onPasswordModalSubmit} centered />

            <ConfirmDeleteModal show={showDelete} confirmationText={user.name}
                message="Usuwanie konta"
                onCancel={() => setShowDelete(false)}
                onSubmit={() => { deleteApi.send(); saveUser(null); navigate(routes.global.home) }} />

            <PostToast isPending={apiPatchUser.isPending} errors={apiPatchUser.errors} className="RFT-2 RF-5" />


            <Row className="d-flex justify-content-center">
                <Col className="mt-1" xl={10} md={12}>
                    <Card>
                        <Card.Header className="RFT-3 RF-3 text-center">
                            Witaj {user.name}!
                        </Card.Header>

                        <Card.Body>
                            <ContainerWithImage image={profileImg} md={9} className="align-self-center d-flex justify-content-center" imgMaxWidth="300px">
                                <div className="w-100 mx-3">

                                    <span className="text-muted RFT-2">E-Mail</span>

                                    <div className="w-75">
                                        <Textfit mode="single" max={22}><p className="RFT-3">{user.email}</p></Textfit>
                                    </div>

                                    <hr />

                                    <Row className="d-flex justify-content-center g-3">

                                        <Col xs={12} className="d-flex justify-content-center">
                                            <div className="d-grid w-100">
                                                <Button variant="primary" size="lg" className="RFT-2 RF-6"
                                                    onClick={() => navigate(routes.weddings.list)}>
                                                    Moje wesela
                                                </Button>
                                            </div>
                                        </Col>

                                        <Col lg={4} sm={6} xs={12} className="d-flex justify-content-center">
                                            <div className="d-grid w-100">
                                                <Button variant="secondary" size="lg" className="RFT-2 RF-6"
                                                    onClick={() => setShowNameModal(true)}>
                                                    Zmień nazwę
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col lg={4} sm={6} xs={12} className="d-flex justify-content-center">
                                            <div className="d-grid w-100">
                                                <Button variant="secondary" size="lg" className="RFT-2 RF-6"
                                                    onClick={() => setShowPasswordModal(true)}>
                                                    Zmień hasło
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col lg={4} sm={14} xs={12} className="d-flex justify-content-center">
                                            <div className="d-grid w-100">
                                                <Button variant="danger" size="lg" className="RFT-2 RF-6"
                                                    onClick={() => setShowDelete(true)}>
                                                    Usuń Konto
                                                </Button>
                                            </div>
                                        </Col>

                                    </Row>

                                </div>
                            </ContainerWithImage>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        </>}

    </>);
}

export default MyProfileScreen;