import { useEffect, useState } from "react";

const useNotificationPermission = (props) => {
    const [notifyPermission, setNotifyPermission] = useState(undefined);

    const browserHasNotification = "Notification" in window;

    const checkPermission = () => {
        if (!browserHasNotification || Notification.permission == "denied") {
            setNotifyPermission(false);
            return false;

        } else if (Notification.permission == "granted") {
            setNotifyPermission(true);
            return true;
        }
    };

    useEffect(() => {
        checkPermission();
    })


    const askForNotifyPermission = () => {
        return new Promise((resolve, reject) => {
            Notification.requestPermission().then(status => {
                if (checkPermission())
                    resolve();
                else
                    reject();

            })
        })
    }


    return ({ browserHasNotification, notifyPermission, askForNotifyPermission })
};

export default useNotificationPermission;