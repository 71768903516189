import { Link } from "react-router-dom";
import { Container, Row, Nav, Navbar, Image, Offcanvas } from "react-bootstrap";
import logo from 'Img/Branding/LogoNapisBezQr.svg'

import 'CSS/fonts.css'
import routes from "Defines/Routes";

import './AppNavbar.css'
import ProfileButton from "./ProfileButton";
import { useState } from "react";
import NotificationButton from "./NotificationButton";
import { useContext } from "react";
import { UserContext } from "Defines/Contexts";

const AppNavbar = () => {

    const [offShow, setOffShow] = useState(false);

    const { user } = useContext(UserContext);

    const toggleOffCanvas = () => {
        setOffShow((offShow) => !offShow);
    };
    const hideOffCanvas = () => {
        setOffShow(false);
    };

    return (<>
        <Container>
            <Navbar fixed="top" variant="light" className="NavBarAll bg-light" expand="lg">
                <Container>

                    <Navbar.Brand>
                        <Link to={routes.global.home}>
                            <Image src={logo} width="auto" height="40" className="d-inline-block align-top ms-3" />
                        </Link>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={toggleOffCanvas} />

                    <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby={"offcanvasNavbarLabel"}
                        placement="end" show={offShow} onHide={toggleOffCanvas}>

                        <Offcanvas.Header closeButton className="NavBarOffHeader">
                            <Offcanvas.Title id="offcanvasNavbar" className="RFT-2 RF-3 ">
                                Menu
                            </Offcanvas.Title>
                        </Offcanvas.Header>

                        <Offcanvas.Body className="d-flex justify-content-md-center justify-content-lg-end align-items-start">
                            <Nav className="d-flex justify-content-end align-items-center me-3">

                                <Link className="NavBarLink RFT-2" onClick={hideOffCanvas} to={routes.global.howitsworks}>
                                    Jak to działa?
                                </Link>

                                <Link className="NavBarLink RFT-2" onClick={hideOffCanvas} to={routes.global.rules}>
                                    Regulaminy
                                </Link>

                                <Link className="NavBarLink RFT-2" onClick={hideOffCanvas} to={routes.global.contact}>
                                    Kontakt
                                </Link>

                                <div className="d-flex justify-content-between align-items-center ps-2">
                                    <div className="me-2">
                                        <ProfileButton className="h-100" handleToggleOffCanvas={hideOffCanvas} />
                                    </div>

                                    {user &&
                                        <div className="me-2" style={{height:"38px"}}>
                                            <NotificationButton className=" h-100 d-flex justify-content-center align-items-center" />
                                        </div>
                                    }
                                </div>

                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>

                </Container>
            </Navbar>

        </Container>
        <Container className="">
            <Row className="NavbarBackgroundRow" />
        </Container>

    </>);

}


export default AppNavbar;