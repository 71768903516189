import { Row, Col, Form, Card, Container, FloatingLabel } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import api from "Defines/Api";

import ContainerWithImage from "Components/Layout/ContainerWithImage";

import forgotImg from 'Img/unDraw/Forgot.svg'
import "./AuthScreens.css"
import ApiButton from "Components/Fetching/ApiButton";
import routes from "Defines/Routes";
import { useDocumentTitle } from "usehooks-ts";

const ResetPasswordScreen = (props) => {

    useDocumentTitle("Resetowanie hasła");

    const { token } = useParams();

    const [isToValidate, setIsToValidate] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const [confPattern, setConfPattern] = useState("");


    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            setIsToValidate(false);
        }
    };

    useEffect(() => {
        if (password === passwordConfirmation) {
            setConfPattern(".{8,}");
        } else {
            setConfPattern("");
        }
    }, [password, passwordConfirmation]);


    const onChangePassword = (e) => {
        setIsToValidate(true);
        setPassword(e.target.value);
    }
    const onChangePasswordConfirmation = (e) => {
        setIsToValidate(true);
        setPasswordConfirmation(e.target.value);
    }

    return (<>
        <Container fluid className="AuthContainer ">
            <Row className="d-flex justify-content-center">
                <Col className="mt-1" xl={9} md={8}>
                    <Card>
                        <Card.Header className="text-center">
                            <span className="RFT-2 RF-3 ">Zmiana HASŁA</span>
                        </Card.Header>
                        <Card.Body>
                            <ContainerWithImage image={forgotImg} xl={8} lg={12}
                                className="align-self-center" imgMaxWidth="300px">
                                <Form noValidate validated={isToValidate} onSubmit={handleSubmit} className="w-100">
                                    <Form.Group>
                                        <FloatingLabel controlId="floatingPassReg" label="Hasło" className="mb-3 RFT-2" size="lg" >
                                            <Form.Control type="password" placeholder=" " className="RFT-3 shadow-none" size="lg"
                                                value={password} onChange={(e) => { onChangePassword(e); }}
                                                required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[0-9a-zA-Z!@#$%&^*()]{8,}$" />
                                            <Form.Control.Feedback type="invalid">
                                                Musi Zawierać:<br />
                                                -Co najmniej 8 znaków<br />
                                                -Co najmniej jedną dużą literę<br />
                                                -Co najmniej jedną liczbę
                                            </Form.Control.Feedback>
                                        </FloatingLabel>

                                        <FloatingLabel controlId="floatingPassConfirmReg" label="Potwierdź hasło" className="mb-3 RFT-2" size="lg" >
                                            <Form.Control type="password" placeholder=" " className="RFT-3 shadow-none" size="lg"
                                                value={passwordConfirmation} onChange={(e) => { onChangePasswordConfirmation(e) }}
                                                required pattern={confPattern} />
                                            <Form.Control.Feedback type="invalid">
                                                Hasła nie są identyczne
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>

                                    <div className="d-grid gap-2">
                                        <ApiButton api={api.auth.postResetPassword} labelClassName="RFT-2" type="submit"
                                            okRedirect={routes.auth.login} size="lg" showError sendData={{
                                                token: token,
                                                password: password,
                                                password_confirmation: passwordConfirmation,
                                            }}>
                                            Zmień hasło
                                        </ApiButton >
                                    </div>

                                </Form>
                            </ContainerWithImage>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    </>);

};

export default ResetPasswordScreen;